import styles from "./myStorePage.module.css";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import StoreProduct from "components/StoreProduct";
import OrdersSection from "components/OrdersSection";
import StoreShowcase from "components/StoreShowcase";
import StoreRow from "components/StoreRow";
import { serverHost } from "helpers/usefulData";
import Sidebar from "components/Sidebar";
import StoreDashboard from "components/StoreDashboard";
import {
  IconCoinEuro,
  IconEdit,
  IconPackage,
  IconPhotoScan,
  IconStack2,
  IconTrash,
} from "@tabler/icons-react";
import Fuse from "fuse.js";
import ConfirmationModal from "components/ConfirmationModal";
import ChatPage from "scenes/chatPage";
import SubscriptionPage from "scenes/SubscriptionPage";
import FAQPage from "scenes/FAQPage";
import FullScreenView from "components/FullScreenView";

const MyStorePage = () => {
  const navigate = useNavigate();
  let { section } = useParams();
  const token = useSelector((state) => state.token);
  const [store, setStore] = useState(null);
  const [products, setProducts] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteProductID, setDeleteProductID] = useState(null);

  const handleClose = () => {
    setShowModal(false);
  };

  const options = {
    keys: ["name"],
    isCaseSensitive: false,
    shouldSort: true,
    threshold: 0.3,
  };
  const user = useSelector((state) => state.user);
  useEffect(() => {
    document.title = "Il mio negozio - Tralevie";
  }, []);
  useEffect(() => {
    getStore();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    getFewProducts();
  }, [store]);

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);

    if (!value) {
      setSearchResults(products);
      return;
    }
    const fuse = new Fuse(products, options);
    const results = fuse.search(value);
    console.log(results);

    setSearchResults(results.map((result) => result.item));
  };
  const getStore = async () => {
    const response = await fetch(serverHost + `stores/myStore`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    setStore(data);
    console.log(data);
  };
  const deleteProduct = async () => {
    const response = await fetch(serverHost + `products/deleteProduct/` + deleteProductID, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (response.ok) {
      console.log("dleeted success");

      const newList = products.filter((item) => item._id !== deleteProductID);
      setProducts(newList);
      setSearchResults(newList);
    }
    setShowModal(false);
  };

  const getFewProducts = async () => {
    var link = serverHost + "products/store/" + store?._id;
    const response = await fetch(link, {
      method: "GET",
    });
    const data = await response.json();
    setProducts(data);
    setSearchResults(data);
  };

  if (!store) return null;

  return (
    <>

      <Navbar />
      <ConfirmationModal
        showModal={showModal}
        handleClose={handleClose}
        handleConfirm={deleteProduct}
        title="Conferma elimiazione"
        message={"Sei sicuro di voler eliminare il prodotto?"}
      />

      <div className={window.innerWidth < 1000 ? styles.mobileContainer : styles.container}>
        <Sidebar type={"store"} />
        <div className={styles.contentContainer}>
          <ul className="breadCrumb">
            <li className="breadCrumbElement">
              <a href="/">Homepage</a>
            </li>{" "}
            {">"}
            <li className="breadCrumbElement">
              <a href="/ilMioNegozio">il Mio Negozio</a>
            </li>
          </ul>

          {(section == "dashboard" || section == null) && (
            <StoreDashboard store={store} />
          )}

          {section == "prodotti" && (
            <div className={styles.productsSection}>
              <div className={styles.titleRow}>
                <div className="secondaryTitle">
                  {" "}
                  <IconPackage></IconPackage> I miei Prodotti
                </div>
                <button
                  className={`${styles.editButton} mainButtonGreen`}
                  onClick={() => navigate("/aggiungiProdotto")}
                >
                  + aggiungi un prodotto
                </button>
              </div>
              {products?.length > 0 ? (<>

              <div className={styles.carousel}>
                <input
                  type="text"
                  placeholder="Cerca tra i prodotti..."
                  value={searchTerm}
                  onChange={handleSearch}
                  className={styles.searchFilter}
                />

                <table className={styles.productTable}>
                  <thead>
                    <tr>
                      <th>
                        <IconPhotoScan></IconPhotoScan> Img
                      </th>
                      <th>
                        <IconPackage></IconPackage> Nome
                      </th>
                      <th className={styles.priceColumn}>
                        <IconCoinEuro></IconCoinEuro> Prezzo
                      </th>
                      <th>
                        <IconStack2></IconStack2> Qnt
                      </th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {searchResults.map((value, key) => {
                      return (
                        <tr
                          key={key}
                          className={value.inventory == 0 && styles.soldout}
                        >
                          <td>
                            <img
                              src={
                                value?.picture[0]
                                  ? value?.picture[0].replace(
                                      "upload/",
                                      "upload/q_50/"
                                    )
                                  : "/assets/product.png"
                              }
                              alt={"immagine di" + value.name}
                              className={styles.productImage}
                            />
                          </td>
                          <td
                            className={styles.productName}
                            onClick={() => navigate("/prodotto/" + value._id)}
                          >
                            {value.name}
                          </td>
                          <td className={styles.productPrice}>
                            € {value.price}
                          </td>
                          <td className={styles.productQuantity}>
                            {value.inventory>=0 ?value.inventory : "∞"}
                          </td>
                          <td className={styles.shortColumn}>
                            <button
                              onClick={() =>
                                navigate("/modificaProdotto/" + value._id)
                              }
                              className={styles.yellowLink}
                            >
                              <IconEdit></IconEdit>
                            </button>
                          </td>
                          <td className={styles.shortColumn}>
                            <button
                              className={styles.redLink}
                              onClick={() =>{ setDeleteProductID(value._id); setShowModal(true)}}
                            >
                              <IconTrash></IconTrash>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div></>):
              (<div className={styles.noProducts}>Non ci sono prodotti</div>)}
            </div>
          )}
          {section == "ordini" && (
            <div className={styles.ordersSection}>
              <div className={styles.ordersSection}>
                <OrdersSection type="store" store={store} />
              </div>
            </div>
          )}
          {section == "chat" && (
            <div className={styles.ordersSection}>
              <ChatPage fullscreen={true} />
            </div>
          )}
          {section == "abbonamento" && (
            <div>
              <SubscriptionPage store={store}></SubscriptionPage>
            </div>
          )}
          {section == "aiuto" && <FAQPage></FAQPage>}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default MyStorePage;
