import { useContext, useEffect, useState } from "react";
import { ErrorMessage, Field, Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import styles from "./createEditProductPageChat.module.css";
import { PopupContext } from "App";
import Loader from "components/Loader";
import FormBar from "components/FormBar";
import Tooltip from "components/Tooltip";
import { serverHost } from "helpers/usefulData";
import imageCompression from "browser-image-compression";
import { IconChevronLeft, IconChevronRight, IconSparkles, IconX } from "@tabler/icons-react";

const productSchema = yup.object().shape({
  name: yup.string().required("Inserisci il nome"),
  description: yup.string(),
  price: yup
    .number("Il prezzo deve essere un numero")
    .required("Inserisci il prezzo"),
  shippingCost: yup.number(),
  tags: yup.string(),
  options: yup.string(),
  inventory: yup.number().nullable(),
});

const Form = ({ existingProduct }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [popup, setPopup] = useContext(PopupContext);
  const token = useSelector((state) => state.token);
  const [images, setImages] = useState([]);
  const [tags, setTags] = useState(existingProduct ? existingProduct.tags : []);
  const [writingTag, setWritingTag] = useState("");
  const [options, setOptions] = useState(
    existingProduct ? existingProduct?.options : []
  );
  const [selectedOption, setSelectedOption] = useState( existingProduct ? existingProduct?.shippingCost : null);
  const [isLoadingAI, setIsLoadingAI] = useState(false);

  const [writingOptions, setWritingOptions] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [oldImages, setOldImages] = useState(
    existingProduct ? existingProduct?.picture : null
  );
  const shippingOptions = [
    { id: 1, name: 'Piccolo', dimensions: '10x10x10', cost: 5.99 },
    { id: 2, name: 'Medio', dimensions: '20x20x20', cost: 8.99 },
    { id: 3, name: 'Grande', dimensions: '30x30x30', cost: 14.99 }
  ];
  var initialValues = {
    name: "",
    tags: "",
    description: "",
    price: "",
    shippingCost: shippingOptions[0].cost,
    options: "",
    inventory: "",
  };

  useEffect(() => {
    if (existingProduct != null) {
      initialValues.name = existingProduct?.name;
      initialValues.tags = existingProduct?.tags.join();
      initialValues.description = existingProduct?.description;
      initialValues.shippingCost = existingProduct?.shippingCost;
      initialValues.price = existingProduct?.price;
      initialValues.inventory = existingProduct?.inventory!=-1 ? existingProduct?.inventory : null;
    }
  }, []);
  async function processImages(formData) {
    for await (const image of images)  {
        const options = {
            maxSizeMB: 2,
            maxWidthOrHeight: 1920,
            useWebWorker: false,
        };
        const compressedFile = await imageCompression(image, options);
        formData.append("picture", compressedFile);

    }
    return formData
}

const ImproveWithAI = async (values, setFieldValue) => {
  try{    // this allows us to send form info with image
    setIsLoadingAI(true);

  const response = await fetch(
     serverHost+ "ai/improveText",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({text: values.description}),
      
    }
  );
  const savedImprovement = await response.json();
  console.log("improved", savedImprovement)
  setIsLoadingAI(false);

  if (response.ok) {
    setFieldValue('description', savedImprovement.text);

    setPopup({
      type: "success",
        message: "Testo migliorato grazie all'AI",
    });
  } else {
    setPopup({
      type: "error",
      message: "Negozio NON modificato, per favore riprova"
    });
  }}catch (e) {
    console.error(e);
  }
};
  const [phases, setPhases] = useState(["ciao! Per creare un prodotto ti basterà rispondere alle mie domande. Partiamo! Come si chiama il prodotto che vuoi aggiungere? ",
   "Ora descrivilo come se lo stessi raccontando ad un amico. Attento a mettere tutte le informazioni per aiutare i tuoi clienti.",
    "è tempo di inserire le immagini. Ricorda che belle foto aiutano a vendere!",
  "E ora dimmi, a che prezzo lo vuoi inserire?",
  "La spedizione dipende dal peso! Seleziona la giusta dimensione del pacco",
"Hai un numero limitato di pezzi? se si, quanti ne hai?",
"Il prodotto che stai inserendo ha delle opzioni (tipo taglia, colore, etc)? se si, quali? (premi '+' per ogni opzione)",
"Riassiumiamo! Controlla tutti i dati e dammi conferma :)."]);

  const createEditProduct = async (values, onSubmitProps) => {
    setIsLoading(true);

    console.log("sto creadno o modificando il negozio");
    // this allows us to send form info with image
    if (existingProduct) values._id = existingProduct?._id;
    values.options = options.join();
    values.tags = tags.join();
    if (values.inventory==null) values.inventory=-1
    var formData = new FormData();
    for (let value in values) {
      console.log(values[value]);
      formData.append(value, values[value]);
    }
    //values?.picture?.name && formData.append("picture", values.picture.name);
    existingProduct &&
      formData.append(
        "oldImages",
        typeof oldImages == "string" ? oldImages : oldImages.join()
      );
    formData= await processImages(formData)

    for (const value of formData.values()) {
      console.log(value);
    }

    const savedProductResponse = await fetch(
      existingProduct
        ? serverHost+ "products/editProduct"
        : serverHost+ "products/addProduct",
      {
        method: "POST",
        headers: {
          //"Content-Type": 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
    const savedProduct = await savedProductResponse.json();
    console.log(savedProduct)
    //onSubmitProps.resetForm();
    setIsLoading(false);

    if (savedProductResponse.ok) {
      setPopup({
        type: "success",
        message: existingProduct
          ? "Prodotto modificato con successo"
          : "Prodotto creato con successo",
      });
      navigate("/ilMioNegozio/prodotti");
    } else {
      setPopup({
        type: "error",
        message: existingProduct
          ? "Prodotto NON modificato, per favore riprova"
          : "Prodotto NON creato, per favore riprova",
      });
    }
  };
  const removeOldImage = (index) => {
    var tempOldImages = oldImages.filter((_, i) => i !== index);
    console.log(tempOldImages);
    setOldImages(tempOldImages);
  };
  const removeTag = (index) => {
    var tempTags = tags.filter((_, i) => i !== index);
    console.log(tempTags);
    setTags(tempTags);
  };
  const addTag = (event) => {
    console.log("i'm hereee");
    event.preventDefault();
    setTags([...tags, writingTag]);
    setWritingTag("");
  };

  const removeOption = (index) => {
    const tempTags = [...options];
    // Remove the element at the specified index
    tempTags.splice(index, 1);
    // Update the state with the new array
    setOptions(tempTags);
  };
  const addOption = (event) => {
    console.log("i'm hereee");
    event.preventDefault();
    setOptions([...options, writingOptions]);
    setWritingOptions("");
  };
  const removeImage = (indexToRemove) => {
    const updatedImages = images.filter((el, index) => index !== indexToRemove);
    setImages(updatedImages);
  };
  const handleFormSubmit = async (values, onSubmitProps) => {
    console.log("formittato");
    await createEditProduct(values, onSubmitProps);
  };

  return (
    <>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={productSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isValid,
          dirty,
          setFieldValue,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit} className={styles.form}>
            <div>
              <div className="mainTitle">
                {existingProduct
                  ? "Modifichiamo assieme '"+existingProduct.name+"'"
                  : "Creiamo insieme un prodotto in 2 minuti"}{" "}
              </div>
              <FormBar phases={phases} step={step} showPhases={false} />

              <div className={styles.chatRow}>
                <div>
                  <img className={styles.image} src="/assets/client support.png" alt="" />
                  <div className={styles.Tralevie}>Tralevie</div>
                </div>
                <div>
                  <div className={styles.message}>{phases[step]}</div>
                </div>
              </div>

              <div className={styles.inputContainer}>
                {step == 0 && (
                  <>
                    <div>
                      <div className={styles.label}>Nome Del Prodotto:</div>
                      <Field
                        className={
                          touched.name && errors.name
                            ? `${styles.input} ${styles.error}`
                            : `${styles.input}`
                        }
                        type="text"
                        label="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        placeholder="Come si chiama il prodotto?"
                        name="name"
                        error={Boolean(touched.name) && Boolean(errors.name)}
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className={styles.errorMessage}
                      />{" "}
                    </div>
                  </>
                )}

                {step == 1 && (
                  <div>
                    <div className={styles.label}>Descrizione (opzionale):</div>
                    <Field
                      className={styles.inputTextArea}
                      as="textarea"
                      label="description"
                      placeholder="Descrivi il prodotto"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      name="description"
                      error={
                        Boolean(touched.description) &&
                        Boolean(errors.description)
                      }
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className={styles.errorMessage}
                    />
                    <button className={`mainButtonGreen ${styles.improveButton}`}
                    type="button"
                    onClick={() => ImproveWithAI(values, setFieldValue)} disabled={values.description === ""}>
                      {isLoadingAI ? (
                          <Loader fullScreen={false} isText={true} />
                        ) : (
                          <><IconSparkles />  migliora con AI</>
                        )}
                    </button>
                  </div>
                )}
                {step == 2 && (
                  <div className={styles.totOldImagesContainer}>
                    {oldImages?.map((el, index) => {
                      return (
                        <div className={styles.oldImageContainer}>
                          <img key={el} src={el} className={styles.oldImage} />
                          <button
                            type="button"
                            onClick={() => removeOldImage(index)}
                            className={styles.removeOldImage}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className={styles.removeSVG}
                              viewBox="0 -960 960 960"
                            >
                              <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm80-160h80v-360h-80v360Zm160 0h80v-360h-80v360Z" />
                            </svg>
                          </button>
                        </div>
                      );
                    })}
                    {images?.map((el, index) => {
                      return (
                        <div className={styles.oldImageContainer}>
                          <img
                            key={el}
                            src={el.tempURL}
                            className={styles.oldImage}
                          />
                          <button
                            type="button"
                            onClick={() => removeImage(index)}
                            className={styles.removeOldImage}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className={styles.removeSVG}
                              viewBox="0 -960 960 960"
                            >
                              <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm80-160h80v-360h-80v360Zm160 0h80v-360h-80v360Z" />
                            </svg>
                          </button>
                        </div>
                      );
                    })}

                    <Dropzone
                      acceptedFiles=".jpg,.jpeg,.png"
                      multiple
                      onDrop={(acceptedFiles) => {
                        //setImages([]);
                        acceptedFiles.forEach((file) => {
                          file.tempURL = URL.createObjectURL(file);
                          setImages((prevState) => [...prevState, file]);
                        });
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                          <input {...getInputProps()} type="file" />

                          <div className={`${styles.newImageContainer}`}>
                            <div className={styles.dropFile}>
                              +<br></br>foto
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                )}
                {step == 3 && (
                  <div>
                    <div className={styles.label}>Prezzo *</div>
                    <Field
                      className={
                        touched.price && errors.price
                          ? `${styles.input} ${styles.error}`
                          : `${styles.input}`
                      }
                      label="Prezzo"
                      min="0.00"
                      max="10000.00"
                      step="0.01"
                      type="number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.price}
                      name="price"
                      placeholder="Quanto costa?"
                      error={Boolean(touched.price) && Boolean(errors.price)}
                      helperText={touched.price && errors.price}
                    />
                    <ErrorMessage
                      name="price"
                      component="div"
                      className={styles.errorMessage}
                    />
                  </div>
                )}
                {step == 4 && (
                  <div>
                    <div className={styles.label}>Costo della spedizione*</div>
                    <ul>
                    {shippingOptions.map(option => (
          <li key={option.id} >
            <label className={selectedOption !== option.cost ? styles.radio:styles.radioSelected  }>
              <input
                type="radio"
                name="shippingOption"
                value={values.shippingCost}
                checked={selectedOption === option.cost}
                onChange={() =>  {values.shippingCost= option.cost; 
                  setSelectedOption(option.cost)   }}
              />
              {`${option.name} ${option.dimensions}cm - ${option.cost} €`}
            </label>
          </li>
        ))}
      </ul>
      <Tooltip text={"La spedizione viene gestita da Tralevie tramite Poste Italiane. Una volta confermato l'ordine verrà inviata l'etichetta da attaccare sul pacco e nei giorni successivi arriverà un corriere di Poste Italiane a ritirarlo."} />

                  </div>
                )}
                {step == 5 && (
                  <div>
                    <div className={styles.label}>Quantità</div>

                    <Field
                      className={
                        touched.inventory && errors.inventory
                          ? `${styles.input} ${styles.error}`
                          : `${styles.input}`
                      }
                      label="Quante unità hai? (lascia vuoto se illimitate)"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.inventory}
                      type="number"
                      name="inventory"
                      min="0"
                      step="1"
                      placeholder="Quante unità hai? (salta se illimitate)"
                      error={
                        Boolean(touched.inventory) && Boolean(errors.inventory)
                      }
                      helperText={touched.inventory && errors.inventory}
                    />

                    <ErrorMessage
                      name="inventory"
                      component="div"
                      className={styles.errorMessage}
                    />
                  </div>
                )}
                {step == 6 && (
                  <div>
                    <div className={styles.label}>Opzioni d'Acquisto *</div>

                    <form className={styles.tagForm} onSubmit={addOption}>
                      {options?.map((tag, index) => {
                          return <div className={styles.option} key={index}>
                            <div className={styles.tagText}>{tag}</div>
                            <div
                              className={styles.removeTag}
                              onClick={() => {
                                removeOption(index);
                              }}
                            >
                              <IconX></IconX>
                            </div>
                          </div>
                        
                      })}

                      <div className={styles.inputAndButton}>
                        <input
                          type="text"
                          className={styles.tagInput}
                          placeholder="Aggiungi opzioni del prodotto"
                          value={writingOptions}
                          onChange={(event) => {
                            setWritingOptions(event.target.value);
                          }}
                        />
                        <button
                          className="mainButtonGreen"
                          onClick={addOption}
                          disabled={!writingOptions}
                        >
                          +
                        </button>
                      </div>
                    </form>
                  </div>
                )}
                
                {step == 7 && (
                  <>
                    <div>
                      <div
                        className={
                          touched.name && errors.name
                            ? `${styles.recapRecord} ${styles.recapRecordError}`
                            : `${styles.recapRecord}`
                        }
                      >
                        <span className={styles.label}>Nome:</span>
                        <span className={styles.label}>
                          {values.name ? values.name : "-"}
                        </span>
                      </div>
                      <div                         className={
                          touched.description && errors.description
                            ? `${styles.recapRecord} ${styles.recapRecordError}`
                            : `${styles.recapRecord}`
                        }>
                        <span className={styles.label}>Descrizione:</span>
                        <span className={styles.label}>
                          {values.description ? values.description : "-"}
                        </span>
                      </div>
                      <div  className={
                          touched.price && errors.price
                            ? `${styles.recapRecord} ${styles.recapRecordError}`
                            : `${styles.recapRecord}`
                        }>
                        <span className={styles.label}>Prezzo:</span>
                        <span className={styles.label}>
                          {values.price ? values.price : "-"}
                        </span>
                      </div>
                      <div className={
                          touched.shippingCost && errors.shippingCost
                            ? `${styles.recapRecord} ${styles.recapRecordError}`
                            : `${styles.recapRecord}`
                        }>
                        <span className={styles.label}>Costi di spedizione:</span>
                        <span className={styles.label}>
                          {values.shippingCost !== 0
                            ? values.shippingCost
                            : "-"}
                        </span>
                      </div>
                      <div className={
                          touched.options && errors.options
                            ? `${styles.recapRecord} ${styles.recapRecordError}`
                            : `${styles.recapRecord}`
                        }>
                        <span className={styles.label}>Opzioni:</span>
                        <span className={styles.label}>
                          {options ? options.join(', ') : "-"}
                        </span>
                      </div>
                      <div className={
                          touched.inventory && errors.inventory
                            ? `${styles.recapRecord} ${styles.recapRecordError}`
                            : `${styles.recapRecord}`
                        }>
                        <span className={styles.label}>Inventario:</span>
                        <span className={styles.label}>
                          {values.inventory!=-1 ? values.inventory : "∞"}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {/* BUTTONS */}
              <div>
                <div className={styles.buttonContainer}>
                  <button
                    className={styles.movementButton}
                    style={{ visibility: step == 0 ? "hidden" : "visible" }}
                    type="button"
                    onClick={() => setStep(step - 1)}
                  >
                  <IconChevronLeft></IconChevronLeft> Indietro

                  </button>

                  {step < phases.length - 1 && (
                    <button
                      className={styles.movementButton}
                      type="button"
                      onClick={() => setStep(step + 1)}
                    >
                      Avanti <IconChevronRight></IconChevronRight>
                    </button>
                  )}

                  {step > phases.length - 2 && (
                    <>
                      <button className="mainButtonGreen" type="submit"  disabled={!isValid}>
                        {isLoading ? (
                          <Loader fullScreen={false} isText={true} />
                        ) : existingProduct ? (
                          "Modifica"
                        ) : (
                          "Crea"
                        )}
                      </button>
                    </>
                  )}
                </div>
                {!isValid && step > phases.length - 2 && (
                  <div className={styles.errorMessageFinal}>
                    Errore: controlla di avere inserito correttamente tutti i
                    dati
                  </div>
                )}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default Form;
