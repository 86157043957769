import Navbar from "components/Navbar.jsx";
import Footer from "components/Footer";
import styles from "./homepage.module.css";
import StoreCarousel from "components/StoreCarousel";
import ProductCarousel from "components/ProductCarousel";
import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import Showcase from "components/Showcase";
import { useSelector } from "react-redux";
import OrdersSectionReminder from "components/OrdersSectionReminder";
import SearchBar from "components/SearchBar";
import { useNavigate } from "react-router-dom";
import CarouselWrapper from "components/CarouselWrapper";
import { productCategories, serverHost } from "helpers/usefulData";
import Tag from "components/Tag";
import ProductHistoryCarousel from "components/ProductHistoryCarousel";
import { IconDiamond, IconHearts, IconMessages, IconStars } from "@tabler/icons-react";

const HomePage = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [stores, setStores] = useState(null);


  const getStores = async () => {
    const response = await fetch(serverHost+ `stores`, {
      method: "GET",
    });
    const data = await response.json();
    setStores(data);
  };
  
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  useEffect(() => {
    document.title = "Tralevie";
    getStores()
  }, []);

  return (
    <div className={styles.container}>
      <Navbar />
      {user?.isOwner && <OrdersSectionReminder />}
      
      <div className={styles.firstRow}>
        <div className={styles.iconContainer}>
        <img
          src="/assets/homepage.png"
          alt="Tralevie icon"
          className={styles.firstIcon}
        />
        <img
          src="/assets/homepageSpeachBubble1.png"
          alt="Tralevie icon"
          className={styles.firstIconHover}
        />
        <img
          src="/assets/homepageSpeachBubble2.png"
          alt="Tralevie icon"
          className={styles.firstIconHover}
        />
      </div>
        <div className={styles.leftSection}>
          <div className={styles.motto}>
           La Rete Online Dei Negozi<br></br> Di Qualità Italiani 
          </div>
          <div className={styles.subText}>
           Tralevie ti aiuta a riscopre il fascino del commercio locale senza rinunciare alle comodità del digitale
            
          </div>
          {/*
                      Riscopri il fascino degli acquisti locali uniti alla comodità dei
            servizi online. Consegne ultraveloci, supporto costante degli
            esperti commercianti locali, etica e qualità.
          <div className={styles.actionRow}>  
            <a
            className={`cta`}
            href="/negozi"
          >
            esplora i negozi
          </a>
          <a
            className={`greenLink`}
            href="/chiSiamo"
          >
            Come Funziona?
          </a>
          </div>*/}
          <SearchBar showHistory={false}/>
        </div>
      </div>

      <svg
        id="wave"
        viewBox="0 0 1440 100"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        style={{ transform: "translateY(5px)" }}
      >
        <defs>
          <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
            <stop stopColor="#bfe8bc" offset="0%"></stop>
            <stop stopColor="#bfe8bc" offset="100%"></stop>
          </linearGradient>
        </defs>
        <path
          fill="url(#sw-gradient-0)"
          d="M0,80L40,66.7C80,53,160,27,240,13.3C320,0,400,0,480,15C560,30,640,60,720,60C800,60,880,30,960,25C1040,20,1120,40,1200,50C1280,60,1360,60,1440,50C1520,40,1600,20,1680,23.3C1760,27,1840,53,1920,63.3C2000,73,2080,67,2160,61.7C2240,57,2320,53,2400,55C2480,57,2560,63,2640,65C2720,67,2800,63,2880,55C2960,47,3040,33,3120,38.3C3200,43,3280,67,3360,73.3C3440,80,3520,70,3600,70C3680,70,3760,80,3840,75C3920,70,4000,50,4080,50C4160,50,4240,70,4320,66.7C4400,63,4480,37,4560,26.7C4640,17,4720,23,4800,31.7C4880,40,4960,50,5040,45C5120,40,5200,20,5280,20C5360,20,5440,40,5520,51.7C5600,63,5680,67,5720,68.3L5760,70L5760,100L5720,100C5680,100,5600,100,5520,100C5440,100,5360,100,5280,100C5200,100,5120,100,5040,100C4960,100,4880,100,4800,100C4720,100,4640,100,4560,100C4480,100,4400,100,4320,100C4240,100,4160,100,4080,100C4000,100,3920,100,3840,100C3760,100,3680,100,3600,100C3520,100,3440,100,3360,100C3280,100,3200,100,3120,100C3040,100,2960,100,2880,100C2800,100,2720,100,2640,100C2560,100,2480,100,2400,100C2320,100,2240,100,2160,100C2080,100,2000,100,1920,100C1840,100,1760,100,1680,100C1600,100,1520,100,1440,100C1360,100,1280,100,1200,100C1120,100,1040,100,960,100C880,100,800,100,720,100C640,100,560,100,480,100C400,100,320,100,240,100C160,100,80,100,40,100L0,100Z"
        ></path>
      </svg>
      <div className={styles.proSection}>
        
      <div className={styles.proSectionRight}>
          <img src="/assets/mark.png" alt="" className={styles.favic} />
          <div className={styles.proTitle}>

          Se ami i prodotti ricercati<br></br> venduti da brava gente,<br></br>Tralevie è il posto giusto!          </div>
          <ul className={styles.actionProSection}>
            <li
              className={styles.actionLink}
              onClick={() => navigate("/chiSiamo")}
            >
              Scopri Tralevie
            </li>
            <li
              className={styles.actionLink}
              onClick={() => navigate("/guidaPerNegozi")}
            >
              Hai un negozio?
            </li>
            <li className={styles.actionLink} onClick={() => navigate("/FAQ")}>
              Domande Frequenti
            </li>
          </ul>
        </div>
        <div className={styles.proSectionLeft}>
          <div className={styles.pros}>
            <div className={styles.proContainerRow}>
              <div className={`${styles.proContainer}`}>
                <IconMessages className={styles.proIcon} ></IconMessages>
                Supporto <br></br>Agli Acquisti
              </div>

              <div className={`${styles.proContainer}`}>
   <IconStars  className={styles.proIcon}></IconStars>
                Qualità e <br></br> Convenienza
              </div>
            </div>
            <div className={styles.proContainerRow}>
              <div className={`${styles.proContainer}`}>
                <IconHearts className={styles.proIcon}></IconHearts>
                Etica e <br></br>Sostenibilità
              </div>
              <div className={`${styles.proContainer}`}>
                <IconDiamond className={styles.proIcon}></IconDiamond>
                Prodotti <br></br>Esclusivi
              </div>
            </div>
          </div>
        </div>

      </div>
      <svg
        id={styles.svgFooter}
        viewBox="0 -50 1440 170"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        style={{ transform: "rotate(180deg) translateY(1px)" }}
      >
        <path
          fill="#bfe8bc"
          d="M0,85L24,82.2C48,79,96,74,144,62.3C192,51,240,34,288,36.8C336,40,384,62,432,59.5C480,57,528,28,576,14.2C624,0,672,0,720,11.3C768,23,816,45,864,45.3C912,45,960,23,1008,19.8C1056,17,1104,34,1152,39.7C1200,45,1248,40,1296,53.8C1344,68,1392,102,1440,107.7C1488,113,1536,91,1584,85C1632,79,1680,91,1728,104.8C1776,119,1824,136,1872,141.7C1920,147,1968,142,2016,133.2C2064,125,2112,113,2160,93.5C2208,74,2256,45,2304,53.8C2352,62,2400,108,2448,107.7C2496,108,2544,62,2592,48.2C2640,34,2688,51,2736,53.8C2784,57,2832,45,2880,59.5C2928,74,2976,113,3024,119C3072,125,3120,96,3168,90.7C3216,85,3264,102,3312,110.5C3360,119,3408,119,3432,119L3456,119L3456,170L3432,170C3408,170,3360,170,3312,170C3264,170,3216,170,3168,170C3120,170,3072,170,3024,170C2976,170,2928,170,2880,170C2832,170,2784,170,2736,170C2688,170,2640,170,2592,170C2544,170,2496,170,2448,170C2400,170,2352,170,2304,170C2256,170,2208,170,2160,170C2112,170,2064,170,2016,170C1968,170,1920,170,1872,170C1824,170,1776,170,1728,170C1680,170,1632,170,1584,170C1536,170,1488,170,1440,170C1392,170,1344,170,1296,170C1248,170,1200,170,1152,170C1104,170,1056,170,1008,170C960,170,912,170,864,170C816,170,768,170,720,170C672,170,624,170,576,170C528,170,480,170,432,170C384,170,336,170,288,170C240,170,192,170,144,170C96,170,48,170,24,170L0,170Z"
        ></path>
      </svg>    
    

      {/*
      <svg
        id={styles.svgFooter}
        viewBox="0 0 1440 170"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="rgb(34, 130, 28)"
          d="M0,85L24,82.2C48,79,96,74,144,62.3C192,51,240,34,288,36.8C336,40,384,62,432,59.5C480,57,528,28,576,14.2C624,0,672,0,720,11.3C768,23,816,45,864,45.3C912,45,960,23,1008,19.8C1056,17,1104,34,1152,39.7C1200,45,1248,40,1296,53.8C1344,68,1392,102,1440,107.7C1488,113,1536,91,1584,85C1632,79,1680,91,1728,104.8C1776,119,1824,136,1872,141.7C1920,147,1968,142,2016,133.2C2064,125,2112,113,2160,93.5C2208,74,2256,45,2304,53.8C2352,62,2400,108,2448,107.7C2496,108,2544,62,2592,48.2C2640,34,2688,51,2736,53.8C2784,57,2832,45,2880,59.5C2928,74,2976,113,3024,119C3072,125,3120,96,3168,90.7C3216,85,3264,102,3312,110.5C3360,119,3408,119,3432,119L3456,119L3456,170L3432,170C3408,170,3360,170,3312,170C3264,170,3216,170,3168,170C3120,170,3072,170,3024,170C2976,170,2928,170,2880,170C2832,170,2784,170,2736,170C2688,170,2640,170,2592,170C2544,170,2496,170,2448,170C2400,170,2352,170,2304,170C2256,170,2208,170,2160,170C2112,170,2064,170,2016,170C1968,170,1920,170,1872,170C1824,170,1776,170,1728,170C1680,170,1632,170,1584,170C1536,170,1488,170,1440,170C1392,170,1344,170,1296,170C1248,170,1200,170,1152,170C1104,170,1056,170,1008,170C960,170,912,170,864,170C816,170,768,170,720,170C672,170,624,170,576,170C528,170,480,170,432,170C384,170,336,170,288,170C240,170,192,170,144,170C96,170,48,170,24,170L0,170Z"
        ></path>
      </svg>
      
      <svg
        id="wave"
        viewBox="0 0 1440 100"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
            <stop stopColor="rgba(34, 130, 28)" offset="0%"></stop>
            <stop stopColor="rgba(34, 130, 28)" offset="100%"></stop>
          </linearGradient>
        </defs>
        <path
          fill="url(#sw-gradient-0)"
          d="M0,80L40,66.7C80,53,160,27,240,13.3C320,0,400,0,480,15C560,30,640,60,720,60C800,60,880,30,960,25C1040,20,1120,40,1200,50C1280,60,1360,60,1440,50C1520,40,1600,20,1680,23.3C1760,27,1840,53,1920,63.3C2000,73,2080,67,2160,61.7C2240,57,2320,53,2400,55C2480,57,2560,63,2640,65C2720,67,2800,63,2880,55C2960,47,3040,33,3120,38.3C3200,43,3280,67,3360,73.3C3440,80,3520,70,3600,70C3680,70,3760,80,3840,75C3920,70,4000,50,4080,50C4160,50,4240,70,4320,66.7C4400,63,4480,37,4560,26.7C4640,17,4720,23,4800,31.7C4880,40,4960,50,5040,45C5120,40,5200,20,5280,20C5360,20,5440,40,5520,51.7C5600,63,5680,67,5720,68.3L5760,70L5760,100L5720,100C5680,100,5600,100,5520,100C5440,100,5360,100,5280,100C5200,100,5120,100,5040,100C4960,100,4880,100,4800,100C4720,100,4640,100,4560,100C4480,100,4400,100,4320,100C4240,100,4160,100,4080,100C4000,100,3920,100,3840,100C3760,100,3680,100,3600,100C3520,100,3440,100,3360,100C3280,100,3200,100,3120,100C3040,100,2960,100,2880,100C2800,100,2720,100,2640,100C2560,100,2480,100,2400,100C2320,100,2240,100,2160,100C2080,100,2000,100,1920,100C1840,100,1760,100,1680,100C1600,100,1520,100,1440,100C1360,100,1280,100,1200,100C1120,100,1040,100,960,100C880,100,800,100,720,100C640,100,560,100,480,100C400,100,320,100,240,100C160,100,80,100,40,100L0,100Z"
        ></path>
      </svg>
      
      <div className={styles.secondRow}>
        <h1 className={styles.title}>come funziona Tralevie?</h1>
        <div className={styles.steps}>
          <div className={styles.step}>
            <div className={styles.num}><img className={styles.stepImg}src="/assets/dart.png" alt="" /></div>
            <div className={styles.text}>trova il prodotto che cerchi</div>
          </div>
          <div className={styles.step}       style={{
       transform: window.innerWidth > 500 ? 'translateY(50px)' : 'translateX(50px)',
      }}>
            <div className={styles.num}><img className={styles.stepImg}src="/assets/megaphone.png" alt="" /></div>
            <div className={styles.text}>contatta il venditore</div>
          </div>
          <div className={styles.step} >
            <div className={styles.num}><img className={styles.stepImg}src="/assets/shopping.png" alt="" /></div>
            <div className={styles.text}>ricevilo a casa in poche ore</div>
          </div>
        </div>
        <button
          className="cta"
          onClick={() =>
            (window.location = "https://www.youtube.com/watch?v=dQw4w9WgXcQ")
          }
        >
          guarda un tutorial
        </button>
      </div>
      <svg
        id={styles.svgFooter}
        viewBox="0 0 1440 170"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        style={{transform: 'rotate(180deg) translateY(50px)'}}
      >
        <path
          fill="rgb(34, 130, 28)"
          d="M0,85L24,82.2C48,79,96,74,144,62.3C192,51,240,34,288,36.8C336,40,384,62,432,59.5C480,57,528,28,576,14.2C624,0,672,0,720,11.3C768,23,816,45,864,45.3C912,45,960,23,1008,19.8C1056,17,1104,34,1152,39.7C1200,45,1248,40,1296,53.8C1344,68,1392,102,1440,107.7C1488,113,1536,91,1584,85C1632,79,1680,91,1728,104.8C1776,119,1824,136,1872,141.7C1920,147,1968,142,2016,133.2C2064,125,2112,113,2160,93.5C2208,74,2256,45,2304,53.8C2352,62,2400,108,2448,107.7C2496,108,2544,62,2592,48.2C2640,34,2688,51,2736,53.8C2784,57,2832,45,2880,59.5C2928,74,2976,113,3024,119C3072,125,3120,96,3168,90.7C3216,85,3264,102,3312,110.5C3360,119,3408,119,3432,119L3456,119L3456,170L3432,170C3408,170,3360,170,3312,170C3264,170,3216,170,3168,170C3120,170,3072,170,3024,170C2976,170,2928,170,2880,170C2832,170,2784,170,2736,170C2688,170,2640,170,2592,170C2544,170,2496,170,2448,170C2400,170,2352,170,2304,170C2256,170,2208,170,2160,170C2112,170,2064,170,2016,170C1968,170,1920,170,1872,170C1824,170,1776,170,1728,170C1680,170,1632,170,1584,170C1536,170,1488,170,1440,170C1392,170,1344,170,1296,170C1248,170,1200,170,1152,170C1104,170,1056,170,1008,170C960,170,912,170,864,170C816,170,768,170,720,170C672,170,624,170,576,170C528,170,480,170,432,170C384,170,336,170,288,170C240,170,192,170,144,170C96,170,48,170,24,170L0,170Z"
        ></path>
      </svg>*/}

      <Showcase />
      <div id="link"></div>
      <ProductHistoryCarousel></ProductHistoryCarousel>
      <CarouselWrapper title="Le nostre categorie ">
      {productCategories.map((cat, index) => (
        <Tag name={cat} key={index}/>
        ))}
      </CarouselWrapper>
      <StoreCarousel /> 
      <div className="secondaryTitle">I prodotti più venduti</div>
      <ProductCarousel storeID="" type="best"/>
      <div className="secondaryTitle">I prodotti consigliati</div>

      <ProductCarousel storeID="" />
      <Footer />
    </div>
  );
};

export default HomePage;
