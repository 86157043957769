import styles from "./SendMessage.module.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { serverHost } from "helpers/usefulData";
import { IconSend2 } from "@tabler/icons-react";
import Loader from "./Loader";
const SendMessage = ({ store, storeOwner }) => {
  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);
  const [content, setContent] = useState("");
  const [sent, setSent] = useState(false);
  const [close, setClose] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();


  const handleSubmit = async (event) => {
    setIsLoading(true)
    try {
      event.preventDefault();
  
      const message = {
        senderID: user._id,
        text: content,
        receiverID: store.ownerID,
      };
  
      const response = await fetch(serverHost + "messages/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(message),
      });
  
      if (!response.ok) {
        // Handle non-successful response
        console.error("errore messaggio non inviato",);
        throw new Error("Failed to save message");
      }
  
      const savedMessage = await response.json();
    setIsLoading(false)

      console.log("Message saved successfully:", savedMessage);
      setSent(true);
    } catch (error) {
      setIsLoading(false)
      console.error("Error occurred while saving message:", error.message);
      // Handle error appropriately, e.g., show error message to the user
    }
  };

  return (
    <>
      {!close  && (
        <div className={styles.container}>
          <div className={styles.badge}>
            {storeOwner?.picturePath ? (
              <img
                src={storeOwner?.picturePath.replace("upload/", "upload/q_50/")}
                alt=""
                className={styles.image}
              />
            ) : (
              <img src="/assets/user.png" alt="" className={styles.image} />
            )}
            <div className={styles.textSection}>
              <div className={styles.name}>
                {storeOwner?.firstName} {storeOwner?.lastName}
              </div>
              <div>{store.name}</div>
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.title}>
              Ciao! Sono a tua disposizione, inviami subito un messaggio!
            </div>

            {!user ? (
              <a className="greenLink" onClick={() => navigate("/auth/login")}>
                Accedi o Registrati
              </a>
            ) : sent ? (
              <>
                <div className={styles.messageSent}>{content}</div>
                <div className={styles.continue}>
                  continua la conversazione nella chat!
                </div>
              </>
            ) : (
              <>
              {
                !isLoading ?
              
                <form className={styles.form} onSubmit={handleSubmit}>
                  <input
                    type="text"
                    id={styles.content}
                    value={content}
                    onChange={(e) => {
                      setContent(e.target.value);
                    }}
                    placeholder="Scrivi il messaggio"
                  />
                  <button type="submit" className={styles.submitButton}>
                    <IconSend2 className={styles.sendIcon}></IconSend2>
                  </button>
                </form>
                : 
                <Loader isText={true} fullScreen={false}/>
}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SendMessage;
