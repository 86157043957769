import styles from "./Badge.module.css";
import { useNavigate } from "react-router-dom";

const Badge = ({ name, image, path, vertical=false }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className={`${vertical? styles.verticalContainer: styles.container}`} onClick={() => path && navigate(path)} 
      style={{
        cursor: path ? 'pointer' : ''
      }}>
        {image ? (
          <img
            src={image.replace("upload/", "upload/q_50/")}
            alt=""
            className={styles.image}
          />
        ) : (
          <img
            src= "/assets/user.png"
            alt=""
            className={styles.image}
          />        )}
        <div className={styles.name}>{name}</div>
      </div>
    </>
  );
};

export default Badge;
