import styles from "./profilePage.module.css";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLogout } from "state";
import { useNavigate, useParams } from "react-router-dom";
import OrdersSection from "components/OrdersSection";
import ReviewsSection from "components/ReviewsSection";
import { serverHost } from "helpers/usefulData";
import BentoCart from "components/BentoCart";
import Sidebar from "components/Sidebar";
import ProductHistoryCarousel from "components/ProductHistoryCarousel";
import ChatPage from "scenes/chatPage";
import CarouselWrapper from "components/CarouselWrapper";
import Tag from "components/Tag";
import { IconUser } from "@tabler/icons-react";
import FAQPage from "scenes/FAQPage";
import RedeemStore from "components/RedeemStore";

const ProfilePage = () => {
  const [user, setUser] = useState(null);
  const [searchHistory, setSearchHistory] = useState(JSON.parse(localStorage.getItem("searchHistory")));
  const token = useSelector((state) => state.token);
  const userId = useSelector((state) => state.user._id);
  const isOwner = useSelector((state) => state.user.isOwner);
  const [store, setStore] = useState(null);
  let { section } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Il mio profilo - Tralevie ";

    getUser();
    getStore();
  }, []);

  if (!token) navigate("/auth/login");
  if (token == null) {
    return null;
  }

  var link = serverHost + `users`;
  const getUser = async () => {
    const response = await fetch(link, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    setUser(data);
  };
  const getStore = async () => {
    const response = await fetch(serverHost + `stores/myStore`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    setStore(data);
  };
  var link = serverHost + `users`;
  const deleteUser = async () => {
    const response = await fetch(link, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    setUser(data);
  };

  const setOffline = async () => {
    const response = await fetch(serverHost + "users/setOffline/" + user._id, {
      method: "GET",
    });
    const data = await response.json();
  };
  if (!user) return null;

  return (
    <>
      <Navbar />

      <div className={window.innerWidth < 1000 ? styles.mobileContainer : styles.container}>
        <Sidebar type="client"/>

        <div className={styles.contentContainer}>
          <ul className="breadCrumb">
            <li className="breadCrumbElement">
              <a href="/">Homepage</a>
            </li>{" "}
            {">"}
            <li className="breadCrumbElement">
              <a href="ilMioProfilo">
                {user?.firstName + " " + user?.lastName}
              </a>
            </li>
          </ul>
          {(section == "dashboard" || section == null) && (
            <>
              <div className={styles.profileContainer}>
              <div className="secondaryTitle"><IconUser></IconUser>Il mio Profilo</div>

<div className={styles.firstRow}>

              <div className={styles.dataContainer}>
              <img
                src={user?.picturePath ? user?.picturePath : "/assets/user.png"}
                className={styles.img}
              />
                <div className={styles.textData}>
                  <div className={styles.names}>
                  <div className={styles.id}>#{user?._id}</div>

                    <div className={styles.dataName}>
                      {user?.firstName + " " + user?.lastName}
                    </div>
                    <div className={styles.data}>{user?.email}</div>
                    <div className={styles.actionsContainer}>
                      <button
                        className="mainButtonRed"
                        onClick={() => {
                          setOffline();
                          dispatch(setLogout());
                          navigate("/");
                        }}
                      >
                        Esci dall'account
                      </button>
                      <button
                        className="mainButtonYellow"
                        onClick={() => {
                          navigate("/auth/edit");
                        }}
                      >
                        Modifica
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              
            {!user.isOwner &&(
              <div className={styles.storeSection}>
                <div className={styles.left}>
                  <img
                    src="/assets/store.png"
                    alt="store icon"
                    className={styles.illustration}
                  />
                </div>
                <div className={styles.right}>
                  <div className={styles.storeSectionTitle}>
                    Vuoi vendere con noi? Crea il tuo negozio virtuale in 5 minuti!
                  </div>
                  <button
                    className="mainButtonGreen"
                    onClick={() => {
                      navigate("/creaNegozio");
                    }}
                  >
                    Crea Negozio
                  </button>
                </div>
                <RedeemStore/>
              </div>
            )}
            </div>

            </div>
            <div className={styles.historyContainer}>
            {searchHistory?.length > 0 && (<>
                    <button className={`redLink ${styles.clear}`} onClick={()=>{      localStorage.setItem("searchHistory", null); setSearchHistory([])
                  }}>Pulisci cronologia</button>
        <CarouselWrapper title="Cronologia ricerche">
          {searchHistory.map((term, index) => (
            <Tag key={index} name={term}  icon={"search"}/>
          ))}
        </CarouselWrapper>
        </>
      )}
            <ProductHistoryCarousel></ProductHistoryCarousel>

            </div>
            

            </>
          )}
          {section == "carrello" && <BentoCart />}
          {(section == "ordini") &&<div className={styles.ordersSection}>
        <div className={styles.ordersSection}>
          <OrdersSection type={"client"} />
        </div></div>}
        {(section == "chat") &&<div className={styles.ordersSection}>
        <ChatPage fullscreen={true}/>
        </div>}

        {(section == "recensioni") &&<div className={styles.ordersSection}>
        <ReviewsSection userID={user?._id} bento={true} />
        </div>}
        {(section == "aiuto") &&
<FAQPage></FAQPage>
}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProfilePage;
