import { IconChevronLeft, IconChevronRight, IconX } from "@tabler/icons-react";
import styles from "./FullScreenView.module.css";
import { useEffect, useRef, useState } from "react";

const FullScreenView = ({ activeImage, closeFunction, collection = [] }) => {
  const [activeImageIndex, setActiveImageIndex] = useState(activeImage);
  const touchStartX = useRef(null);
  const touchStartY = useRef(null);
  const scrollFlag = useRef(false);
  const imageContainerRef = useRef(null);
  const activeImgContRef = useRef(null);

  const handleTouchStart = (e) => {
    e.stopPropagation()
    touchStartX.current = e.touches[0].clientX;
    scrollFlag.current = false;

  };
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "ArrowLeft") {
        setActiveImageIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : prevIndex
        );
      } else if (e.key === "ArrowRight") {
        setActiveImageIndex((prevIndex) =>
          prevIndex < collection.length - 1 ? prevIndex + 1 : prevIndex
        );
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [activeImageIndex, collection]);

  const handleTouchMove = (e) => {
    e.stopPropagation()
    if (scrollFlag.current) return;
  
    const touchEndX = e.touches[0].clientX;
    const touchEndY = e.touches[0].clientY;
    const deltaX = touchStartX.current - touchEndX;
    const deltaY = touchStartY.current - touchEndY;
    const sensitivity = 50; // Adjust sensitivity as needed
  
    if (Math.abs(deltaY) > Math.abs(deltaX) && Math.abs(deltaY) > 500) {
      // Vertical drag detected, close fullscreen view
      closeFunction(false);
      return;
    }
  
    if (deltaX > sensitivity) {
      // Swipe left
      setActiveImageIndex((prevIndex) =>
        prevIndex < collection.length - 1 ? prevIndex + 1 : prevIndex
      );
      scrollFlag.current = true;
    } else if (deltaX < -sensitivity) {
      // Swipe right
      setActiveImageIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
      scrollFlag.current = true;
    }
      const containerWidth = imageContainerRef.current.offsetWidth;
    const translation = -deltaX / containerWidth * 100;
    activeImgContRef.current.style.transform = `translateX(${translation}%)`;
  };
  const handleTouchEnd = (e) => {
    e.stopPropagation()
    // Reset translation and flags on touch end
    activeImgContRef.current.style.transform = 'translateX(0)';
    scrollFlag.current = false;
  };
  return (
    <>
          <div
        ref={imageContainerRef}
        className={styles.imageContainer}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
      >
        <button className={styles.button} onClick={() => closeFunction(false)}>
          <IconX></IconX>
        </button>

        {collection[activeImageIndex - 1] && (
          <button
            className={styles.buttonArrow}
            onClick={() => setActiveImageIndex(activeImageIndex - 1)}
          >
            <IconChevronLeft className={styles.arrow}></IconChevronLeft>
          </button>
        )}
        <div className={styles.activeImgCont}         ref={activeImgContRef}
>
          <img
            src={
              collection.length > 0
                ? collection[activeImageIndex]
                : activeImageIndex
            }
            className={styles.activeImg}
          />
          <div className={styles.dotsConatiner}>
            <div
              className={`${
                collection[activeImageIndex + 1] &&
                !collection[activeImageIndex - 1] &&
                styles.activeDot
              } ${styles.dot}`}
              onClick={() =>
                collection[activeImageIndex - 1] &&
                setActiveImageIndex(activeImageIndex - 1)
              }
            ></div>
            <div
              className={`${
                collection[activeImageIndex + 1] &&
                collection[activeImageIndex - 1] &&
                styles.activeDot
              } ${styles.dot}`}
            ></div>
            <div
              className={`${
                !collection[activeImageIndex + 1] &&
                collection[activeImageIndex - 1] &&
                styles.activeDot
              } ${styles.dot}`}
              onClick={() =>
                collection[activeImageIndex + 1] &&
                setActiveImageIndex(activeImageIndex + 1)
              }
            ></div>
          </div>
        </div>

        {collection[activeImageIndex + 1] && (
          <button
            className={styles.buttonArrowRight}
            onClick={() => setActiveImageIndex(activeImageIndex + 1)}
          >
            <IconChevronRight className={styles.arrow}></IconChevronRight>
          </button>
        )}
      </div>
    </>
  );
};

export default FullScreenView;
