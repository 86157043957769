import styles from "./ProductBadge.module.css";
import { useNavigate } from "react-router-dom";

const ProductBadge = ({ product }) => {
  const navigate = useNavigate();
  return (

    
    <>
      <div className={styles.container} onClick={() => navigate("/prodotto/" + product._id)} 
      style={{
        cursor: 'pointer'
      }}>
        <div className={styles.quantity}>
        {product.quantity? product.quantity+"x":""}
        </div>
              {product?.picture!=null && typeof(product?.picture)!=undefined && product?.picture.length!=0 && product?.picture[0].length!=""  ? (
                <img
                src={product?.picture[0].replace("upload/", "upload/q_50/")}
                  alt=""
                  className={styles.image}
                />
              ) : (
<img src="/assets/product.png" alt="" className={styles.image}/>
              )}
        <div>
        <div className={styles.name}>{product.name} ({product.quantity ? product.price/product.quantity : product.price}€)</div>
        <div className={styles.info}>{product.option}
                 </div>
        </div>



        
      </div>
    </>
  );
};

export default ProductBadge;
