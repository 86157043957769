import styles from "./OrdersSectionReminder.module.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { serverHost } from "helpers/usefulData";
import {
  IconAlertTriangle,
  IconPackageExport,
  IconPackageOff,
  IconReceipt,
  IconReceiptRefund,
} from "@tabler/icons-react";
import { IconPackage } from "@tabler/icons-react";
import { IconChevronRight } from "@tabler/icons-react";
import { IconMessage } from "@tabler/icons-react";
import Loader from "./Loader";
const OrdersSectionReminder = () => {
  const navigate = useNavigate();
  //var storeId = useParams();
  const [orders, setOrders] = useState(null);
  const [store, setStore] = useState(null);
  const [products, setProducts] = useState(null);
  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);
  const chatNotificationNumber = useSelector(
    (state) => state.chatNotificationNumber
  );

  const [statusCounts, setStatusCounts] = useState({
    created: 0,
    confirmed: 0,
    delivered: 0,
  });
  const countStatusTypes = (orders) => {

    const newStatusCounts = {
      created: 0,
      confirmed: 0,
    };
    console.log("newStatusCounts",newStatusCounts)

    for (const order of orders) {
      switch (order.status) {
        case "creato":
          newStatusCounts.created++;
          break;
        case "confermati":
          newStatusCounts.confirmed++;
          break;
        default:
          // Ignore any other status types, if there are any
          break;
      }
    }
    console.log("newStatusCounts",newStatusCounts)

    setStatusCounts(newStatusCounts);
  };

  useEffect(() => {
    getOrders();
    getStore();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    getProducts();
  }, [store]);
  const getOrders = async () => {
    var link = serverHost + `orders/shopOrders/all`;
    const response = await fetch(link, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    setOrders(data);
    countStatusTypes(data);
  };
  const getStore = async () => {
    const response = await fetch(serverHost + `stores/myStore`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    setStore(data);
  };
  const getProducts = async () => {
    var link = serverHost + "products/store/" + store?._id;
    const response = await fetch(link, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    setProducts(data);
  };

  return (
    <>
      {(statusCounts.created > 0 ||
        statusCounts.confirmed > 0 ||
        products?.filter((product) => product.inventory === 0).length > 0 ||
        chatNotificationNumber > 0) && (
        <>
          <div className={styles.container}>
            <div className="secondaryTitle">
              {" "}
              <IconAlertTriangle></IconAlertTriangle> Pannello di controllo del
              tuo negozio
            </div>
            {orders == null ? (
              <Loader />
            ) : (
              <>
                <div className={styles.dataContainer}>
                  {statusCounts.created > 0 && (
                    <div className={styles.data}>
                      <div
                        className={styles.cardLink}
                        onClick={() => navigate("/ilMioNegozio/ordini")}
                      >
                        vedi{" "}
                        <IconChevronRight
                          className={styles.icon}
                        ></IconChevronRight>
                      </div>
                      <IconReceipt></IconReceipt>
                      <div>
                        {" "}
                        <div className={styles.num}>
                          {" "}
                          {statusCounts.created}
                        </div>
                        <div className={styles.dataTitle}>
                          Ordini da confermare
                        </div>
                      </div>
                    </div>
                  )}
                  {statusCounts.confirmed > 0 && (
                    <div className={styles.data}>
                      <div
                        className={styles.cardLink}
                        onClick={() => navigate("/ilMioNegozio/ordini")}
                      >
                        vedi{" "}
                        <IconChevronRight
                          className={styles.icon}
                        ></IconChevronRight>
                      </div>
                      <IconReceiptRefund
                        style={{ transform: "scaleX(-1)" }}
                      ></IconReceiptRefund>
                      <div>
                        <div className={styles.num}>
                          {statusCounts.confirmed}
                        </div>

                        <div className={styles.dataTitle}>
                          Ordini da spedire
                        </div>
                      </div>
                    </div>
                  )}
                  {products?.filter((product) => product.inventory === 0)
                    .length > 0 && (
                    <div className={styles.data}>
                      <div
                        className={styles.cardLink}
                        onClick={() => navigate("/ilMioNegozio/prodotti")}
                      >
                        vedi{" "}
                        <IconChevronRight
                          className={styles.icon}
                        ></IconChevronRight>
                      </div>
                      <IconPackageOff></IconPackageOff>
                      <div>
                        <div className={styles.num}>
                          {" "}
                          {
                            products?.filter(
                              (product) => product.inventory === 0
                            ).length
                          }
                        </div>
                        <div className={styles.dataTitle}>
                          Prodotti Esauriti
                        </div>
                      </div>
                    </div>
                  )}

                  {chatNotificationNumber > 0 && (
                    <div className={styles.data}>
                      <div
                        className={styles.cardLink}
                        onClick={() => navigate("/ilMioNegozio/chat")}
                      >
                        vedi{" "}
                        <IconChevronRight
                          className={styles.icon}
                        ></IconChevronRight>
                      </div>
                      <IconMessage></IconMessage>
                      <div>
                        <div className={styles.num}>
                          {" "}
                          {chatNotificationNumber}
                        </div>
                        <div className={styles.dataTitle}>Nuovi messaggi</div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default OrdersSectionReminder;
