import styles from "./ImageDisplay.module.css";
import {  useState } from "react";
import { useNavigate } from "react-router-dom";
import FullScreenView from "./FullScreenView";

const ImageDisplay = ({ collection }) => {
  const navigate = useNavigate();
  const [activeImage, setActiveImage] = useState(collection[0]);
  const [fullScreen, setFullScreen] = useState(false);

  
  return (
    <>
    {fullScreen&& <FullScreenView activeImage={collection.indexOf(activeImage)} collection={collection} closeFunction={setFullScreen}/>}
      <div className={styles.imagesContainer}>
        {collection.length == 0 ?(
          <img
            src="/assets/product.png"
            className={styles.activeImg}
          />
        ):(
          <img onClick={()=>setFullScreen(true)}
            src={activeImage}
            className={styles.activeImg}
          />
        )}
              {collection?.length > 1 && (
          <div className={styles.fullImageList}>
            {collection?.map((img) => (
              <img
                src={ img.replace("upload/", "upload/q_50/")}
                key={img}
                className={styles.productImg}
                onClick={() => setActiveImage(img)}
              />
            ))}
          </div>
        )}
       
      </div>
    </>
  );
};

export default ImageDisplay;
