import styles from "./OrderFullfilledPage.module.css";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { serverHost } from "helpers/usefulData";
import { useSelector } from "react-redux";
import OrderCard from "components/OrderCard";

const OrderFullfilledPage = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState(null);
  const token = useSelector((state) => state.token);

  const getOrder = async () => {
    var link = serverHost + `orders/getNewOrders/`;
    const response = await fetch(link, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    setOrders(data);
    console.log("ordinissss",data);
  };

  useEffect(() => {
    document.title = "Ordine Effettuato - Tralevie ";
    getOrder();
  }, []);

  return (
    <>
      <Navbar />
      <div className={styles.container}>
        <div className={styles.textContainer}>
                  <img src="/assets/happy.png" className={styles.icon} alt="" />
        <div className={styles.positiveMex}>Ordine Effettuato!</div>
        <div className={styles.positiveMexParag}>
          Grazie di cuore per l'ordine effettuato! Abbiamo
          prontamente passato il tuo ordine ai negozi coinvolti e attendiamo la loro conferma. Resta sintonizzato, perché ti
          invieremo una email per ogni aggiornamento! Una volta spediti, i tuoi
          articoli arriveranno a casa tua entro 2/3 giorni
          lavorativi. Grazie!
        </div>
        </div>

        <div className={styles.orders}>
        {orders && orders?.map((ord, index) => (
         <OrderCard key={index} order={ord} type={"client"} />
        ))
       }
        </div>

      </div>

      <Footer />
    </>
  );
};

export default OrderFullfilledPage;
