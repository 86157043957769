import { useState, useEffect } from "react";
import styles from "./FormBar.module.css";


const FormBar = ({phases, step, showPhases=true}) => {
  const [progress, setProgress] = useState((step/(phases.length-1))*100);


  return (
    <div  className={styles.container}>

      <div className={styles.progressBarContainer}>
      <div  className={styles.labelContainer}>
      {showPhases && <div className={styles.label}>{step!=-1? phases[step]: "Annullato"}</div>}
      {/*
      {phases?.map((el) => (
            <div className={styles.label}>{el}</div>
      ))}*/}
      </div>
			<div className={styles.progressBar}>
				<span className={styles.progressBarFill} style={{width: (step/(phases.length-1))*100+"%"}}></span>
        {phases.map((_, index) => (
            <div
              key={index}
              className={styles.circle}
              style={{
                left: `${(index / (phases.length - 1)) * 100}%`,
                backgroundColor: index <= step ? "var(--main)" : "lightgray",
              }}
            ></div>
          ))}
			</div>
      </div>


    </div>
  );
};

export default FormBar;
