import styles from "./ProductCarousel.module.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GenericCarousel from "./GenericCarousel";
import Loader from "./Loader";
import { serverHost } from "helpers/usefulData";
const ProductCarousel = ({ storeID, type="" }) => {
  const [products, setProducts] = useState(null);
  useEffect(() => {
    getProducts();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getProducts = async () => {
    var link = serverHost+ "products";
    if (storeID != "") link = serverHost+ "products/store/" + storeID;
    if (type=="best") link = serverHost+ "products/topSelling";
    const response = await fetch(link, {
      method: "GET",
    });
    var data = await response.json();
    if(storeID == "") data.sort(() => Math.random() - 0.5);

    setProducts(data);
  };

  return (<>
  <GenericCarousel type={"product"} collection={products} title={""}/>
      {/*!products ? (<Loader/>)
      :
      (    <GenericCarousel type={"product"} collection={products? products : []} title={""}/>
      )
  */}
    </>
  );
};

export default ProductCarousel;

