
import styles from "./chatPage.module.css";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLastAccess , setChatNotificationNumber} from "state";
import { useNavigate } from "react-router-dom";
import Conversation from "components/Conversation";
import Message from "components/Message";
//import { io } from "socket.io-client";
import Loader from "components/Loader";
import ProductRow from "components/ProductRow";
import { serverHost } from "helpers/usefulData";
import imageCompression from "browser-image-compression";
import { IconChevronLeft, IconMessage, IconPackage, IconPaperclip, IconPhotoScan, IconRefresh, IconSend, IconSend2, IconShare, IconX } from "@tabler/icons-react";

//import bellSound from "../assets/bellSound.mp3"
const ChatPage = ({ fullscreen = false }) => {
  const [conversations, setConversations] = useState(null);

  const [oldConversations, setOldConversations] = useState(null);

  const [prevConversations, setPrevConversations] = useState(null);
  const [notificationWhiteList, setNotificationWhiteList] = useState([]);
  const [products, setProducts] = useState([]);
  const [store, setStore] = useState([]);

  const [currentChat, setCurrentChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [notificationList, setNotificationList] = useState([]);
  const user = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [previousAccess, setPreviousAccess] = useState(user.lastAccess);
  const [firstCheck, setFirstCheck] = useState(false);

  const navigate = useNavigate();
  //const socket = useRef();
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [hidden, setHidden] = useState(fullscreen);
  const [image, setImage] = useState();
  const [imagePreview, setImagePreview] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showFormAttached, setShowFormAttached] = useState(false);

  const token = useSelector((state) => state.token);
  const divRef = useRef(null);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    getConversations();
    user.isOwner && getStore();
    
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (conversations && !firstCheck) {
      setNotificationWhileOffline()
      setFirstCheck(true);

    }
  }, [conversations]); 
  useEffect(() => {
dispatch(
    setChatNotificationNumber({
      chatNotificationNumber: notificationList.length,
    })
  );
  }, [notificationList]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    getProducts();
  }, [store, hidden]);

  useEffect(() => {
    // create the preview
    if(image==null || image== "") return
    const objectUrl = URL.createObjectURL(image)
    setImagePreview(objectUrl)
    // setPreview(objectUrl)
  
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
    
  }, [image])

  useEffect(() => {
    const interval = setInterval(() => {
      getConversations();
      dispatch(setLastAccess());
      const now = new Date();
      setPreviousAccess((prev) => now.toISOString());
    }, 50000);
    return () => clearInterval(interval);
  }, [previousAccess]);

  useEffect(() => {
    divRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    const interval = setInterval(() => {
      currentChat && getMessages();
    }, 10000);

    return () => clearInterval(interval);
  }, [messages]);

  const checkConvsUpdates = (newConversations, oldConversations) => {
    if (oldConversations == null || newConversations==null) return;
    var tempNot = [];
    /*
    for (let i = 0; i < conv1?.length; i++) {
      if ( conv1[i]?._id != currentChat?._id &&
        (conv1[i]?._id !== conv2[i]?._id && conv1[i]?.lastMessage !== conv2[i]?.lastMessage)  ||
        (conv1[i]?._id == conv2[i]?._id &&
          conv1[i]?.lastMessage !== conv2[i]?.lastMessage)
      ) {
        tempNot = tempNot.concat(conv1[i]?._id);
        console.log("this bith", conv1[i], "vs this bith", conv2[i]);
      }
    }
    */
   
  for (let i = 0; i < newConversations.length; i++) {
    const newConv = newConversations[i];
    const oldConv = oldConversations.find(conv => conv._id === newConv._id);

    if ((!oldConv ||  oldConv.lastMessage !== newConv.lastMessage) && newConv.lastMessageSenderID != user._id) {
      tempNot = tempNot.concat(newConv._id);
    }
  }
    setNotificationWhiteList([]);
    tempNot != [] && setNotificationList([...notificationList, ...tempNot]);
    return;
  };
  
  useEffect(() => {
    
    const interval = setInterval(() => {
      getConversations();
      checkConvsUpdates(conversations, prevConversations);
      setPrevConversations(conversations);
    }, 10000);

    return () => clearInterval(interval);
  }, [conversations]);

  function playSound() {
    var audio = new Audio("../assets/bellSound.mp3");
    audio.play();
  }

  const getConversations = async () => {
    const response = await fetch(serverHost+ `conversations/`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    setConversations(data);
    if (!firstCheck) {
      setOldConversations(data);
    }
    return await data;
  };

  const setNotificationWhileOffline = () => {
    const filteredConversations = conversations
      ?.filter((conversation) => {
        return new Date(conversation.lastMessage) > new Date(previousAccess);
      })
      .forEach(
        (conversation) =>{
          !notificationList.includes(conversation._id) &&
          conversation._id != currentChat?._id &&
          conversation.lastMessageSenderID != user._id &&
          setNotificationList([...notificationList, conversation._id])}
      );
  };

  const getMessages = async () => {
    try {
      const response = await fetch(
        serverHost+ `messages/${currentChat?._id}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = await response.json();
      (messages[0]?._id!=data[0]?._id || messages[0]==null) && setMessages(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (currentChat) {
      getMessages();
      setTimeout(() => {
        divRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
      }, 500); // Adjust the delay as needed
      const index = notificationList.indexOf(currentChat?._id);
      if (index !== -1) {
        const tempList = [...notificationList];
        tempList.splice(index, 1);
        setNotificationList(tempList);
      }
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
    
  }, [currentChat]);

  const refresh = async () => {
    setIsRefreshing(true);
    await getConversations();
    
    setIsRefreshing(false);
  };
  const refreshMessages = async () => {
    setIsRefreshing(true);
    getMessages();
    setIsRefreshing(false);
  };
  async function processImages(formData) {
    const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: false,
    };
    const compressedFile = await imageCompression(image, options);
    formData.append("picture", compressedFile);
return formData
}
  const submitImage = async () => {
    var formData = new FormData();
    formData= await processImages(formData)
    const savedImageResponse = await fetch(
      serverHost+ "messages/uploadImage",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
    const savedImage = await savedImageResponse.json();
    return savedImage;
  };
  const handleSubmit = async (e=null, mex=null) => {
    setIsLoading(true);
    e && e.preventDefault();
    var content = newMessage;
    var type = "";
    if (image) {
      content = await submitImage();
      type = "image";
    }

    const message = {
      senderID: user._id,
      text: mex ? mex : content,
      type: type,
      conversationID: currentChat._id,
    };
/*
    const receiverId = currentChat.members.find(
      (member) => member !== user._id
    ); 
    socket.current.emit("sendMessage", {
      senderID: user._id,
      receiverId,
      text: content,
      type: type,
      conversationID: currentChat._id,
    });*/
    const savedMessagetResponse = await fetch(
      serverHost+ "messages/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(message),
      }
      
    );
    setIsLoading(false);


    const savedMessage = await savedMessagetResponse.json();
    setNotificationWhiteList([...notificationList, currentChat._id]);
    setMessages([...messages, savedMessage]);

    divRef.current?.scrollIntoView({ behavior: "smooth", block: "end"  });

    const conversationIndex = conversations.findIndex(
      conv => conv._id === currentChat._id
    );

    

    if (conversationIndex !== -1) {
      const updatedConversations = [...conversations];
      const updatedConversation = {
        ...updatedConversations[conversationIndex],
        lastMessage: savedMessage?.createdAt,
        lastMessageSenderID: savedMessage?.senderID
      };
      updatedConversations.splice(conversationIndex, 1); // Remove the conversation from its current index
      updatedConversations.unshift(updatedConversation); 
      setConversations(updatedConversations);
      setPrevConversations(updatedConversations);

   
      

    }

    setNewMessage("");
    setImage("");
    setShowForm(false);
    setShowFormAttached(false)
  };

  const getProducts = async () => {
    var link = serverHost+ "products/store/" + store?._id;
    const response = await fetch(link, {
      method: "GET",
    });
    const data = await response.json();
    setProducts(data);
  };
  const getStore = async () => {
    const response = await fetch(serverHost+ `stores/myStore`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    setStore(data);
  };

  return (
    <div
      className={fullscreen? styles.totContainerFullscreen : styles.totContainer}
      onClick={() => {hidden &&setHidden(!hidden)}} style={{
        backgroundColor:
          hidden && !fullscreen ? "rgba(0, 0, 0, 0.7)" : "transparent",
        pointerEvents: hidden ? "auto" : "none",
        cursor: hidden && !fullscreen? "pointer": "auto",
      }}
    >
      <div
      onClick={(e) => e.stopPropagation()}
        className={fullscreen? styles.containerFullscreen : styles.container} 
        style={{
          transform: hidden ? "translateX(0)" : "translateX(100%)",
          width: fullscreen && "100%",
        }}
      >
        {!fullscreen && 
        <button
          className={styles.showButton}
          onClick={() => setHidden(!hidden)}
        >
          {notificationList.length != 0 && (
            <div className={styles.notification}>!</div>
          )}
          <IconMessage className={styles.showButtonIcon}></IconMessage>
          <div>chat</div>
        </button>}
        {!currentChat && (
          <div className={styles.list}>
            <div className={styles.listTitle}>
              {!fullscreen && (
                <div
                  className={styles.backButtonIcon}
                  onClick={() => setHidden(!hidden)}
                ><IconX></IconX>
                                  </div>
              )}
              <div className={styles.logoText}>
                <IconMessage></IconMessage>Tralevie Chat
              </div>
              <div></div>
            </div>

            <button onClick={refresh} className={`${styles.refresh} greenLink ${isRefreshing && styles.turnIcon}`}>
    
                <IconRefresh></IconRefresh>
             
            </button>

            {conversations != null &&
              conversations?.map((el,index) => (
                <div
                  className={styles.conversationCont}
                  key={index}
                  onClick={() => {
                    setCurrentChat(el);
                  }}
                >
                  <Conversation
                  key={el._id}
                    conversation={el}
                    isActive={el == currentChat}
                    hasNotification={notificationList.includes(el._id)}
                  />
                </div>
              ))}
              {conversations?.length==0 && 
              <img src="/assets/chat.png" alt="immagine di chat" className={styles.chatIllustration}/>
              }
            <div className={styles.listExplain}>
              {" "}
              Per inviare un messaggio ad un negozio recati sul sua pagina e
              inviagli un messaggio
            </div>
          </div>
        )}
        {currentChat && (
          <div className={styles.chat}>
            <div className={styles.firstRow}>
              <div
                className={styles.backButtonIcon}
                onClick={() => {
                  setCurrentChat(null);
                }}
              >
                <IconChevronLeft></IconChevronLeft>
              </div>
              <Conversation conversation={currentChat} isActive={true} />
            </div>
            <div
              onClick={refreshMessages}
              className={`${styles.refresh} greenLink  ${isRefreshing && styles.turnIcon}`}
            >
                <IconRefresh></IconRefresh>

            </div>
            <div className={styles.mexContainer}>
              {messages.map((m, i) => (
                <Message
                  key={i}
                  message={m}
                  own={m.senderID === user._id}
                />
              ))}
              {messages.length==1 && messages[0].senderID == user._id  && 
              <div className={styles.listExplain}>Messaggio inviato! A breve riceverai una risposta.</div>
              }
              <div />
              <div ref={divRef}></div>
            </div>
            <form className={styles.newMex}>
              {showFormAttached && 
              <div className={styles.attachedContainer}> 
                <div className={styles.fileInputLabel}
                onClick={() => {setShowForm(!showForm);  setShowFormAttached(false)}}>
                
                  <IconPackage></IconPackage> Prodotto

                </div>
                <div  className={styles.attached}>
                  <input
                className={styles.fileInput}
                id="fileInput"
                type="file"
                accept=".jpg, .jpeg, .png"
                onChange={(e) => {
                  setImage(e.target.files[0]);
                  setNewMessage(e.target.files[0].name);
                }}
                capture
              />
              <label className={styles.fileInputLabel} htmlFor="fileInput">
              <IconPhotoScan></IconPhotoScan> Immagine
              </label>
                </div>
              </div>}
              {user.isOwner && (
                <button
                  className={`${styles.tempProdButton}`}
                  type="button"
                  onClick={() => setShowFormAttached(!showFormAttached)}
                >
                  <IconPaperclip></IconPaperclip>
                </button>
              )}
              { image==null || image=="" ?
              <input
                className={styles.newMexInput}
                ref={inputRef}
                type="text"
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Scrivi..."
                value={newMessage}
              />
            :
            <div className={styles.imagePreviewContainer}>
              <img src={imagePreview} alt="" className={styles.imagePreview}/>
              <button
                className={styles.removePost}
                onClick={() => {
                  setImage("")
                  setNewMessage("");
                }}
              >
               <IconX></IconX>
              </button>
            </div>
            }

              {showForm && user.isOwner == true && (
                <div className={styles.proposalContainer}>
                  
                  <div className={styles.productListContainer}>
                    <button
                    type="button"
                    className={styles.propClose}
                    onClick={() => setShowForm(!showForm)}
                  >
                    <IconX></IconX>
                  </button>
                    <div className={styles.prodTitle}>Condividi un prodotto esistente o creane uno nuovo</div>
                    <a
                      className="mainButtonGreen"
                      onClick={() => navigate("/aggiungiProdotto")}
                    >
                      {" "}
                      crea nuovo prodotto
                    </a>
                    <div className={styles.productList}>
                      {products.map((product, i) => (
                        <div className={styles.prodRow} key={i} onClick={() => {
                          handleSubmit(null,`prodotto/${product._id}`)}}>
                          {" "}
                          <ProductRow key={product._id} product={product} />
                          <div className={styles.shareButton} onClick={() => {
                          handleSubmit(null,`prodotto/${product._id}`)}}>
                            {" "}
                            <IconShare></IconShare>
                          </div>
                        </div>
                        
                      ))}
                      
                    </div>
                  </div>
                </div>
              )}

              <button
                type="submit"
                disabled={!newMessage && !image}
                className={styles.sendMessageButton}
                onClick={handleSubmit}
              >
                {isLoading ? (
                  <Loader fullScreen={false} isText={true} />
                ) : (
                  <IconSend2></IconSend2>
                )}
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatPage;
