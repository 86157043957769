import styles from "./ordersListPage.module.css";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {  useSelector } from "react-redux";
import { PopupContext } from "App";

import OrdersSection from "components/OrdersSection";
import { serverHost } from "helpers/usefulData";
const OrdersListPage = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const [store, setStore] = useState(null);
  const [products, setProducts] = useState(null);
  const [popup, setPopup] = useContext(PopupContext);


  const user = useSelector((state) => state.user);
  useEffect(() => {
    document.title = "Il mio negozio - Tralevie";
  }, []);
  useEffect(() => {
    getStore();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    getProducts();
  }, [store]);
  const getStore = async () => {
    const response = await fetch(serverHost+ `stores/myStore`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    setStore(data);
  };
  const deleteProduct = async (id) => {
    const response = await fetch(
      serverHost+ `products/deleteProduct/` + id,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    if (response.ok) {
      console.log("dleeted success");
      setPopup({
        type: "success",
        message: "Prodotto eliminato con successo",
      });
      const newList = products.filter((item) => item._id !== id);
      setProducts(newList);
    }
  };

  const getProducts = async () => {
    var link = serverHost+ "products/store/" + store?._id;
    const response = await fetch(link, {
      method: "GET",
    });
    const data = await response.json();
    setProducts(data);
  };
  return (
    <>
      <Navbar />
      <ul className="breadCrumb">
        <li className="breadCrumbElement">
          <a href="/">Homepage</a>
        </li>{" "}
        {">"}
        <li className="breadCrumbElement">
          <a href={"/IMieiOrdini/"}>I Miei Ordini</a>
        </li>
      </ul>
      <div className={styles.ordersSection}>
          <OrdersSection type="store" />
        </div>
      <Footer />
    </>
  );
};

export default OrdersListPage;
