import { useEffect, useState } from "react";
import styles from "./ProductHistoryCarousel.module.css";
import CarouselWrapper from "./CarouselWrapper";
import ProductCard from "./ProductCard";

const ProductHistoryCarousel = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    const history = JSON.parse(localStorage.getItem("productHistory")) || [];
    setProducts(history);
  };
  return (
    <>
      {products.length > 0 && (<div>
        <div className="secondaryTitle">
        I prodotti che hai visitato di recente
        </div>
        <button className={`redLink ${styles.clear}`} onClick={()=>{      localStorage.setItem("productHistory", null); setProducts([])
                  }}>Pulisci cronologia</button>
        <CarouselWrapper title="">
          {products.map((prod, index) => (
            <ProductCard key={index} product={prod} />
          ))}
        </CarouselWrapper>

        </div>
      )}
    </>
  );
};

export default ProductHistoryCarousel;
