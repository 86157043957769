import { IconStar, IconStarFilled, IconStarHalfFilled } from "@tabler/icons-react";
import styles from "./RatingStars.module.css";
import { useEffect, useState } from "react";

const RatingStars = ({ rating=0, inline = false, setRating=null }) => {
  var isEditable= setRating
  const [hoverRating, setHoverRating] = useState(isEditable ? 0 : rating);
  const [ratingState, setRatingState] = useState(parseFloat(rating));

  const handleClick = (value) => {
    if (setRating) {
      setRating(value);
      setRatingState(value)
    }
  };

  return (
    <>
      <div className={`${inline ? styles.ratingButtonsContainerInline : styles.ratingButtonsContainer} ${ratingState+hoverRating === 0 && styles.gray}`}>
        {[...Array(5)].map((_, index) => {
          const starValue = index + 1;
          return (
            <div
              key={index}
              className={`${styles.optionButton} ${isEditable && styles.clickable}`}
              onClick={() => isEditable && handleClick(starValue)}
              onMouseEnter={() => isEditable &&setHoverRating(starValue)}
              onMouseLeave={() => isEditable &&setHoverRating(0)}
            >
              {hoverRating >= starValue || ratingState >= starValue ? (
                <IconStarFilled style={{ filter: 'drop-shadow(0px 0px 2px var(--main))' }} />
              ) : ratingState + 0.5 > starValue ? (
                <IconStarHalfFilled style={{ filter: 'drop-shadow(0px 0px 2px var(--main))' }} />
              ) : (
                <IconStar />
              )}
            </div>
          );
        })}
        {!inline && <div className={styles.textRating}>({(ratingState % 1 === 0) ? ratingState : ratingState.toFixed(ratingState.toString().split('.')[0].length + 1)})</div>}
      </div>
    </>
  );
};

export default RatingStars;
