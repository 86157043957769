import styles from "./StoreShowcase.module.css";
import Post from "./Post";
import { useContext, useEffect, useState } from "react";
import NewPost from "./NewPost";
import { useSelector } from "react-redux";
import { PopupContext } from "App";
import { serverHost } from "helpers/usefulData";
import CarouselWrapper from "./CarouselWrapper";
import { IconCamera, IconCategory, IconTrash } from "@tabler/icons-react";
import ConfirmationModal from "./ConfirmationModal";
const StoreShowcase = ({ store }) => {
  const [posts, setPosts] = useState(null);
  const token = useSelector((state) => state.token);
  const [popup, setPopup] = useContext(PopupContext);
  const [showModal, setShowModal] = useState(false);
  const [deleteElement, setDeleteElement] = useState(null);

  useEffect(() => {
    getPosts();
  }, []);

  const getPosts = async () => {
    const response = await fetch(
      serverHost+ `posts/store/` + store._id,
      {
        method: "GET",
      }
    );
    const data = await response.json();
    setPosts(data);
  };
  const removePost = async () => {
    
    const response = await fetch(
      serverHost+ `posts/delete/` + deleteElement._id,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }

    );
    setDeleteElement()
    setShowModal(false)

    if (!response.ok) {
      setPopup({ type: "error", message: "operazione non riuscita" });
    } else {
      setPopup({ type: "success", message: "Post rimosso con successo" });
    }
    getPosts();
  };
  return (<>
        <ConfirmationModal         showModal={showModal} 
        handleClose={()=>setShowModal(false)} 
        handleConfirm={removePost} 
        title="Conferma eliminazione"
      message={'Sei sicuro di voler eliminare il post?'} positive={false}/>
    <div className={styles.fullContainer}>
      <div className={styles.titleRow}>
        <div className="secondaryTitle">
        <IconCamera></IconCamera> La mia vetrina
      </div>
      <NewPost refresh={getPosts} />

        </div>
      <div className={styles.explanation}>Pubblica foto per raccontare al meglio il tuo negozio!</div>
      <div className={styles.container}>
        <div className={styles.carousel}>

          <CarouselWrapper>
          {posts?.map((el, index) => (
            < >
              <Post store={store} post={el} noName={true} key={index}/>
              <button
                className={styles.removePost}
                onClick={() => {
                  setDeleteElement(el);
                  setShowModal(true)
                }}
              >
                <IconTrash className={styles.removeSVG} ></IconTrash>
              </button>
            </>
          ))}
          </CarouselWrapper>
        </div>
      </div>
    </div>
 </> );
};

export default StoreShowcase;
