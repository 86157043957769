import styles from "./searchPage.module.css";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "components/Loader";
import { PopupContext } from "App";
import Fuse from "fuse.js";
import GenericCarousel from "components/GenericCarousel";
import { serverHost } from "helpers/usefulData";
import CarouselWrapper from "components/CarouselWrapper";
import Tag from "components/Tag";
import GenericDisplay from "components/GenericDisplay";
const StoresPage = () => {
  const navigate = useNavigate();
  const [popup, setPopup] = useContext(PopupContext);
  const [stores, setStores] = useState([]);
  const [searchTerm, setSearchTerm] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const list = [
    "Alimentari",
    "Abbigliamento",
    "Elettronica",
    "Casa",
    "Libri",
    "Gioielli",
    "Attrezzature Sportive",
    "Giocattoli",
    "Calzature",
    "Musica",
    "Farmaci",
    "Arte",
    "Hobby",
    "Intrattenimento",
    "Panificati",
    "Motori",
    "Cura Personale",
  ];
  const options = {
    keys: ['name', "tags", "description"],
    isCaseSensitive: false,
    shouldSort: true,
    threshold: 0.1,
  };
  useEffect(() => {
    document.title =  "Tutti i negozi - Tralevie";
    getStores();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    searchTerm.length>0 && handleSearch()
  }, [searchTerm]);

  const handleSearch = () => {
    if (!searchTerm) {
      setSearchResults(stores);
      return;
    }
    const fuse = new Fuse(stores, options);
    const results = fuse.search(searchTerm);
    console.log(results)

    setSearchResults(results.map((result) => result.item));
  };
  const getStores = async () => {
    const response = await fetch(
      serverHost+ "stores/",
      {
        method: "GET",
      }
    );
    if (!response.ok) {
      setPopup({
        type: "error",
        message: "Errore nel server, per cortesia riprovare",
      });
    } else {
      const data = await response.json();

      setStores(data);
      setSearchResults(data)

    }
  };
  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };


  return (
    <>
      <Navbar />
      <ul className="breadCrumb">
        <li className="breadCrumbElement"><a href="/">Homepage</a></li> {">"}
        <li className="breadCrumbElement"><a href={"/negozi"}>Tutti i negozi</a></li>
      </ul>
      <CarouselWrapper title="Le nostre categorie">
      {list.map((cat, index) => (
        <Tag name={cat} key={index} disabled={true}onClick={(event) => { 
          console.log("checkingggggggggggggg")
          event.stopPropagation();
          console.log("checkingggggggggggggg")
          setSearchTerm(cat);
        }}/>
        ))}
      </CarouselWrapper>
      <input
        type="text"
        placeholder="Cerca negozi..."
        value={searchTerm}
        onChange={handleInputChange}
        className={styles.searchFilter}
      />
          {stores.length<1 ? (
            <Loader fullScreen={false} isText={false}/>          ) : (
            <GenericDisplay
              type={"store"}
              collection={searchResults}
              title={"i negozi di Tralevie"}
            />
          )}

      <Footer />
    </>
  );
};

export default StoresPage;
