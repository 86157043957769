import Footer from "components/Footer";
import Navbar from "components/Navbar";
import React, { useEffect, useState } from "react";
import styles from "./DeliveryPage.module.css";

const DeliveryPage = () => {
  useEffect(() => {
    document.title = "Consegne - Tralevie";
  }, []);

  return (
    <>
      <Navbar />
      <ul className="breadCrumb">
        <li className="breadCrumbElement">
          <a href="/">Homepage</a>
        </li>{" "}
        {">"}
        <li className="breadCrumbElement">
          <a href="/spedizioni">Spedizioni</a>
        </li>
      </ul>

      <div className={styles.container}>
        <div className={styles.row}>
          <img
            className={styles.image}
            src="/assets/delivery-bike.png"
            alt=""
          />

          <div className={styles.textSection}>
            <div className="mainTitle">Spedizioni e consegne</div>
            <div className={styles.text}>
              Le spedizioni attualmente si appoggiano al servizio offerto da Poste Italiane. Una volta confermato un l'ordine 
              verranno inviate per email tutte le informazioni necessarie per spedire correttamente il pacco.
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DeliveryPage;
