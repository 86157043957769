import styles from "./StoreCard.module.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Tag from "./Tag";
import { IconMapPin } from "@tabler/icons-react";
const StoreCard = (props) => {
  const [isHover, setIsHover] = useState(false);

  const store = props.store;
  const navigate = useNavigate();
  if (!store) return null;
  return (
    <>
      <div
        className={styles.card}
        onClick={() => {
          navigate("/negozio/" + store._id);
        }}
      >
        {store?.picture != null &&
        typeof store?.picture != undefined &&
        store?.picture.length != 0 ? (
          <img
            className={styles.icon}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            src={
              !isHover || !store?.picture[1]
                ? store?.picture[0].replace("upload/", "upload/q_50/")
                : store?.picture[1].replace("upload/", "upload/q_50/")
            }
            alt=""
          />
        ) : (
          <img src="/assets/store.png" alt="" className={styles.icon} />
        )}

        <div className={styles.textDataContainer}>
          {/*<RatingStars rating={store.rating}/>*/}

          <div className={styles.name}>{store.name}</div>
          <div className={styles.city}>
            <IconMapPin></IconMapPin> {store.province}
          </div>

        </div>
        <div className={styles.tag}>
          <Tag small={true} name={store.tags[0]} />

          </div>
      </div>
    </>
  );
};

export default StoreCard;
