import Form from "./Form";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import styles from "./createEditStorePageChat.module.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { serverHost } from "helpers/usefulData";
const CreateEditStorePageChat = ({ edit = false }) => {

  var params = useParams();
  const [store, setStore] = useState(null);
  useEffect(() => {
    document.title =
      params.id == null
        ? "Crea negozio - Tralevie "
        : "Modifica negozio - Tralevie ";
    if (params.id != null) {
      getStore();
    }
  }, []);

  const getStore = async () => {
    const response = await fetch(
      serverHost+ `stores/${params.id}`,
      {
        method: "GET",
      }
    );
    const data = await response.json();
    setStore(data.store);
    console.log("storeee", store);
  };

  return (
    <>
      <Navbar />
      <div className={styles.container}>
        <div className={`${styles.dataContainer}`}>
          {store && edit && <Form existingStore={store} />}
          {!edit && <Form existingStore={null} />}

        </div>
      </div>
      <Footer />
    </>
  );
};

export default CreateEditStorePageChat;
