import Form from "./Form";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import styles from "./createEditProductPageChat.module.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { serverHost } from "helpers/usefulData";
const CreateEditProductPageChat = ({ edit = false }) => {

  var params = useParams();
  const [product, setProduct] = useState(null);
  useEffect(() => {
    console.log("parms, ", params)
    console.log(product)
    document.title =
      params.id == null
        ? "Crea Prodotto - Tralevie "
        : "Modifica Prodotto - Tralevie ";
    if (params.id != null) {
      getProduct();
    }else{
      setProduct(null)
    }
  }, []);

  const getProduct = async () => {
    const response = await fetch(
      serverHost+ `products/${params.id}`,
      {
        method: "GET",
      }
    );
    const data = await response.json();
    setProduct(data.product);
    console.log("product",product)
  };
  return (
    <>
      <Navbar />
      <div className={styles.container}>
        <div className={`${styles.dataContainer}`}>
          {product && edit && <Form existingProduct={product} />}
          {!edit && <Form existingProduct={null} />}

        </div>
      </div>
      <Footer />
    </>
  );
};

export default CreateEditProductPageChat;
