import { Formik } from "formik";
import styles from "./NewPost.module.css";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import * as yup from "yup";
import { serverHost } from "helpers/usefulData";
import { useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import imageCompression from "browser-image-compression";

import { PopupContext } from "App";
import Loader from "./Loader";

const storeSchema = yup.object().shape({

});

const initialValues = {

};

const NewPost = ({refresh}) => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const [image, setImage] = useState([]);
  const [popup, setPopup] = useContext(PopupContext);
  const [isLoading, setIsLoading] = useState(false);

  async function processImages(formData,file) {
        const options = {
            maxSizeMB: 2,
            maxWidthOrHeight: 1920,
            useWebWorker: false,
        };
        const compressedFile = await imageCompression(file, options);
        formData.append("picture", compressedFile);
    return formData
}
  const createPost = async (file) => {
    setIsLoading(true)
    var  formData = new FormData();
    /*
    image.forEach((ima) => {
      console.log("picture", ima);
      formData.append("picture", ima);
    });*/
    formData= await processImages(formData,file);

    const savedPostResponse = await fetch(
      serverHost+ "posts/add",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
    setIsLoading(false)

    const savedPost = await savedPostResponse.json();
    //onSubmitProps.resetForm();
    if (savedPostResponse.ok) {
      setPopup({ type: "success", message: "Post creato con successo" });
      setImage([])
      refresh()
      
    } else {
      setPopup({
        type: "error",
        message: "Post NON creato, per favore riprova",
      });
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    await createPost(values, onSubmitProps);
  };

  return (
    <>
      <div className={styles.card}>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={storeSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit} className={styles.form}>
                 <Dropzone
                  acceptedFiles=".jpg,.jpeg,.png"
                  maxFiles={1}
                  onDrop={(acceptedFiles) =>{
                    setImage([])
                    acceptedFiles.forEach((file) => {
                      file.tempURL = URL.createObjectURL(file);
                      setImage((prevState) => [...prevState, file]);
                      createPost(file)
                    })

                  }
                  }
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} type="file"/>
                        <button className="mainButtonGreen">{!isLoading? "+ foto" : <Loader fullScreen={false} isText={true} />}</button>
                    </div>
                  )}
                </Dropzone>
                {/*image.length>0 &&
                          <div className={styles.oldImageContainer}>
                            <img
                              src={image[0].tempURL}
                              className={styles.oldImage}
                            />
                            <button
                              type="button"
                              onClick={() => setImage([])}
                              className={styles.removeOldImage}
                            >
                            <svg xmlns="http://www.w3.org/2000/svg" className={styles.removeSVG} viewBox="0 -960 960 960" ><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm80-160h80v-360h-80v360Zm160 0h80v-360h-80v360Z"/></svg>

                            </button>
                          </div>
                        
                     }
                {image.length>0 && <button type="submit" className={styles.sendButton}>
                {isLoading ? (
                        <Loader fullScreen={false} isText={true} />
                      ) : (
                        "conferma"
                      )}
                      </button>*/}

            </form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default NewPost;
