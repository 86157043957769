import styles from "./storePage.module.css";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import RatingStars from "components/RatingStars";
import Fuse from "fuse.js";
import SendMessage from "components/SendMessage";
import ImageDisplay from "components/ImageDisplay";
import { useSelector } from "react-redux";
import { PopupContext } from "App";
import Showcase from "components/Showcase";
import GenericCarousel from "components/GenericCarousel";
import ReviewsSection from "components/ReviewsSection";
import { serverHost } from "helpers/usefulData";
import {
  IconCategory,
  IconFileDescription,
  IconMail,
  IconMapPin,
  IconPhone,
  IconShare,
} from "@tabler/icons-react";
import CarouselWrapper from "components/CarouselWrapper";
import Tag from "components/Tag";
import Loader from "components/Loader";
const StorePage = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const [popup, setPopup] = useContext(PopupContext);

  var storeId = useParams();
  const [store, setStore] = useState(null);
  const [storeOwner, setStoreOwner] = useState(null);
  const [user, setUser] = useState(null);
  const [products, setProducts] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [reviews, setReviews] = useState([]);

  const options = {
    keys: ["name"],
    isCaseSensitive: false,
    shouldSort: true,
    threshold: 0.3,
  };

  const shareStore = () => {
    if (navigator.share) {
      navigator.clipboard.writeText(
        "Guarda questo negozio su Tralevie! " +
          store?.name +
          "tralevie.it/negozio/" +
          store?._id
      );
      navigator
        .share({
          title: store?.name,
          url: "tralevie.it/negozio/" + store?._id,
        })
        .then(() => console.log("Product shared successfully"))
        .catch((error) => console.error("Error sharing product:", error));
    } else {
      console.log("Web Share API not supported");
      // You can implement a fallback behavior here, like showing a copy link option.
    }
  };

  const [fullDescription, setFullDescription] = useState(false);

  useEffect(() => {
    getStore();
    getProducts();
    getStoreReviews();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    document.title = store?.name + " - Tralevie";
  }, store);
  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);

    if (!value) {
      setSearchResults(products);
      return;
    }
    const fuse = new Fuse(products, options);
    const results = fuse.search(value);
    console.log(results);

    setSearchResults(results.map((result) => result.item));
  };
  const getStoreReviews = async () => {
    var link = serverHost + "reviews/store/" + store?.id;
    const response = await fetch(link, {
      method: "GET",
    });
    const data = await response.json();

    setReviews(data);
    console.log("rev", data);
  };

  const getStore = async () => {
    const response = await fetch(serverHost + `stores/${storeId.id}`, {
      method: "GET",
    });

    if (!response.ok) {
      setPopup({
        type: "error",
        message: "Errore nel server, per cortesia riprovare",
      });
    } else {
      const data = await response.json();
      setStore(data.store);
      console.log("store", data.store);
      setUser(data.user);
      setStoreOwner(data.user);
      console.log("store OWNER", data.user);
    }
  };

  const getProducts = async () => {
    var link = serverHost + "products/store/" + storeId.id;
    const response = await fetch(link, {
      method: "GET",
    });

    if (!response.ok) {
      setPopup({
        type: "error",
        message: "Errore nel server, per cortesia riprovare",
      });
    } else {
      const data = await response.json();
      setProducts(data);
      setSearchResults(data);
      console.log("products", data);
    }
  };

  return (
    <>
      <Navbar />
      <ul className="breadCrumb">
        <li className="breadCrumbElement">
          <a href="/">Homepage</a>
        </li>{" "}
        {">"}
        <li className="breadCrumbElement">
          <a href={"/negozio/" + store?._id}>{store?.name}</a>
        </li>
      </ul>
      {!store ? (
        <Loader fullScreen={true}/>
      ) : (
        <>
      <div className={styles.container}>
        <div className={styles.storeSection}>

          <ImageDisplay collection={store?.picture} />

          <div className={styles.storeRow}>
            <RatingStars rating={store.rating ? store.rating : 0} />

            <div className={styles.titleRow}>
            <div className={styles.name}>{store.name}</div>

              <button className={`${styles.shareIcon} greenLink`} onClick={shareStore}>
                <IconShare ></IconShare>
              </button>
            </div>
            <CarouselWrapper title="">
          {store?.tags.map((term, index) => (
            <Tag
             key={index} name={term} />
          ))}
        </CarouselWrapper>

            <div></div>

            <div className={styles.dataContainer}>
              <div className={styles.textArea}>
                {store?.description && (
                  <div className={styles.descriptionContainer}>
                    <div className={styles.label}>
                      <IconFileDescription></IconFileDescription> Descrizione
                    </div>

                    <div className={styles.description}>
                      {store?.description}{" "}
                    </div>
                  </div>
                )}
                <div className={styles.info}>
                  {store?.address && (
                    <div>
                      <div className={styles.label}>
                        {" "}
                        <IconMapPin></IconMapPin> Indirizzo
                      </div>
                      <div className={styles.address}>{store?.address}</div>

                      {/*<div className={styles.province}>{store?.province}</div>*/}
                    </div>
                  )}
                  {store?.phone && (
                    <>
                      <div className={styles.label}>
                        {" "}
                        <IconPhone></IconPhone> Telefono
                      </div>
                      <div className={styles.address}>{store?.phone}</div>
                    </>
                  )}
                  {store?.email && (
                    <>
                      <div className={styles.label}>
                        {" "}
                        <IconMail></IconMail> Email
                      </div>
                      <div className={styles.address}>{store?.email}</div>
                    </>
                  )}
                </div>
              </div>
            </div>
        </div>
        </div>


        <SendMessage store={store} storeOwner={storeOwner} />
        <Showcase store={store} />

        <input
          type="text"
          placeholder="Cerca tra i prodotti..."
          value={searchTerm}
          onChange={handleSearch}
          className={styles.searchFilter}
        />
        <GenericCarousel
          collection={searchResults}
          type={"product"}
          title={"Alcuni prodotti di '" + store.name + "'"}
          storeID={store._id}
        />
      </div>
      <ReviewsSection storeID={store?._id} />
      </>)}
      <Footer />
    </>
  );
};

export default StorePage;
