import { useContext, useEffect, useState } from "react";
import { ErrorMessage, Field, Formik, setFieldValue  } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIsOwner } from "state";
import Dropzone from "react-dropzone";
import styles from "./createEditStorePageChat.module.css";
import { PopupContext } from "App";
import Loader from "components/Loader";
import FormBar from "components/FormBar";
import { provinces } from "helpers/usefulData";
import { serverHost } from "helpers/usefulData";
import imageCompression from "browser-image-compression";
import { IconCheck, IconChevronLeft, IconChevronRight, IconSparkles, IconTrash } from "@tabler/icons-react";

const storeSchema = yup.object().shape({
  name: yup.string().required("Inserisci il nome"),
  tags: yup.string(),
  description: yup.string(),
  address: yup.string(),
  province: yup.string(),
  email: yup.string().email("Email incorretta"),
  phone: yup.string(),
  IBAN:  yup.string().matches(/^(it|IT)[0-9]{2}[A-Za-z][0-9]{10}[0-9A-Za-z]{12}$/, 'IBAN invalido').nullable(),
});

const Form = ({ existingStore }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [popup, setPopup] = useContext(PopupContext);
  const token = useSelector((state) => state.token);
  const [images, setImages] = useState([]);
  const provinceOptions = ["-",
    "Agrigento",
    "Alessandria",
    "Ancona",
    "Aosta",
    "Arezzo",
    "Ascoli Piceno",
    "Asti",
    "Avellino",
    "Bari",
    "Barletta-Andria-Trani",
    "Belluno",
    "Benevento",
    "Bergamo",
    "Biella",
    "Bologna",
    "Bolzano",
    "Brescia",
    "Brindisi",
    "Cagliari",
    "Caltanissetta",
    "Campobasso",
    "Caserta",
    "Catania",
    "Catanzaro",
    "Chieti",
    "Como",
    "Cosenza",
    "Cremona",
    "Crotone",
    "Cuneo",
    "Enna",
    "Fermo",
    "Ferrara",
    "Firenze",
    "Foggia",
    "Forlì-Cesena",
    "Frosinone",
    "Genova",
    "Gorizia",
    "Grosseto",
    "Imperia",
    "Isernia",
    "L'aquila",
    "La spezia",
    "Latina",
    "Lecce",
    "Lecco",
    "Livorno",
    "Lodi",
    "Lucca",
    "Macerata",
    "Mantova",
    "Massa-Carrara",
    "Matera",
    "Messina",
    "Milano",
    "Modena",
    "Monza e Brianza",
    "Napoli",
    "Novara",
    "Nuoro",
    "Oristano",
    "Padova",
    "Palermo",
    "Parma",
    "Pavia",
    "Perugia",
    "Pesaro e Urbino",
    "Pescara",
    "Piacenza",
    "Pisa",
    "Pistoia",
    "Pordenone",
    "Potenza",
    "Prato",
    "Ragusa",
    "Ravenna",
    "Reggio Calabria",
    "Reggio Emilia",
    "Rieti",
    "Rimini",
    "Roma",
    "Rovigo",
    "Salerno",
    "Sassari",
    "Savona",
    "Siena",
    "Siracusa",
    "Sondrio",
    "Sud Sardegna",
    "Taranto",
    "Teramo",
    "Terni",
    "Torino",
    "Trapani",
    "Trento",
    "Treviso",
    "Trieste",
    "Udine",
    "Varese",
    "Venezia",
    "Verbano-Cusio-Ossola",
    "Vercelli",
    "Verona",
    "Vibo valentia",
    "Vicenza",
    "Viterbo",
    "Altro",
  ];
  const mainTags = [
    "alimentari",
    "abbigliamento",
    "elettronica",
    "casa",
    "libri",
    "gioielli",
    "attrezzature sportive",
    "giocattoli",
    "calzature",
    "musica",
    "farmaci",
    "arte",
    "hobby",
    "intrattenimento",
    "panificati",
    "motori",
    "cura personale",
  ];
  const [tags, setTags] = useState([]);
  const [writingTag, setWritingTag] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAI, setIsLoadingAI] = useState(false);
  const [step, setStep] = useState(0);
  const user = useSelector((state) => state.user);

  const [selectedValue, setSelectedValue] = useState(
    existingStore ? existingStore?.tags[0].toLowerCase() : mainTags[0].toLowerCase()
  ); // State to hold the selected value
  const [oldImages, setOldImages] = useState(
    existingStore ? existingStore?.picture : null
  );

  var initialValues = {
    name: "",
    tags: "",
    description: "",
    address: "",
    province: "",
    phone: "",
    email: "",
    IBAN: "",
  };

  useEffect(() => {
    if (existingStore != null) {
      initialValues.name = existingStore?.name;
      initialValues.tags = existingStore?.tags.join();
      initialValues.description = existingStore?.description;
      initialValues.address = existingStore?.address;
      initialValues.province = existingStore?.province;
      initialValues.phone = existingStore?.phone;
      initialValues.email = existingStore?.email;
      initialValues.IBAN = existingStore?.IBAN;

      const remainingTags = existingStore?.tags.slice(1);
      setTags(remainingTags);
    }
  }, []);
  async function processImages(formData) {
    for await (const image of images)  {
        const options = {
            maxSizeMB: 2,
            maxWidthOrHeight: 1920,
            useWebWorker: false,
        };
        const compressedFile = await imageCompression(image, options);
        formData.append("picture", compressedFile);

    }
    return formData
}

  const [phases, setPhases] = useState([
    "Ciao! Per creare un negozio ti basterà rispondere alle mie domande. Partiamo! Come si chiama il tuo negozio? ",
    "E ora dimmi, a che indirizzo è collocato?",
    "A che numero di telefono e email è possibile contattarti?",
    "è tempo di inserire le immagini. Ricorda che belle foto aiutano a vendere! Ti consigliamo di mettere il tuo logo, la tua vetrina, l'intero del tuo negozio e anche una foto del team!",
    "Ora descrivilo. Attento a mettere tutte le informazioni per aiutare i tuoi clienti.",
    "Adesso tocca ad una parte fondamentale! Dimmi che categorie di prodotti si possono trovare nel tuo negozio.",
    "Per inviarti i guadagni derivanti dalle tue vendite ho bisogno del codice IBAN al quale vuoi ricevere i soldi.",
    "Riassiumiamo! Controlla tutti i dati e dammi conferma. Grazie!",
  ]);

  const createStore = async (values, onSubmitProps) => {
    setIsLoading(true);
    try{    // this allows us to send form info with image
    if (existingStore) values._id = existingStore?._id;

    values.tags = (tags.includes(selectedValue) ? tags : [selectedValue, ...tags]).join(",");
    var formData = new FormData();
    if(values.email=="")values.email=user.email

    console.log("emailllll", values.email)
    for (let value in values) {
      console.log(values[value]);
      formData.append(value, values[value]);
    }
    //values?.picture?.name && formData.append("picture", values.picture.name);
    existingStore &&
      formData.append(
        "oldImages",
        typeof oldImages == "string" ? oldImages : oldImages.join()
      );

    formData= await processImages(formData)
    /*
    images.forEach((image) => {
      console.log("picture", image);
      formData.append("picture", image);
    });*/
    for (const value of formData.values()) {
      console.log(value);
    }

    const savedStoreResponse = await fetch(
      existingStore
        ? serverHost+ "stores/editStore"
        : serverHost+ "stores/createStore",
      {
        method: "POST",
        headers: {
          //"Content-Type": 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
    const savedStore = await savedStoreResponse.json();
    //onSubmitProps.resetForm();
    setIsLoading(false);

    if (savedStoreResponse.ok) {
      !existingStore && dispatch(setIsOwner({isOwner: true}));
      setPopup({
        type: "success",
        message: existingStore
          ? "Negozio modificato con successo"
          : "Negozio creato con successo",
      });
      navigate(existingStore ? "/ilMioNegozio" : "/GuidaPerNegozi");
    } else {
      setPopup({
        type: "error",
        message: existingStore
          ? "Negozio NON modificato, per favore riprova"
          : "Negozio NON creato, per favore riprova",
      });
    }}catch (e) {
      console.error(e);
    }
  };
  const ImproveWithAI = async (values, setFieldValue) => {
    try{    // this allows us to send form info with image
      setIsLoadingAI(true);

    const response = await fetch(
       serverHost+ "ai/improveText",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({text: values.description}),
        
      }
    );
    const savedImprovement = await response.json();
    console.log("improved", savedImprovement)
    setIsLoadingAI(false);

    if (response.ok) {
      setFieldValue('description', savedImprovement.text);

      setPopup({
        type: "success",
          message: "Testo migliorato grazie all'AI",
      });
    } else {
      setPopup({
        type: "error",
        message: "Negozio NON modificato, per favore riprova"
      });
    }}catch (e) {
      console.error(e);
    }
  };
  const removeOldImage = (index) => {
    var tempOldImages = oldImages.filter((_, i) => i !== index);
    console.log(tempOldImages);
    setOldImages(tempOldImages);
  };
  const toggleTag = (tag) => {
    tag=tag.toLowerCase()
    if (tag.toLowerCase() == selectedValue.toLowerCase()) return
    const index = tags.indexOf(tag);
    if (index === -1) {
      // Tag is not present, so add it
      setTags([...tags, tag]);
    } else {
      // Tag is already present, so remove it
      const updatedTags = [...tags];
      updatedTags.splice(index, 1);
      setTags(updatedTags);
    }
    console.log(tags)
  };
  const removeImage = (indexToRemove) => {
    const updatedImages = images.filter((el, index) => index !== indexToRemove);
    setImages(updatedImages);
  };
  const handleFormSubmit = async (values, onSubmitProps) => {
    console.log("formittato");
    await createStore(values, onSubmitProps);
  };

  return (
    <>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={storeSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isValid,
          dirty,
          setFieldValue,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit} className={styles.form}>
            <div>
              <div className="mainTitle">
                {existingStore
                  ? "Modifica il tuo negozio"
                  : "Crea il tuo Negozio in 5 minuti!"}
              </div>

              <FormBar phases={phases} step={step} showPhases={false} />

              <div className={styles.chatRow}>
                <div>
                  <img className={styles.image} src="/assets/client support.png" alt="" />
                  <div className={styles.Tralevie}>Tralevie</div>
                </div>
                <div>
                  <div className={styles.message}>{phases[step]}</div>
                </div>
              </div>
              <div className={styles.inputContainer}>
                {step == 0 && (
                  <>
                    <div className={styles.label}>Nome del negozio</div>

                    <Field
                      className={
                        touched.name && errors.name
                          ? `${styles.input} ${styles.error}`
                          : `${styles.input}`
                      }
                      type="text"
                      label="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      placeholder="Come si chiama il negozio?"
                      name="name"
                      error={Boolean(touched.name) && Boolean(errors.name)}
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className={styles.errorMessage}
                    />
                  </>
                )}
                {step == 1 && (
                  <>
                    <div className={styles.label}>Indirizzo</div>
                    <Field
                      className={
                        touched.address && errors.address
                          ? `${styles.input} ${styles.error}`
                          : `${styles.input}`
                      }
                      type="text"
                      label="address"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.address}
                      name="address"
                      placeholder="Indirizzo completo"
                      error={
                        Boolean(touched.address) && Boolean(errors.address)
                      }
                    />
                    <ErrorMessage
                      name="address"
                      component="div"
                      className={styles.errorMessage}
                    />
                    <div className={styles.label}>Provincia</div>

                    <Field
                      className={
                        touched.province && errors.province
                          ? `${styles.input} ${styles.error}`
                          : `${styles.input}`
                      }
                      as="select"
                      label="province"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.province}
                      name="province"
                      placeholder="Provincia"
                      error={
                        Boolean(touched.province) && Boolean(errors.province)
                      }
                    >
                      {provinceOptions?.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="province"
                      component="div"
                      className={styles.errorMessage}
                    />
                  </>
                )}
                {step == 2 && (
                  <>
                    <div className={styles.label}>Telefono</div>

                    <Field
                      className={
                        touched.phone && errors.phone
                          ? `${styles.input} ${styles.error}`
                          : styles.input
                      }
                      type="text"
                      label="phone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.phone}
                      name="phone"
                      placeholder="Telefono (opzionale)"
                      error={Boolean(touched.phone) && Boolean(errors.phone)}
                    />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className={styles.errorMessage}
                    />
                    <div className={styles.label}>Email del negozio</div>

                    <Field
                      className={
                        touched.email && errors.email
                          ? `${styles.input} ${styles.error}`
                          : `${styles.input}`
                      }
                      type="email"
                      label="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      name="email"
                      placeholder="Email Negozio (opzionale)"
                      error={Boolean(touched.email) && Boolean(errors.email)}
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className={styles.errorMessage}
                    />
                  </>
                )}
                {step == 3 && (
                  <>
                    <div className={styles.totOldImagesContainer}>
                      {oldImages?.map((el, index) => {
                        return (
                          <div className={styles.oldImageContainer}>
                            <img
                              key={el}
                              src={el}
                              className={styles.oldImage}
                            />
                            <button
                              type="button"
                              onClick={() => removeOldImage(index)}
                              className={styles.removeOldImage}
                            >
                              <IconTrash></IconTrash>

                            </button>
                          </div>
                        );
                      })}
                      {images?.map((el, index) => {
                        return (
                          <div className={styles.oldImageContainer}>
                            <img
                              key={el}
                              src={el.tempURL}
                              className={styles.oldImage}
                            />
                            <button
                              type="button"
                              onClick={() => removeImage(index)}
                              className={styles.removeOldImage}
                            >
                              <IconTrash></IconTrash>
                            </button>
                          </div>
                        );
                      })}

                      <Dropzone
                        acceptedFiles=".jpg,.jpeg,.png"
                        multiple
                        onDrop={(acceptedFiles) => {
                          //setImages([]);
                          acceptedFiles.forEach((file) => {
                            file.tempURL = URL.createObjectURL(file);
                            setImages((prevState) => [...prevState, file]);
                          });
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} type="file" />

                            <div className={`${styles.newImageContainer}`}>
                              <div className={styles.dropFile}>
                                +<br></br>foto
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </>
                )}
                {step == 4 && (
                  <>
                    <div className={styles.label}>Descrizione</div>

                    <Field
                      className={styles.inputTextArea}
                      as="textarea"
                      label="description"
                      placeholder="Descrizione (opzionale)"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      name="description"
                      error={
                        Boolean(touched.description) &&
                        Boolean(errors.description)
                      }
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className={styles.errorMessage}
                    />
                    <button className={`mainButtonGreen ${styles.improveButton}`}
                    type="button"
                    onClick={() => ImproveWithAI(values, setFieldValue)} disabled={values.description === ""}>
                      {isLoadingAI ? (
                          <Loader fullScreen={false} isText={true} />
                        ) : (
                          <><IconSparkles />  migliora con AI</>
                        )}
                    </button>

                    
                  </>
                )}
                {step == 5 && (
                  <>
                    <div className={styles.selectTagContainer}>
                      <div className={styles.label}>Categoria di merci principale:</div>
                      <select
                        className={styles.selectTag}
                        value={selectedValue}
                        onChange={(event) =>{
                          setSelectedValue(event.target.value)}
                        }
                      >

                        {mainTags.map((tag, index) => (
                          <option key={index} value={tag}>
                            {tag}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className={styles.label}>Altre categorie:</div>

                    <div className={styles.tagButtonContainer}>
                    {mainTags.map((tag, index) => (
                          <div key={index} 
                          className={`${(tags.includes(tag) || selectedValue==tag) ? styles.selectedTagButton : styles.selectTagButton}`}
                          onClick={()=>toggleTag(tag)}
                          >
                           {(tags.includes(tag) || selectedValue==tag) && <IconCheck></IconCheck>} {tag}
                          </div>
                        ))}
                    </div>
{/*
                    <form className={styles.tagForm} onSubmit={addTag}>
                      {tags?.map((tag, index) => {
                        return (
                          <div className="tag" key={tag}>
                            <div className={styles.tagText}>{tag}</div>
                            <div
                              className={styles.removeTag}
                              onClick={() => {
                                removeTag(index);
                              }}
                            >
                              <img src="/assets/icons/closeGold.svg" alt="" />
                            </div>
                          </div>
                        );
                      })}
                      <div className={styles.inputAndButton}>
                        <input
                          type="text"
                          className={styles.tagInput}
                          placeholder="Aggiungi altre categorie"
                          value={writingTag}
                          onChange={(event) => {
                            setWritingTag(event.target.value);
                          }}
                        />
                        <button
                          className="mainButtonGreen"
                          onClick={addTag}
                          disabled={!writingTag}
                        >
                          +
                        </button>
                      </div>
                    </form>*/}
                  </>
                )}
                  {step == 6 && (
                  <>
                    <div className={styles.label}>IBAN</div>

                    <Field
                      className={
                        touched.name && errors.name
                          ? `${styles.input} ${styles.error}`
                          : `${styles.input}`
                      }
                      type="text"
                      label="IBAN"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.IBAN}
                      placeholder="Codice IBAN (opzionale)"
                      name="IBAN"
                      error={Boolean(touched.IBAN) && Boolean(errors.IBAN)}
                    />
                    <ErrorMessage
                      name="IBAN"
                      component="div"
                      className={styles.errorMessage}
                    />
                  </>
                )}
                {step == 7 && (
                  <>
                    <div>
                      <div
                        className={
                          touched.name && errors.name
                            ? `${styles.recapRecord} ${styles.recapRecordError}`
                            : `${styles.recapRecord}`
                        }
                      >
                        <span className={styles.label}>Nome:</span>
                        <span className={styles.labelContent}>
                          {values.name ? values.name : "-"}
                        </span>
                      </div>
                      <div
                        className={
                          touched.name && errors.name
                            ? `${styles.recapRecord} ${styles.recapRecordError}`
                            : `${styles.recapRecord}`
                        }
                      >
                        <span className={styles.label}>Immagini:</span>
                        <span className={styles.labelContent}>
                          {(images?.length ?? 0) + (oldImages?.length ?? 0)} File
                        </span>
                      </div>
                      <div
                        className={
                          touched.description && errors.description
                            ? `${styles.recapRecord} ${styles.recapRecordError}`
                            : `${styles.recapRecord}`
                        }
                      >
                        <span className={styles.label}>Descrizione:</span>
                        <span className={styles.labelContent}>
                          {values.description ? values.description : "-"}
                        </span>
                      </div>
                      <div
                        className={
                          touched.phone && errors.phone
                            ? `${styles.recapRecord} ${styles.recapRecordError}`
                            : `${styles.recapRecord}`
                        }
                      >
                        <span className={styles.label}>Telefono:</span>
                        <span className={styles.labelContent}>
                          {values.phone ? values.phone : "-"}
                        </span>
                      </div>
                      <div
                        className={
                          touched.shippingCost && errors.shippingCost
                            ? `${styles.recapRecord} ${styles.recapRecordError}`
                            : `${styles.recapRecord}`
                        }
                      >
                        <span className={styles.label}>
                          Email:
                        </span>
                        <span className={styles.labelContent}>
                          {values.email ? values.email
                            : "-"}
                        </span>
                      </div>
                      <div
                        className={
                          touched.options && errors.options
                            ? `${styles.recapRecord} ${styles.recapRecordError}`
                            : `${styles.recapRecord}`
                        }
                      >
                        <span className={styles.label}>Indirizzo:</span>
                        <span className={styles.labelContent}>
                          {values.address ? values.address : "-"}, {values.province ? values.province : ""}
                        </span>
                      </div>
                      <div
                        className={
                          touched.inventory && errors.inventory
                            ? `${styles.recapRecord} ${styles.recapRecordError}`
                            : `${styles.recapRecord}`
                        }
                      >
                        <span className={styles.label}>Categorie:</span>
                        <span className={styles.labelContent}>
                          {[selectedValue, ...tags] ? [selectedValue, ...tags].join(', ') : "-"}
                        </span>
                      </div>
                      <div
                        className={
                          touched.inventory && errors.inventory
                            ? `${styles.recapRecord} ${styles.recapRecordError}`
                            : `${styles.recapRecord}`
                        }
                      >
                        <span className={styles.label}>IBAN:</span>
                        <span className={styles.labelContent}>
                          {values.IBAN ? values.IBAN : "-"}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {/* BUTTONS */}
              <div>
                <div className={styles.buttonContainer}>
                  <button
                    className={styles.movementButton}
                    style={{ visibility: step == 0 ? "hidden" : "visible" }}
                    type="button"
                    onClick={() => setStep(step - 1)}
                  >
                   <IconChevronLeft></IconChevronLeft> Indietro
                  </button>

                  {step < phases.length - 1 && (
                    <button
                      className={styles.movementButton}
                      type="button"
                      onClick={() => setStep(step + 1)}
                    >
                      Avanti <IconChevronRight></IconChevronRight>
                    </button>
                  )}

                  {step > phases.length - 2 && (
                    <>
                      <button className="mainButtonGreen" type="submit" disabled={!isValid}>
                        {isLoading ? (
                          <Loader fullScreen={false} isText={true} />
                        ) : existingStore ? (
                          "Modifica"
                        ) : (
                          "Crea"
                        )}
                      </button>
                    </>
                  )}
                </div>
                {!isValid && step > phases.length - 2 && (
                  <div className={styles.errorMessageFinal}>
                    Errore: controlla di avere inserito correttamente tutti i
                    dati
                  </div>
                )}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default Form;
