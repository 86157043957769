import { useState } from "react";
import FullScreenView from "./FullScreenView";
import styles from "./Post.module.css";
import { useNavigate } from "react-router-dom";

const Post = ({ post, store, noName, collection=[], index=0 }) => {
  const navigate = useNavigate();
  const [fullScreen, setFullscreen] = useState(false);

  if (!post) return null;
  return (
    <>
      {fullScreen && (
        <FullScreenView
          activeImage={collection.length>0 ? index: post.picture}
          closeFunction={setFullscreen}
          collection={collection.map(post => post.picture)}
        />
      )}
      <div className={styles.card}>
        <div className={styles.gradient}>
                  <img
          className={styles.image}
          src={post.picture.replace("upload/", "upload/q_50/")}
          alt=""
          onClick={() => {
            setFullscreen(true);
          }}
        />
        </div>

        {!noName && (
          <div
            onClick={() => navigate("/negozio/" + post.storeID)}
            className={styles.name}
          >
            {post?.storeName}
          </div>
        )}
      </div>
    </>
  );
};

export default Post;
