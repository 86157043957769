import styles from "./GenericCarousel.module.css";
import ProductCard from "./ProductCard";
import StoreCard from "./StoreCard";
import Post from "./Post";
import CarouselWrapper from "./CarouselWrapper";

const GenericCarousel = ({ collection, type, title }) => {
  return (

        <CarouselWrapper title={title}>
          {collection!=null && collection?.map((el, index) =>{
            if(type == "product"){ return  <ProductCard key={el._id} product={el} />}
            if(type == "store"){ return <StoreCard key={el._id} store={el} />}
            if(type == "post"){ return <Post collection={collection} index={index} key={index} /*store={store}*/ post={el} noName={false} />
          }


})}
          {/* type == "product" && <div className={styles.noProduct}>
          <svg xmlns="http://www.w3.org/2000/svg" className={styles.showButtonIcon} viewBox="0 -960 960 960" ><path d="M303.93-508Q326-508 341-522.93t15-37Q356-582 341.07-597t-37-15Q282-612 267-597.07t-15 37Q252-538 266.93-523t37 15Zm176 0Q502-508 517-522.93t15-37Q532-582 517.07-597t-37-15Q458-612 443-597.07t-15 37Q428-538 442.93-523t37 15Zm176 0Q678-508 693-522.93t15-37Q708-582 693.07-597t-37-15Q634-612 619-597.07t-15 37Q604-538 618.93-523t37 15ZM46-46v-742q0-53 36.5-89.5T172-914h616q53 0 89.5 36.5T914-788v456q0 53-36.5 89.5T788-206H206L46-46Zm126-286h616v-456H172v456Zm0 0v-456 456Z"/></svg>
 Contatta  i negozianti per scoprire l'intera gamma dei loro prodotti
</div>*/}
          </CarouselWrapper>

  );
};

export default GenericCarousel;
