import styles from "./NewReview.module.css";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { serverHost } from "helpers/usefulData";
import { IconX } from "@tabler/icons-react";
import RatingStars from "./RatingStars";
import { PopupContext } from "App";

const NewReview = ({ productID= null, storeID= null , addNewReview}) => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const [popup, setPopup] = useContext(PopupContext);

  //var storeId = useParams();
  const [serviceRating, setServiceRating] = useState(0);
  const [productRating, setProductRating] = useState(0);
  const [close, setClose] = useState(true);
  const [existingReview, setExistingReview] = useState(false);
  const [content, setContent] = useState("");
  const [sent, setSent] = useState(false);
  const [display, setDisplay] = useState(true);


  useEffect(() => {
    productID && canIReview()
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const canIReview = async () => {

    var link = serverHost+ `reviews/check/${productID}`;
    const response = await fetch(link, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    if(data.permission == false ) setDisplay(false)
  };

  const addProductReview = async (values, onSubmitProps) => {
    const newReview = {
      productID: productID? productID : null,
      storeID: storeID? storeID : null,
      serviceRating: serviceRating,
      productRating: productRating,
      content: content,
    };
    const savedProductReviewResponse = await fetch(
      serverHost+ "reviews/add",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newReview),
      }
    );

    const savedProductReview = await savedProductReviewResponse.json();
    if (savedProductReviewResponse.ok){
      addNewReview(savedProductReview)
    setSent(true);
    setPopup({ type: "success", message: "Recensione aggiunta con successo" });

    }else {
      setPopup({ type: "error", message: "operazione non riuscita" });
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    // Create a new review object

    addProductReview();

    // Pass the new review to the parent component

    // Reset the form
    setProductRating(0);
    setServiceRating(0);
    setContent("");
  };
  return (
    <>
      {!close && token && display && (
        <div className={styles.container}>
          <div className={styles.firstRow}>
          <div className={styles.title}>Aggiungi una Recensione</div>

          <button
            className={styles.closeButton}
            onClick={() => {
              setClose(true);
            }}
          >
            {" "}
            <IconX></IconX>
          </button>
          </div>

          {sent ? (
            <div className={styles.sent}>
              Congratulazioni la tua recensione è stata inserita
            </div>
          ) : (
            <>
              <form className={styles.form} onSubmit={handleSubmit}>
                <div className={styles.ratingsContainer}>
                  {productID && 
                  <div className={styles.ratingContainer}>
                    <div className={styles.ratingLable}>Prodotto:</div>
                    <RatingStars setRating={setProductRating} inline={true}/>
                  </div>}
                  <div className={styles.ratingContainer}>
                    <div className={styles.ratingLable}>Servizio:</div>
                    <RatingStars setRating={setServiceRating} inline={true}/>
                  </div>
                </div>
                <div className={styles.contentRow}>
                <textarea
                  className={styles.content}
                  value={content}
                  onChange={(e) => {
                    setContent(e.target.value);
                  }}
                  placeholder="Scrivi la tua recensione..."
                />
                <button type="submit" className="mainButtonGreen" disabled={content=="" && productRating==0 && serviceRating==0}>
                  Invia Recensione
                </button>
                </div>

              </form>
            </>
          )}
        </div>
      )}
      {close && token && display && !sent && (
        <button className={` ${styles.newRev} mainButtonGreen`} onClick={() => setClose(!close)}>
          + Recensione
        </button>
      )}
    </>
  );
};

export default NewReview;
