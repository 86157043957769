import styles from "./ReviewsSection.module.css";
import { serverHost } from "helpers/usefulData";
import { useEffect, useState } from "react";
import NewReview from "./NewReview";
import ReviewCard from "./ReviewCard";
import { IconStar } from "@tabler/icons-react";
import ReviewsStats from "./ReviewsStats";

const ReviewsSection = ({ productID=null, storeID=null, userID=null, bento=false }) => {
  //var storeId = useParams();
  const [reviews, setReviews] = useState(null);
  useEffect(() => {
    
    getReviews();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const addNewReview = (rev) => {

    setReviews([rev, ...reviews]);
  };

  const getReviews = async () => {
    var link = ""
    productID && (link = serverHost+ "reviews/product/" + productID);
    storeID && !productID && (link = serverHost+ "reviews/store/" + storeID);
    userID && (link = serverHost+ "reviews/user/all/" + userID);
    const response = await fetch(link, {
      method: "GET",
    });
    const data = await response.json();

    setReviews(data);
    console.log(data)
  };

  if (!reviews) return null;
  return (
    <div className={styles.card}>
    { !bento && <div className="secondaryTitle"><IconStar></IconStar>Recensioni ({reviews.length}) </div>}

    { bento && 
            <div className={styles.firstRow}>
            <div className="secondaryTitle"><IconStar></IconStar>Le mie Recensioni ({reviews.length}) </div>
            </div>
    }
     { !bento && <NewReview productID={productID} storeID={storeID} className={styles.newButton} addNewReview={addNewReview}/>}
<div className={styles.row}>


{reviews!=null && reviews.length>0 && (storeID || productID) && <div className={styles.statsContainer}><ReviewsStats reviews={reviews} type={productID? "product":"store"}/></div>}

<div className={styles.reviewsContainer}>
{reviews?.map((el) => (
        <ReviewCard review={el} />
      ))}{" "}
      {reviews.length==0 && <div className={styles.noReviews}>Nessuna Recensione</div>
      }
</div>
</div>

    </div>
  );
};

export default ReviewsSection;
