import { useEffect, useState } from "react";
import styles from "./StoreCarousel.module.css";
import GenericCarousel from "./GenericCarousel";
import { serverHost } from "helpers/usefulData";
const StoreCarousel = () => {
  const [stores, setStores] = useState(null);
  useEffect(() => {
    getStores();
  }, []);

  const getStores = async () => {
    const response = await fetch(serverHost+ `stores`, {
      method: "GET",
    });
    var data = await response.json();
    data.sort(() => Math.random() - 0.5);

    setStores(data);
  };
  return (<>
    <GenericCarousel type={"store"} collection={stores} title={"I nostri negozi"}/>
    <a className={`greenLink ${styles.link}`} href="/negozi">Scopri tutti i Negozi</a>

    </>);
};

export default StoreCarousel;
