import { useEffect, useState } from "react";
import styles from "./PriceTag.module.css";
const PriceTag = ({ price, big=false }) => {
  const [integer, setInteger] = useState("");
  const [decimal, setDecimal] = useState("");
  
  useEffect(() => {
    if (price === 0) {
      setInteger("0");
      setDecimal(".00");
    } else {
      // Convert price to a fixed number with two decimal places
      const formattedPrice = parseFloat(price).toFixed(2);
  
      // Split the formatted price into integer and decimal parts
      const [integerPart, decimalPart] = formattedPrice.split('.');
  
      // Set the integer part
      setInteger(integerPart);
  
      // Set the decimal part, ensuring it has two digits
      setDecimal(`.${decimalPart.padEnd(2, '0').slice(0, 2)}`);
    }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className={styles.container}>

        {price!=undefined && <div className={big ? styles.priceTagBig : styles.priceTag}>
        {integer}
          <span className={styles.digits}>{decimal} </span>€</div>}
    </div>
  );
};

export default PriceTag;
