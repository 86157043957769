import styles from "./ReviewsStats.module.css";
import RatingStars from "./RatingStars";


const ReviewsStats = ({ reviews=null, type="product"}) => {
  if (reviews==null) return

  const totalReviews =reviews.filter(review => (type === "product" ? review.productRating : review.serviceRating) !== 0).length;;
  const averageRating =
  totalReviews > 0
    ? reviews.reduce((acc, review) => acc + (type === "product" ? review.productRating : review.serviceRating), 0) / totalReviews
    : 0;
 
      const ratingFrequency = Array(5).fill(0);
      const ratingPercentage = Array(5).fill(0);
      reviews.forEach(review => {
        ratingFrequency[(type=="product" ? review.productRating : review.serviceRating) - 1]++;
      });
      ratingFrequency.forEach((frequency, index) => {
        ratingPercentage[index] = (frequency / totalReviews) * 100;
      });

      const reversedFrequency = [...ratingFrequency].reverse();
      const reversedPercentage = [...ratingPercentage].reverse();

  return (
    <div className={styles.container}>
      <div className={styles.title}>Recensioni del {(type=="product" ? "prodotto" : "negozio")}</div>
      <RatingStars rating={averageRating.toFixed(1)}/>
      <div className={styles.num}>n° recensioni: {totalReviews}</div>
      <div className={styles.chart}>
        {reversedFrequency.map((frequency, index) => (
          <div key={index + 1} className={styles.barContainer}>
            <div className={styles.star}>{5-index}</div>
  <div className={styles.bar}>
              <div className={styles.fill} style={{ width: `${reversedPercentage[index]}%` }}></div>
            </div>
              <div className={styles.label}>{reversedPercentage[index]?.toFixed(0)}% ({frequency})</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReviewsStats;