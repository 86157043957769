import styles from "./Message.module.css";
import {  useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FullScreenView from "./FullScreenView";
import ProductCard from "./ProductCard";
import { serverHost } from "helpers/usefulData";

const Message = ({ message, own }) => {
  var date = new Date(message.createdAt);  // dateStr you get from mongodb
var d = date.getDate();
var m = date.getMonth()+1
var h = date.getHours()
var min = date.getMinutes()
const token = useSelector((state) => state.token);
const [fullScreen, setFullScreen] = useState(false);
const [product, setProduct] = useState(null);

useEffect(() => {
  if(message.text.indexOf("prodotto/")>-1){
    message.type="product"

    getProduct();
}
}, []); // eslint-disable-line react-hooks/exhaustive-deps

const getProduct = async () => {
  var text= message.text
  var productID= text.substring(text.indexOf("prodotto/") + "prodotto/".length)
  const response = await fetch(
    serverHost+ `products/${productID}`,
    {
      method: "GET",
    }
  );
  const data = await response.json();
  setProduct(data.product);
};

  return  (<>
    {fullScreen&& <FullScreenView activeImage={message.text} closeFunction={setFullScreen}/>}
    <div className={own ? styles.myMessage: styles.message}>
      <div className={styles.messageTop}>
        {message.type=="image" &&
                  <img
                  src={message.text}
                  /*onClick={()=>setFullScreen(true)}*/
                  className={styles.messageImage}
                  alt={message.text}
                />
  }
    {product &&
    <div className={styles.messageProduct}>


    <ProductCard  product={product} /></div>
}
  {message.type=="" && !product &&
        <p className={styles.messageText}>{message.text}</p>
        }

      </div>
      <div className={styles.messageBottom}>{d + "/"+ m + " "+ h + ":" + min}</div>
    </div>
 </> );
};

export default Message;