import styles from "./RedeemStore.module.css";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { serverHost } from "helpers/usefulData";
import { useDispatch, useSelector } from "react-redux";
import { PopupContext } from "App";
import { setIsOwner } from "state";


const RedeemStore = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const [popup, setPopup] = useContext(PopupContext);
  const handleChange = (e) => {
    setCode(e.target.value);
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate the code (e.g., length, characters, etc.)
    if (code.length !== 5 || !/^[A-Za-z0-9]+$/.test(code)) {
      setError('Il codice deve essere di 5 caratteri alfanumerici');
      return;
    }
    // Handle form submission
    // Example: You can submit the code to your backend for processing
    
    try {
      const response = await fetch(
        serverHost+ "stores/redeemStore/"+code,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );      
      
      if (response.ok) {
        dispatch( setIsOwner({isOwner: true})
      );
        setPopup({
          type: "success",
          message:"Negozio collegato con successo"
        });
        navigate("/ilMioNegozio");
      } else {
        setPopup({
          type: "error",
          message:"Errore, negozio NON collegato"
        });
      }
    } catch (error) {
      console.error('Error fetching store by code:', error);
      setError(error.message);
    }
    // Clear the input after submission
    setCode('');
  };

  return (
    <form className={styles.redeemCodeSection} onSubmit={handleSubmit}>
    <label htmlFor="storeCode">Hai ricevuto un codice Negozio? Inseriscilo qui:</label>
    <input
      type="text"
      id="storeCode"
      placeholder="- - - - -"
      value={code}
      onChange={handleChange}
    />
    {error && <div className={styles.error}>{error}</div>}
    <button type="submit" className="mainButtonGreen">Invia</button>
  </form>
  );
};

export default RedeemStore;
