import styles from "./Conversation.module.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "./Loader";
import { serverHost } from "helpers/usefulData";
import { IconPhone } from "@tabler/icons-react";
const Conversation = (props) => {
  const [friend, setFriend] = useState(null);
  const [store, setStore] = useState(null);

  const [firstLetter, setFirstLetter] = useState(null);
  const user = useSelector((state) => state.user);
  var friendId = "";
  useEffect(() => {
    friendId = props.conversation.members.find((m) => m !== user._id);
    getFriend();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getFriend = async () => {
    const response = await fetch(serverHost+ `users/${friendId}`, {
      method: "GET",
    });

    const data = await response.json();
    setFriend(data.user);
    setStore(data.store);
    setFirstLetter(data.user?.firstName[0]);
  };

  return (
    <>
      {!friend ? (
        <Loader />
      ) : (
        <div
          className={`${styles.conversation} ${props.isActive && styles.conversationActive}`}
        >

<div className={styles.left}>
{props.hasNotification && (
            <div className={styles.notification}>!</div>
          )}
            <img
              src={friend?.picturePath ? friend?.picturePath.replace("upload/", "upload/q_50/"): "/assets/user.png"}
              alt=""
              className={styles.conversationIcon}
            />
          <div className={styles.conversationName}>
          <div>{friend?.firstName + " " + friend?.lastName}</div>

            {store && <div>{store?.name}</div>}
          </div>
          </div>
          {store && <a className={styles.call} href={`tel:${store?.phone}`}       onClick={(e) => e.stopPropagation()}
><IconPhone className={styles.callIcon}></IconPhone></a>}
        </div>
      )}
    </>
  );
};

export default Conversation;
