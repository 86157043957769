import styles from "./OrderReturnPage.module.css";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { serverHost } from "helpers/usefulData";
import ProductBadge from "components/ProductBadge";
import Badge from "components/Badge";

const OrderReturnPage = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [order, setOrder] = useState([]);
  const [store, setStore] = useState([]);
  const [products, setProducts] = useState([]);
  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [returnReason, setReturnReason] = useState(''); // State for capturing return reasons
  const [sent, setSent] = useState(false); // State for capturing return reasons


  useEffect(() => {
    document.title = "Reso Ordine - Tralevie ";
    getOrder()
  }, []);

  useEffect(() => {
    if (order && order.products && order.products.length > 0) {
      getProducts();
    }  }, [order]);

  const getProducts = async () => {
    if (order==[] || !order?.products?.length) return
    const tempProducts = [];

    for await (var productTemp of order?.products) {
      var link = serverHost+ "products/" + productTemp.productID;
      const response = await fetch(link, {
        method: "GET",
      });

      const data = await response.json();
      productTemp._id = data?.product?._id;
      productTemp.name = data?.product?.name;
      productTemp.picture = data?.product?.picture;
      productTemp.returnQuantity = 0;
      tempProducts.push(productTemp);
      store!= [] && setStore(data.store);
      //tempProd = [...tempProd, data];
    }
    setProducts(tempProducts);

  };

  const getOrder = async () => {
    const response = await fetch(serverHost+ `orders/singleOrder/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("order ce lo abbiamo", order)
    const data = await response.json();
    setOrder(data);
  };

  const handleReturnReasonChange = (event) => {
    setReturnReason(event.target.value);
  };

  const updateReturnQuantity = (productId, value) => {
    setProducts((prevProducts) =>
      prevProducts.map((prod) =>
        prod.productID === productId ? { ...prod, returnQuantity: value } : prod
      )
    );
  };

 const sendReturn = async ()=>{
  let reclam={
    orderID: order._id,
    reason: returnReason,
    products: products.filter((prod) => prod.returnQuantity > 0)
  };
  console.log("returnato: ", reclam)
  const savedReturnResponse = await fetch(serverHost + "orders/returnOrder", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(reclam),
  });
  const savedStore = await savedReturnResponse.json();
  console.log(savedStore);
  setSent(true)
 }

  return order && (
    <>
      <Navbar />
      <div className={styles.container}>
        <div className={styles.paragarph}>
          Ci dispiace che tu voglia fare un reso. Per cortesia dicci la motivazione.
        </div>
        <div>
        <textarea
            value={returnReason}
            className={styles.textArea}
            onChange={handleReturnReasonChange}
            placeholder="Motivazione"
            rows={4}
            cols={50}
          />        </div>
      <div className={styles.productContainer}>
      <div className={styles.orderRecap}>
              <Badge
                name={store?.name}
                path={store?._id}
                image={store.picture? store?.picture[0].replace("upload/", "upload/q_50/") : ""}
              />
      <div>ID: #{order?._id}</div>
                    <div>Creato il: {new Date(order.createdAt) .toLocaleDateString("en-GB") }</div>
                    <div>Totale: {order?.totalPrice} €</div>
        </div>


                <div className={styles.productList}>
                <div className={styles.paragarph}>
          Seleziona i prodotti da rendere:
        </div>
                  {products?.map((prod) => (
                    <div key={prod._id} className={styles.productRow}>
                                    <div className={styles.left}>
                                      <input
                                    className={styles.checkbox}
                  type="checkbox"
                  checked={prod.returnQuantity>0}
                  onChange={() =>{  updateReturnQuantity(prod.productID, prod.returnQuantity>0 ? 0: 1)}}
                />
                    <ProductBadge  product={prod} /></div>
                    <input type="number" min={0} max={prod.quantity} value={prod.returnQuantity} 
                                   onChange={(event) => {
                                    const value = parseInt(event.target.value);
                                    if (!isNaN(value)) {
                                      updateReturnQuantity(prod.productID, value);

                                    }}}
                                    className={styles.quantityInput}/>

                    </div>

                  ))}
                </div>
              </div> 
              <div className={styles.actions}>
                <button className="mainButtonGreen" disabled={products ? products.reduce((total, product) => total + product.returnQuantity, 0)==0: true} onClick={()=>sendReturn()}>Invia richiesta di reso</button>
              </div>
      </div>

      <Footer />
    </>
  );
};

export default OrderReturnPage;
