import styles from "./CartProduct.module.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { PopupContext } from "App";
import Loader from "./Loader";
import PriceTag from "./PriceTag";
import { serverHost } from "helpers/usefulData";
import { IconMinus, IconPlus } from "@tabler/icons-react";
import ConfirmationModal from "./ConfirmationModal";
const CartProduct = (props) => {
  const productId = props.product.productID;
  const isSmall = props.small;
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const [product, setProduct] = useState(null);
  const [store, setStore] = useState(null);
  const [popup, setPopup] = useContext(PopupContext);
  const [doNotExists, setDoNotExists] = useState(100);
  const [quantity, setQuantity] = useState(props.product?.quantity);
  const [option, setOption] = useState(props.product?.option);
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [noteValue, setNoteValue] = useState(props.product?.note);
  const [showModal, setShowModal] = useState(false);

  // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    console.log(props.product?.quantity);
    setIsLoading(true);
    getProduct();
  }, []);
  useEffect(() => {
  }, [props]);
  useEffect(() => {
    setQuantity(props.product?.quantity);
    console.log(props.product?.quantity);
  }, [product]);
  const getProduct = async () => {
    const response = await fetch(
      serverHost + "products/" + props.product.productID,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setIsLoading(false);
    if (response.status.toString()[0] == "4") {
      setDoNotExists("doNotExist");
      props.removeProduct(props.product);
    } else {
      const data = await response.json();
      setProduct(data.product);
      setStore(data.store);
    }
    setShowModal(false)
  };
  const handleNoteChange = (event) => {
    setNoteValue(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setProduct({ ...product, note: noteValue });

    if (token != null) {
      const savedStoreResponse = await fetch(serverHost + "carts/editProduct", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ...props.product, note: noteValue }),
      });
      if (!savedStoreResponse.ok) {
        setPopup({ type: "error", message: "operazione non riuscita" });
      } else {
        setPopup({ type: "success", message: "Nota aggiunta con successo" });
        setShowForm(false);
      }
    } else {
      var localCart = JSON.parse(localStorage.getItem("cart"));

      var tempArray = localCart.products;
      const index = tempArray.findIndex((el) => {
        return String(el.productID) === product.productID;
      });
      if (index != -1) {
        tempArray[index] = { ...props.product, note: noteValue };
      }
      localCart.products = tempArray;
      localStorage.setItem("cart", JSON.stringify(localCart));

      setPopup({ type: "success", message: "Nota aggiunta con successo" });
    }
  };
  const changeProductQuantity = async (num) => {
    try {
      const link = `${serverHost}carts/changeProductQuantity`;
      const bodyData = {
        productId: props.product.productID,
        quantity: num, // Change this to the desired quantity
      };
      const response = await fetch(link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(bodyData),
      });

      if (!response.ok) {
        throw new Error("Failed to change product quantity");
      }

      const data = await response.json();
      props.refresh()
      console.log("Product quantity changed successfully:", data);
    } catch (error) {
      console.error("Error changing product quantity:", error);
    }
  };
  const changeProductOption = async (opt) => {
    try {
      const link = `${serverHost}carts/changeProductOption`;
      const bodyData = {
        productId: props.product.productID,
        option: opt, // Change this to the desired quantity
      };
      const response = await fetch(link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(bodyData),
      });

      if (!response.ok) {
        throw new Error("Failed to change product quantity");
      }

      const data = await response.json();
      props.refresh()
      console.log("Product quantity changed successfully:", data);
    } catch (error) {
      console.error("Error changing product quantity:", error);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
                 <ConfirmationModal         showModal={showModal} 
        handleClose={()=>setShowModal(false)} 
        handleConfirm={()=>props.removeProduct(props.product)} 
        title="Rimuovi prodotto"
      message={'Sei sicuro di voler rimuovere questo prodotto dal carello?'}
      positive={false}/>
          <div className={styles.card}>
            <div className={styles.dataContainer}>
              {!isSmall && (
                <div className={styles.rowZero}>
                  <button
                    className={`redLink ${styles.clear}`}
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    rimuovi
                  </button>
                </div>
              )}
              <div className={styles.left}>
                {product?.picture[0] ? (
                  <img
                    className={styles.image}
                    onClick={() => {
                      navigate("/prodotto/" + product?._id);
                    }}
                    src={product.picture[0].replace("upload/", "upload/q_50/")}
                  />
                ) : (
                  <img
                    className={styles.image}
                    src={"/assets/product.png"}
                    onClick={() => {
                      navigate("/prodotto/" + product?._id);
                    }}
                  />
                )}
                <div className={styles.nameContainer}>
                  <div
                    className={styles.name}
                    onClick={() => {
                      navigate("/prodotto/" + product?._id);
                    }}
                  >
                    {product?.name}
                  </div>

                  <div className={styles.info}>
                    {/*<div className={styles.storeName}>{store?.name}</div>*/}
                    <div className={styles.noteText}>
                      {product?.note
                        ? `Annotazione: "${product?.note}"`
                        : props.product?.note &&
                          `Annotazione: "${props.product?.note}"`}
                    </div>
                  </div>
                  {!isSmall && (
                    <div
                      className={`greenLink ${styles.noteButton}`}
                      onClick={() => setShowForm(!showForm)}
                    >
                      {showForm ? "Annulla" : "+ nota"}
                    </div>
                  )}
                </div>
                <div className={styles.leftRow}>
                  
                <div className={styles.actionContainer}>
                  {/*<div className={styles.lable}>Opzione:</div>*/}
                 {option && <select
                    value={option}
                    className={styles.optionContainer}
                    onChange={(e) => {
                      setOption(e.target.value);
                      changeProductOption(e.target.value);
                    }}
                  >
                    {product?.options.map((option, index) => (
                      <option
                        key={index + 1}
                        className={styles.option}
                        value={option}
                      >
                        {option}
                      </option>
                    ))}
                  </select>}
                </div>
              
                <div className={styles.actionContainer}>
                  {/*<div className={styles.lable}>
                    Qnt (max {product?.inventory}):
                  </div>*/}
                  <div className={styles.row}>
                    <button
                      className={styles.quantityButton}
                      onClick={() => {
                        setQuantity(quantity - 1);
                        changeProductQuantity(quantity - 1);
                      }}
                      disabled={quantity == 1}
                    >
                      <IconMinus></IconMinus>
                    </button>

                    <div className={styles.quantity}>{quantity}</div>
                    <button
                      className={styles.quantityButton}
                      disabled={quantity == product?.inventory}
                      onClick={() => {
                        setQuantity(quantity + 1);
                        changeProductQuantity(quantity + 1);
                      }}
                    >
                      <IconPlus></IconPlus>
                    </button>
                  </div>
                </div>

              <div className={styles.priceContainer}>
                <div className={styles.priceCalc}>
                  {quantity} x {props.product.completeProduct.price}€
                </div>
                {!isSmall && (<div className={styles.actualPrice}>
                  <PriceTag
                    big={true}
                    price={props.product.price * quantity}
                  />
                </div>)}
              </div>
             </div>
            </div>
            </div>
            {showForm && (
              <form
                className={styles.noteFormContainer}
                onSubmit={handleFormSubmit}
              >
                <textarea
                  type="textArea"
                  placeholder="Inserisci una nota"
                  className={styles.noteForm}
                  onChange={handleNoteChange}
                  value={noteValue}
                />
                <button className="mainButtonGreen" type="submit">
                  Conferma
                </button>
              </form>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default CartProduct;
