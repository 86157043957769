import { useState } from "react";
import ProductBadge from "./ProductBadge";
import styles from "./StoreProduct.module.css";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "./ConfirmationModal";
const StoreProduct = (props) => {
  const product = props.product;
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleConfirm = () => {
    props.deleteFunction(product._id)
    console.log('Delete action confirmed');
    setShowModal(false);
  };

  return (
    <>
    <ConfirmationModal         showModal={showModal} 
        handleClose={handleClose} 
        handleConfirm={handleConfirm} 
        title="Conferma elimiazione"
        message={'Sei sicuro di voler eliminare il prodotto: '+ product.name}/>
      <div className={styles.card} style={{backgroundColor:  product.inventory === 0 ? 'lightgray' : 'white'}}>
        <ProductBadge  product={product} />
        <div className={styles.inventory}>Quantità: {product.inventory}</div>
        <div className={styles.buttonContainer}>
          <button
            onClick={() => navigate("/modificaProdotto/" + product._id)}
            className="mainButtonYellow"
          >
            <svg
              className="buttonIcon white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 -960 960 960"
            >
              <path d="M80 0v-160h800V0H80Zm80-240v-170l448-447q11-11 25.5-17t30.5-6q16 0 31 6t27 18l55 56q12 11 17.5 26t5.5 31q0 15-5.5 29.5T777-687L330-240H160Zm504-448 56-56-56-56-56 56 56 56Z" />
            </svg>{" "}
            modifica
          </button>
          <button
            onClick={() =>
              setShowModal(true)
            }
            className="mainButtonRed"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="buttonIcon white" viewBox="0 -960 960 960" ><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm80-160h80v-360h-80v360Zm160 0h80v-360h-80v360Z"/></svg>
            rimuovi
          </button>
        </div>
      </div>
    </>
  );
};

export default StoreProduct;
