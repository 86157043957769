import styles from "./CookieBanner.module.css";
import { useEffect, useState, useContext } from "react";
import { IconCookie } from "@tabler/icons-react";


const CookieBanner = (props) => {
  const [show, setShow] = useState(false);
  const [active, setActive] = useState(true);


  useEffect(() =>{
    localStorage.getItem('cookieConsent')==null && setShow(true)
  }, [])
  useEffect(() => {
    const countdownDuration = 50; // in seconds
    let timer;
    // Start the countdown
    const startCountdown = () => {
      timer = setTimeout(() => {
        // Update your state after 50 seconds
        consent(true);
        setActive(false);
      }, countdownDuration * 1000); // convert seconds to milliseconds
    };

    startCountdown();

    // Cleanup the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);


  const consent = (boole) => {
  
    localStorage.setItem("cookieConsent", boole)
    setShow(false)
  }

  return (
    <>
      {active&&
        <div className={styles.container} style={{ transform: `translateY(${show ? '0px' : '500px'})` }}>
          <div  className={styles.title}>
          <IconCookie></IconCookie> Abbiamo a cuore la tua Privacy!
            </div>
          <div  className={styles.text}>
          Questo sito utilizza cookie per ottimizzare la tua esperienza. Accetta l'uso dei cookie. Per dettagli consulta la nostra <a href="">informativa sui cookie</a>
          </div>
          <div className={styles.buttons}>
          <div className="mainButtonRed" onClick={()=>consent(false)}>
            Rifiuta
          </div>
          <div className="mainButtonGreen" onClick={()=>consent(true)}>
            Acconsento
          </div>
          </div>


        </div>



      }
    </>
  );
};

export default CookieBanner;