import styles from "./GenericDisplay.module.css";
import ProductCard from "./ProductCard";
import StoreCard from "./StoreCard";

const GenericDisplay = ({collection, type, title}) => {
  return (
    <>
      <div className="secondaryTitle">{title}</div>
      <div className={styles.container}>
        {collection?.length!=0 && <div
          className={styles.carousel}
        >
          {collection?.map((el) => (
            type=="product"?  <ProductCard product={el} key={el._id}/> : <StoreCard store={el}  key={el._id}/>
          ))}
        </div>}
        {collection?.length==0 && <div className={styles.voidList}>
          nessun elemento
        </div>}
      
      </div>
    </>
  );
};

export default GenericDisplay;
