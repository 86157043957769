import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    user: null,
    token: null,
    cartNumber: 0,
    chatNotificationNumber: 0
}

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setLogin: (state, action) =>{
            console.log("logged in")
            state.user = action.payload.user
            state.token = action.payload.token
        },
        setLogout: (state) => {
            console.log("logged out")
            state.user = null
            state.token = null
        },
        setIsOwner: (state, action) =>{
            state.user.isOwner = action.payload.isOwner
        },
        setLastAccess: (state) =>{
            const now= new Date().toISOString()
            state.user.lastAccess = now
        },
        setCartNumber: (state, action) =>{
            state.cartNumber = action.payload.cartNumber
        },
        setChatNotificationNumber: (state, action) =>{
            state.chatNotificationNumber = action.payload.chatNotificationNumber
        },
        

    }
})

export const {setLogin, setLogout, setIsOwner, setLastAccess, setCartNumber, setChatNotificationNumber} =authSlice.actions
export default authSlice.reducer