import styles from "./VerifyEmailPage.module.css";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const VerifyEmailPage = ({ message }) => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Verifica email - Tralevie ";
  }, []);
  return (
    <>
      <Navbar />
      <div className={styles.container}>
      <img src="/assets/client support.png" className={styles.icon} alt="" />

        <div className={styles.positiveMex}>Verifica l'indirizzo email inserito!</div>
        <div className={styles.positiveMexParag}>
          Per proseguire con l'utilizzo del nostro sito web, ti chiediamo
          gentilmente di confermare il tuo indirizzo email clikando sul link ricevuto via email.
          Grazie!
        </div>
      </div>

      <Footer />
    </>
  );
};

export default VerifyEmailPage;
