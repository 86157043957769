import Footer from "components/Footer";
import Navbar from "components/Navbar";
import React, { useEffect, useState } from "react";
import styles from "./GuidePage.module.css";
import { useNavigate } from "react-router-dom";

const GuidePage = () => {
  useEffect(() => {
    document.title = "Chi siamo - Tralevie";
  }, []);
  const navigate = useNavigate();

  return (
    <>
      <Navbar />
      <ul className="breadCrumb">
        <li className="breadCrumbElement">
          <a href="/">Homepage</a>
        </li>{" "}
        {">"}
        <li className="breadCrumbElement">
          <a href="/GuidaPerNegozi">Guida Per Negozi</a>
        </li>
      </ul>

      <div className={styles.container}>
        <div className={styles.firstContent}>
          <img src="/assets/teacher.png" alt="" className={styles.teacher} />
          <div className={styles.textCont}>
            <div className="secondaryTitle">Guida Completa Per I Negozi!</div>
            In questa pagina troverai tutte le informazioni che ti serviranno
            per comprendere e gestire al meglio il tuo negozio su Tralevie!
            <div>
              Se hai ulteriori domande visita la pagina{" "}
              <a className={styles.link} onClick={() => navigate("/FAQ")}>
                Domande Frequenti
              </a>{" "}
              oppure contattaci qua:{" "}
              <a className={styles.link} onClick={() => navigate("/contatti")}>
                Contattaci!
              </a>{" "}
            </div>
          </div>
        </div>

        <div className={styles.rowContainer}>
          <div className={styles.rowSection}>
            <div className="secondaryTitle">Che cos'è Tralevie?</div>
            <div className={styles.content}>
              Tralevie è una piattaforma per lo shopping online che mira ad
              offire le bellezze del commercio locale su internet. Tralevie è
              uno strumento che ti permette di digitalizzare il tuo negozio nel
              modo giusto. In pochi semplici passi potrai creare un tuo profilo
              ed iniziare a vendere i tuoi prodotti in tutta Italia. L'obiettivo
              di Tralevie è replicare le dinamiche del commercio locale, nel
              mondo online! Vogliamo offrire uno strumento umano e sincero in
              cui le persone valgono più di qualsiasi cosa.
              <div>
                Se vuoi conoscere meglio il progetto visita la pagina:{" "}
                <a
                  className={styles.link}
                  onClick={() => navigate("/chiSiamo")}
                >
                  Chi siamo
                </a>
              </div>
            </div>
          </div>
          <img className={styles.rowImage} />
        </div>

        <svg
          className={styles.wave}
          viewBox="0 -50 1440 170"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          style={{ transform: "rotate(180deg) translateY(5px)" }}
        >
          <path
            fill="rgb(255,255,255)"
            d="M0,85L24,84.4C48,83.8,96,82.5,144,77.3C192,72.1,240,62,288,65.7C336,69.4,384,84.1,432,86.9C480,89.7,528,80.4,576,75.4C624,70.4,672,70.7,720,67.8C768,65,816,59.4,864,61.7C912,64,960,74.4,1008,72.2C1056,70,1104,55.4,1152,58.7C1200,62.1,1248,83.4,1296,81.8C1344,80.2,1392,55.8,1440,54.5C1488,53.2,1536,75.5,1584,74.8C1632,74.1,1680,51.8,1728,49.4C1776,47,1824,64.3,1872,68.5C1920,72.7,1968,64.4,2016,65.1C2064,65.7,2112,75.1,2160,77.4C2208,79.7,2256,72.7,2304,74.5C2352,76.3,2400,87.9,2448,89.1C2496,90.3,2544,81.1,2592,76.5C2640,72,2688,73,2736,75.1C2784,77.3,2832,76.7,2880,77.9C2928,79.1,2976,76.1,3024,78.7C3072,81.3,3120,85.3,3168,87.3C3216,89.3,3264,78.1,3312,78.7C3360,79.3,3408,86.3,3432,88.3L3456,91V170H3432C3408,170,3360,170,3312,170C3264,170,3216,170,3168,170C3120,170,3072,170,3024,170C2976,170,2928,170,2880,170C2832,170,2784,170,2736,170C2688,170,2640,170,2592,170C2544,170,2496,170,2448,170C2400,170,2352,170,2304,170C2256,170,2208,170,2160,170C2112,170,2064,170,2016,170C1968,170,1920,170,1872,170C1824,170,1776,170,1728,170C1680,170,1632,170,1584,170C1536,170,1488,170,1440,170C1392,170,1344,170,1296,170C1248,170,1200,170,1152,170C1104,170,1056,170,1008,170C960,170,912,170,864,170C816,170,768,170,720,170C672,170,624,170,576,170C528,170,480,170,432,170C384,170,336,170,288,170C240,170,192,170,144,170C96,170,48,170,24,170L0,170Z"
          ></path>
        </svg>
        <div className={styles.rowContainer}>
          <div className={styles.rowSection}>
            <div className="secondaryTitle">Come si crea un negozio?</div>
            <div className={styles.content}>
              E' molto semplice e veloce! Dovrai prima creare un profilo
              personale (In questa pagina:{" "}
              <a
                className={styles.link}
                onClick={() => navigate("/auth/register")}
              >
                Registrati
              </a>{" "}
              ). Successivamente potrai creare un negozio in 3 minuti rispondendo a sempplici e veloci domande(In questa pagina:{" "}
              <a
                className={styles.link}
                onClick={() => navigate("/creaNegozio")}
              >
                Crea Negozio
              </a>
              )! Creato il negozio ti consigliamo di inserire almeno qualche prodotto che vuoi vendere per far capire ai tuoi futuri
              clienti che tipoligia di merce hai a disposizione.
               Fatto questo sei già pronto a ricevere ordini!
            </div>
          </div>
          <img className={styles.rowImage} />
        </div>
        <svg
          className={styles.wave}
          viewBox="0 -50 1440 170"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          style={{ transform: "rotate(180deg) translateY(5px)" }}
        >
          <path
            fill="rgb(255,255,255)"
            d="M0,85L24,82.2C48,79,96,74,144,62.3C192,51,240,34,288,36.8C336,40,384,62,432,59.5C480,57,528,28,576,14.2C624,0,672,0,720,11.3C768,23,816,45,864,45.3C912,45,960,23,1008,19.8C1056,17,1104,34,1152,39.7C1200,45,1248,40,1296,53.8C1344,68,1392,102,1440,107.7C1488,113,1536,91,1584,85C1632,79,1680,91,1728,104.8C1776,119,1824,136,1872,141.7C1920,147,1968,142,2016,133.2C2064,125,2112,113,2160,93.5C2208,74,2256,45,2304,53.8C2352,62,2400,108,2448,107.7C2496,108,2544,62,2592,48.2C2640,34,2688,51,2736,53.8C2784,57,2832,45,2880,59.5C2928,74,2976,113,3024,119C3072,125,3120,96,3168,90.7C3216,85,3264,102,3312,110.5C3360,119,3408,119,3432,119L3456,119L3456,170L3432,170C3408,170,3360,170,3312,170C3264,170,3216,170,3168,170C3120,170,3072,170,3024,170C2976,170,2928,170,2880,170C2832,170,2784,170,2736,170C2688,170,2640,170,2592,170C2544,170,2496,170,2448,170C2400,170,2352,170,2304,170C2256,170,2208,170,2160,170C2112,170,2064,170,2016,170C1968,170,1920,170,1872,170C1824,170,1776,170,1728,170C1680,170,1632,170,1584,170C1536,170,1488,170,1440,170C1392,170,1344,170,1296,170C1248,170,1200,170,1152,170C1104,170,1056,170,1008,170C960,170,912,170,864,170C816,170,768,170,720,170C672,170,624,170,576,170C528,170,480,170,432,170C384,170,336,170,288,170C240,170,192,170,144,170C96,170,48,170,24,170L0,170Z"
          ></path>
        </svg>
        <div className={styles.rowContainer}>
          <div className={styles.rowSection}>
            <div className="secondaryTitle">Come si vende su Tralevie?</div>
            <div className={styles.content}>
            Ci sono due principali approcci per utilizzare la piattaforma Tralevie:
<br></br>
(1) Digitalizzazione completa del catalogo: Questo approccio consente ai negozi di caricare l'intero catalogo dei loro prodotti sulla piattaforma. I clienti possono quindi navigare e acquistare direttamente la merce senza richiedere l'intervento del venditore.
<br></br>

(2)Vendita tramite interazione diretta: Se preferisci un approccio più personale, puoi comunque vendere tramite interazione diretta con i clienti. Quando un cliente trova il tuo negozio su Tralevie, può contattarti direttamente tramite la chat del nostro sito o tramite chiamata. In questo modo, puoi mostrare e vendere i prodotti desiderati al cliente esattamente come se fosse all'interno del tuo negozio fisico!
            </div>
          </div>
        </div>
        <svg
          className={styles.wave}
          viewBox="0 -50 1440 170"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          style={{ transform: "rotate(180deg) translateY(5px)" }}
        >
          <path
            fill="rgb(255,255,255)"
            d="M0,85L24,82.2C48,79,96,74,144,62.3C192,51,240,34,288,36.8C336,40,384,62,432,59.5C480,57,528,28,576,14.2C624,0,672,0,720,11.3C768,23,816,45,864,45.3C912,45,960,23,1008,19.8C1056,17,1104,34,1152,39.7C1200,45,1248,40,1296,53.8C1344,68,1392,102,1440,107.7C1488,113,1536,91,1584,85C1632,79,1680,91,1728,104.8C1776,119,1824,136,1872,141.7C1920,147,1968,142,2016,133.2C2064,125,2112,113,2160,93.5C2208,74,2256,45,2304,53.8C2352,62,2400,108,2448,107.7C2496,108,2544,62,2592,48.2C2640,34,2688,51,2736,53.8C2784,57,2832,45,2880,59.5C2928,74,2976,113,3024,119C3072,125,3120,96,3168,90.7C3216,85,3264,102,3312,110.5C3360,119,3408,119,3432,119L3456,119L3456,170L3432,170C3408,170,3360,170,3312,170C3264,170,3216,170,3168,170C3120,170,3072,170,3024,170C2976,170,2928,170,2880,170C2832,170,2784,170,2736,170C2688,170,2640,170,2592,170C2544,170,2496,170,2448,170C2400,170,2352,170,2304,170C2256,170,2208,170,2160,170C2112,170,2064,170,2016,170C1968,170,1920,170,1872,170C1824,170,1776,170,1728,170C1680,170,1632,170,1584,170C1536,170,1488,170,1440,170C1392,170,1344,170,1296,170C1248,170,1200,170,1152,170C1104,170,1056,170,1008,170C960,170,912,170,864,170C816,170,768,170,720,170C672,170,624,170,576,170C528,170,480,170,432,170C384,170,336,170,288,170C240,170,192,170,144,170C96,170,48,170,24,170L0,170Z"
          ></path>
        </svg>

        <div className={styles.rowContainer}>
          <div className={styles.rowSection}>
            <div className="secondaryTitle">
              Ho creato un negozio, e adesso?
            </div>
            <div className={styles.content}>

            Una volta creato il negozio, la tua principale responsabilità è rimanere disponibile per i clienti. Questo significa essere pronti a rispondere alle loro domande e gestire gli acquisti dei tuoi prodotti in modo efficace. Se desideri ampliare la tua presenza online, puoi anche arricchire l'esperienza dei clienti pubblicando foto nella sezione "Vetrina" direttamente dalla pagina del profilo del tuo negozio. Questo ti consente di mostrare i tuoi prodotti in modo accattivante e di attirare l'attenzione dei potenziali acquirenti.
            </div>
          </div>
        </div>
        <svg
          className={styles.wave}
          viewBox="0 -50 1440 170"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          style={{ transform: "rotate(180deg) translateY(5px)" }}
        >
          <path
            fill="rgb(255,255,255)"
            d="M0,85L24,82.2C48,79,96,74,144,62.3C192,51,240,34,288,36.8C336,40,384,62,432,59.5C480,57,528,28,576,14.2C624,0,672,0,720,11.3C768,23,816,45,864,45.3C912,45,960,23,1008,19.8C1056,17,1104,34,1152,39.7C1200,45,1248,40,1296,53.8C1344,68,1392,102,1440,107.7C1488,113,1536,91,1584,85C1632,79,1680,91,1728,104.8C1776,119,1824,136,1872,141.7C1920,147,1968,142,2016,133.2C2064,125,2112,113,2160,93.5C2208,74,2256,45,2304,53.8C2352,62,2400,108,2448,107.7C2496,108,2544,62,2592,48.2C2640,34,2688,51,2736,53.8C2784,57,2832,45,2880,59.5C2928,74,2976,113,3024,119C3072,125,3120,96,3168,90.7C3216,85,3264,102,3312,110.5C3360,119,3408,119,3432,119L3456,119L3456,170L3432,170C3408,170,3360,170,3312,170C3264,170,3216,170,3168,170C3120,170,3072,170,3024,170C2976,170,2928,170,2880,170C2832,170,2784,170,2736,170C2688,170,2640,170,2592,170C2544,170,2496,170,2448,170C2400,170,2352,170,2304,170C2256,170,2208,170,2160,170C2112,170,2064,170,2016,170C1968,170,1920,170,1872,170C1824,170,1776,170,1728,170C1680,170,1632,170,1584,170C1536,170,1488,170,1440,170C1392,170,1344,170,1296,170C1248,170,1200,170,1152,170C1104,170,1056,170,1008,170C960,170,912,170,864,170C816,170,768,170,720,170C672,170,624,170,576,170C528,170,480,170,432,170C384,170,336,170,288,170C240,170,192,170,144,170C96,170,48,170,24,170L0,170Z"
          ></path>
        </svg>
        <div className={styles.rowContainer}>
          <div className={styles.rowSection}>
            <div className="secondaryTitle">
              Come Inserire un nuovo prodotto?
            </div>
            <div className={styles.content}>
              Per inserire un nuovo prodotto dovrai recarti nella pagina di
              gestione del tuo negozio, nella sezione prodotti e premere sul pulsante "Aggiungi un
              prodotto". Una volta cliccato bisogna inserire dei dati del
              prodotto e il gioco è fatto!
            </div>
          </div>
        </div>
        <svg
          className={styles.wave}
          viewBox="0 -50 1440 170"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          style={{ transform: "rotate(180deg) translateY(5px)" }}
        >
          <path
            fill="rgb(255,255,255)"
            d="M0,85L24,82.2C48,79,96,74,144,62.3C192,51,240,34,288,36.8C336,40,384,62,432,59.5C480,57,528,28,576,14.2C624,0,672,0,720,11.3C768,23,816,45,864,45.3C912,45,960,23,1008,19.8C1056,17,1104,34,1152,39.7C1200,45,1248,40,1296,53.8C1344,68,1392,102,1440,107.7C1488,113,1536,91,1584,85C1632,79,1680,91,1728,104.8C1776,119,1824,136,1872,141.7C1920,147,1968,142,2016,133.2C2064,125,2112,113,2160,93.5C2208,74,2256,45,2304,53.8C2352,62,2400,108,2448,107.7C2496,108,2544,62,2592,48.2C2640,34,2688,51,2736,53.8C2784,57,2832,45,2880,59.5C2928,74,2976,113,3024,119C3072,125,3120,96,3168,90.7C3216,85,3264,102,3312,110.5C3360,119,3408,119,3432,119L3456,119L3456,170L3432,170C3408,170,3360,170,3312,170C3264,170,3216,170,3168,170C3120,170,3072,170,3024,170C2976,170,2928,170,2880,170C2832,170,2784,170,2736,170C2688,170,2640,170,2592,170C2544,170,2496,170,2448,170C2400,170,2352,170,2304,170C2256,170,2208,170,2160,170C2112,170,2064,170,2016,170C1968,170,1920,170,1872,170C1824,170,1776,170,1728,170C1680,170,1632,170,1584,170C1536,170,1488,170,1440,170C1392,170,1344,170,1296,170C1248,170,1200,170,1152,170C1104,170,1056,170,1008,170C960,170,912,170,864,170C816,170,768,170,720,170C672,170,624,170,576,170C528,170,480,170,432,170C384,170,336,170,288,170C240,170,192,170,144,170C96,170,48,170,24,170L0,170Z"
          ></path>
        </svg>
        <div className={styles.rowContainer}>
          <div className={styles.rowSection}>
            <div className="secondaryTitle">
              Ho ricevuto un ordine, e adesso?
            </div>
            <div className={styles.content}>
              Congratulazioni! Hai ricevuto un ordine, ed è il momento di
              procedere con il processo di gestione. Inizia confermando l'ordine
              e assicurandoti che tutti i dettagli siano corretti.
              Successivamente, prepara con cura il tuo prodotto o prodotti,
              assicurandoti che siano imballati in modo sicuro. Una volta
              completata questa fase, attendi pazientemente che un corriere
              qualificato passi a ritirare il tuo pacco. Di solito, ciò avviene
              entro un giorno lavorativo. Nel frattempo, assicurati di essere
              disponibile per la raccolta e tieniti pronto a consegnare il pacco
              al corriere quando si presenterà. Questo passo è fondamentale per
              garantire una consegna puntuale e soddisfacente al tuo cliente.
            </div>
          </div>
        </div>
        <svg
          className={styles.wave}
          viewBox="0 -50 1440 170"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          style={{ transform: "rotate(180deg) translateY(5px)" }}
        >
          <path
            fill="rgb(255,255,255)"
            d="M0,85L24,82.2C48,79,96,74,144,62.3C192,51,240,34,288,36.8C336,40,384,62,432,59.5C480,57,528,28,576,14.2C624,0,672,0,720,11.3C768,23,816,45,864,45.3C912,45,960,23,1008,19.8C1056,17,1104,34,1152,39.7C1200,45,1248,40,1296,53.8C1344,68,1392,102,1440,107.7C1488,113,1536,91,1584,85C1632,79,1680,91,1728,104.8C1776,119,1824,136,1872,141.7C1920,147,1968,142,2016,133.2C2064,125,2112,113,2160,93.5C2208,74,2256,45,2304,53.8C2352,62,2400,108,2448,107.7C2496,108,2544,62,2592,48.2C2640,34,2688,51,2736,53.8C2784,57,2832,45,2880,59.5C2928,74,2976,113,3024,119C3072,125,3120,96,3168,90.7C3216,85,3264,102,3312,110.5C3360,119,3408,119,3432,119L3456,119L3456,170L3432,170C3408,170,3360,170,3312,170C3264,170,3216,170,3168,170C3120,170,3072,170,3024,170C2976,170,2928,170,2880,170C2832,170,2784,170,2736,170C2688,170,2640,170,2592,170C2544,170,2496,170,2448,170C2400,170,2352,170,2304,170C2256,170,2208,170,2160,170C2112,170,2064,170,2016,170C1968,170,1920,170,1872,170C1824,170,1776,170,1728,170C1680,170,1632,170,1584,170C1536,170,1488,170,1440,170C1392,170,1344,170,1296,170C1248,170,1200,170,1152,170C1104,170,1056,170,1008,170C960,170,912,170,864,170C816,170,768,170,720,170C672,170,624,170,576,170C528,170,480,170,432,170C384,170,336,170,288,170C240,170,192,170,144,170C96,170,48,170,24,170L0,170Z"
          ></path>
        </svg>
        <div className={styles.rowContainer}>
          <div className={styles.rowSection}>
            <div className="secondaryTitle">Come ricevo gli incassi?</div>
            <div className={styles.content}>
              Potrai riscuotere il tuo credito in qualasiasi momento tu voglia tramite la pagina dedicata del tuo negozio.
            </div>
          </div>
        </div>
        <svg
          className={styles.wave}
          viewBox="0 -50 1440 170"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          style={{ transform: "rotate(180deg) translateY(5px)" }}
        >
          <path
            fill="rgb(255,255,255)"
            d="M0,85L24,82.2C48,79,96,74,144,62.3C192,51,240,34,288,36.8C336,40,384,62,432,59.5C480,57,528,28,576,14.2C624,0,672,0,720,11.3C768,23,816,45,864,45.3C912,45,960,23,1008,19.8C1056,17,1104,34,1152,39.7C1200,45,1248,40,1296,53.8C1344,68,1392,102,1440,107.7C1488,113,1536,91,1584,85C1632,79,1680,91,1728,104.8C1776,119,1824,136,1872,141.7C1920,147,1968,142,2016,133.2C2064,125,2112,113,2160,93.5C2208,74,2256,45,2304,53.8C2352,62,2400,108,2448,107.7C2496,108,2544,62,2592,48.2C2640,34,2688,51,2736,53.8C2784,57,2832,45,2880,59.5C2928,74,2976,113,3024,119C3072,125,3120,96,3168,90.7C3216,85,3264,102,3312,110.5C3360,119,3408,119,3432,119L3456,119L3456,170L3432,170C3408,170,3360,170,3312,170C3264,170,3216,170,3168,170C3120,170,3072,170,3024,170C2976,170,2928,170,2880,170C2832,170,2784,170,2736,170C2688,170,2640,170,2592,170C2544,170,2496,170,2448,170C2400,170,2352,170,2304,170C2256,170,2208,170,2160,170C2112,170,2064,170,2016,170C1968,170,1920,170,1872,170C1824,170,1776,170,1728,170C1680,170,1632,170,1584,170C1536,170,1488,170,1440,170C1392,170,1344,170,1296,170C1248,170,1200,170,1152,170C1104,170,1056,170,1008,170C960,170,912,170,864,170C816,170,768,170,720,170C672,170,624,170,576,170C528,170,480,170,432,170C384,170,336,170,288,170C240,170,192,170,144,170C96,170,48,170,24,170L0,170Z"
          ></path>
        </svg>
        <div className={styles.rowContainer}>
          <div className={styles.rowSection}>
            <div className="secondaryTitle">
              Quanto costa il servizio di Tralevie?
            </div>
            <div className={styles.content}>
              La piattaforma richiede un abbonamento mensile e una piccola percentuale su ogni vendita ma
              per voi che state supportando il progetto l'abbonamento annuale è gratuito!
              Il costo del servizio Tralevie è pari al 5% dell'importo totale
              dei prodotti che acquisti. Questa piccola percentuale è destinata
              a coprire i costi di manutenzione e supporto del nostro servizio,
              consentendoci di offrirti un'esperienza affidabile e di alta
              qualità.{" "}
            </div>
          </div>
        </div>
        <svg
          className={styles.wave}
          viewBox="0 -50 1440 170"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          style={{ transform: "rotate(180deg) translateY(5px)" }}
        >
          <path
            fill="rgb(255,255,255)"
            d="M0,85L24,82.2C48,79,96,74,144,62.3C192,51,240,34,288,36.8C336,40,384,62,432,59.5C480,57,528,28,576,14.2C624,0,672,0,720,11.3C768,23,816,45,864,45.3C912,45,960,23,1008,19.8C1056,17,1104,34,1152,39.7C1200,45,1248,40,1296,53.8C1344,68,1392,102,1440,107.7C1488,113,1536,91,1584,85C1632,79,1680,91,1728,104.8C1776,119,1824,136,1872,141.7C1920,147,1968,142,2016,133.2C2064,125,2112,113,2160,93.5C2208,74,2256,45,2304,53.8C2352,62,2400,108,2448,107.7C2496,108,2544,62,2592,48.2C2640,34,2688,51,2736,53.8C2784,57,2832,45,2880,59.5C2928,74,2976,113,3024,119C3072,125,3120,96,3168,90.7C3216,85,3264,102,3312,110.5C3360,119,3408,119,3432,119L3456,119L3456,170L3432,170C3408,170,3360,170,3312,170C3264,170,3216,170,3168,170C3120,170,3072,170,3024,170C2976,170,2928,170,2880,170C2832,170,2784,170,2736,170C2688,170,2640,170,2592,170C2544,170,2496,170,2448,170C2400,170,2352,170,2304,170C2256,170,2208,170,2160,170C2112,170,2064,170,2016,170C1968,170,1920,170,1872,170C1824,170,1776,170,1728,170C1680,170,1632,170,1584,170C1536,170,1488,170,1440,170C1392,170,1344,170,1296,170C1248,170,1200,170,1152,170C1104,170,1056,170,1008,170C960,170,912,170,864,170C816,170,768,170,720,170C672,170,624,170,576,170C528,170,480,170,432,170C384,170,336,170,288,170C240,170,192,170,144,170C96,170,48,170,24,170L0,170Z"
          ></path>
        </svg>
      </div>

      <Footer />
    </>
  );
};

export default GuidePage;
