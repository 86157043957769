import styles from "./OrdersSection.module.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import OrderCard from "./OrderCard";
import OrdersStats from "./OrdersStats";
import { serverHost } from "helpers/usefulData";
import {
  IconCalendarMonth,
  IconChevronDown,
  IconChevronRight,
  IconCoinEuro,
  IconIdBadge2,
  IconPackages,
  IconProgressCheck,
  IconReceipt,
} from "@tabler/icons-react";
import Loader from "./Loader";
const OrdersSection = ({ type, store={} }) => {
  const navigate = useNavigate();
  //var storeId = useParams();
  const [orders, setOrders] = useState(null);
  const [filterResults, setFilterResults] = useState(null);
  const token = useSelector((state) => state.token);
  const [filter, setFilter] = useState("tutti");
  const [activeKey, setActiveKey] = useState(null);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    if (event.target.value=="tutti"){
      setFilterResults(orders)

    }else{
      const filteredOrders = orders.filter(order => order.status === event.target.value);
      setFilterResults(filteredOrders)
    }

  };

  useEffect(() => {
    getOrders();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getOrders = async () => {
    var link = serverHost + `orders/shopOrders/all`;
    if (type == "client") link = serverHost + `orders/clientOrders/all`;
    const response = await fetch(link, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    console.log(data);
    setOrders(data);
    setFilterResults(data)
  };

  const getStatus =(status) => {
    let translation = '';
    let style = '';
  
    switch (status) {
      case 'created':
        translation = 'creato';
        style = 'pendingStyle'; // You can define your CSS classes
        break;
      case 'confirmed':
        translation = 'confermato';
        style = 'processingStyle';
        break;
      case 'delivered':
        translation = 'spedito';
        style = 'completedStyle';
        break;
        case 'completed':
        translation = 'completato';
        style = 'unknownStyle';
        default:
          translation = '';
          style = ''; 
    }
    return <td><div  className={`${styles.label} ${styles.red}`}>{translation}</div></td>
  }

  return (
    <>{
      orders == null ?
      <Loader/>
    :<>
            {type == "store" && orders != null && <OrdersStats orders={orders} store={store}/>}

      <div className={styles.container}>
        <div className={styles.firstRow}>
          <div className="secondaryTitle">
            <IconReceipt></IconReceipt>{" "}
            {type == "client" ? "I miei ordini" : "Storico degli ordini"}
          </div>
        </div>

        {orders?.length > 0 ? (<>
          <select
            className={styles.selection}
            value={filter}
            onChange={handleFilterChange}
          >
            <option value="tutti">Tutti gli ordini</option>
            <option value={"created"}>Ordini Creati</option>
            <option value={"confirmed"}>Ordini Confermati</option>
            <option value={"delivered"}>Ordini Spediti</option>
            <option value={"completed"}>Ordini Completati</option>
            <option value={"canceled"}>Ordini Cancellati</option>

          </select>
        
        <table className={styles.ordersTable}>
          <thead>
            <tr>
              <th className={styles.IDColumn}><IconIdBadge2></IconIdBadge2>{window.innerWidth>800?"ID":""}</th>
              <th><IconCalendarMonth></IconCalendarMonth>{window.innerWidth>800?"Data":""}</th>
              <th> <IconPackages></IconPackages>{window.innerWidth>800?"Prodotti":""}</th>
              <th><IconCoinEuro></IconCoinEuro> {window.innerWidth>800?"Valore":""}</th>
              <th><IconProgressCheck></IconProgressCheck> {window.innerWidth>800?"Stato":""}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filterResults?.map((order, key) => {
              let date= new Date(order.createdAt)
              return (
                <>
                  <tr
                    key={key}
                    onClick={() => setActiveKey(activeKey == key ? null : key)}
                  >
                    <td className={styles.ID}>#{order._id}</td>
                    <td>{date.toLocaleDateString("it-IT", { month: 'short', day: 'numeric' })}</td>
                    <td>{order.products.reduce((total, product) => total + product.quantity, 0)}</td>
                    <td>{order.totalPrice} €</td>
                    
                    <td><div  className={styles.label}>{order.status}</div></td>

                    <td>
                      {activeKey == key ? (
                        <div className={styles.row}>- info <IconChevronDown></IconChevronDown></div>
                      ) : (
                        <div className={styles.row}>+ info<IconChevronRight></IconChevronRight></div>
                      )}
                    </td>
                  </tr>
                  
                  {activeKey == key && (
            <tr key={`${key}-card`}>
            <td colSpan={window.innerWidth>800? 6 : 5}>
              <OrderCard order={order} type={type} />
            </td>
          </tr>
                  )}
                </>
              );
            })}
          </tbody>
        </table>
        </>)
        :
        <div className={styles.noOrders}>Nessun Ordine</div>
        }
        {/*
    orders?.length>0 ? (orders?.map((ord) => (
      ord.status==filter || filter=="tutti"? (<OrderCard key={ord._id} order={ord} type={type}/>) : <></>
          ))):
          <div className={styles.noOrders}>Nessun Ordine</div>
    */}
      </div>
      </>}
    </>
  );
};

export default OrdersSection;
