import styles from "./SearchBar.module.css";
import { Search } from "@mui/icons-material";
import { IconSearch } from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const SearchBar = ({showHistory=true}) => {
  var oldQuery = useParams();
  const [query, setQuery] = useState(oldQuery.query);
  const [searchHistory, setSearchHistory] = useState([]);

  const navigate = useNavigate();
  const [inputFocused, setInputFocused] = useState(false);
  const [menuHover, setMenuHover] = useState(false);


  const handleClick = (e) => {
    e.preventDefault();
    saveSearch();
    navigate("/cerca/" + query);
  };

  useEffect(() => {
    const savedSearchHistory =
      JSON.parse(localStorage.getItem("searchHistory")) || [];
    setSearchHistory(savedSearchHistory);
  }, []);

  const saveSearch = () => {
    if (query.trim() !== "") {
      var updatedHistory = [...searchHistory];
      if (!updatedHistory.includes(query)) {
        updatedHistory=[query, ...searchHistory];
        setSearchHistory(updatedHistory);
        localStorage.setItem("searchHistory", JSON.stringify(updatedHistory));
      }
      setQuery("");
    }
  };

  const deleteSingleSearch = (index) => {
    const updatedHistory = [...searchHistory];
    updatedHistory.splice(index, 1);
    setSearchHistory(updatedHistory);
    localStorage.setItem('searchHistory', JSON.stringify(updatedHistory));
  };
  

  return (<>
    <form method="get" className={styles.searchContainer}>
      <input
        type="text"
        value={query}
        placeholder={window.innerWidth>500 ? "Cerca prodotti, categorie, negozi...": "Cerca..."}
        onChange={(e) => setQuery(e.target.value)}
        onFocus={() => setInputFocused(true)}
        onBlur={() => {
          setTimeout(() => setInputFocused(false), 500);
        }}      />
      <button type="submit" disabled={!query} onClick={handleClick} className={styles.searchButton}>
<IconSearch className={styles.searchIcon}></IconSearch>
      </button>

    </form>
    {searchHistory!=[] && (inputFocused || menuHover) && showHistory &&
          <ul className={styles.historyContainer} onMouseEnter={()=> setMenuHover(true)} onMouseLeave={()=> setTimeout(() => setMenuHover(false), 500)} >
          {searchHistory.map((term, index) => (
            index<5 && <li key={index} 

                      onFocus={() => console.log("sjoiwnjef ")}
          >
              <span className={styles.term} onClick={()=>{navigate("/cerca/" + term)}}>{term}</span>
              <button className={styles.close} onClick={() => deleteSingleSearch(index)}>
                                  <img src="/assets/icons/close.svg" />
</button>
            </li>
          ))}
        </ul> }
        </>
  );
};

export default SearchBar;
