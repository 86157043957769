import styles from "./BentoCart.module.css";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CartProduct from "components/CartProduct";
import { PopupContext } from "App";
import { setCartNumber } from "state";
import Loader from "components/Loader";
import PriceTag from "components/PriceTag";
import { serverHost } from "helpers/usefulData";
import Badge from "components/Badge";
import { IconChevronRight, IconShoppingCart } from "@tabler/icons-react";



const BentoCart = () => {
  const [cart, setCart] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totShippingCost, setTotShippingCost] = useState(0);

  const [groupedProducts, setGroupedProducts] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [stores, setStores] = useState([]);

  const token = useSelector((state) => state.token);
  const [popup, setPopup] = useContext(PopupContext);

  const cartNumber = useSelector((state) => state.cartNumber);
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Carrello - Tralevie";
    getCart();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const refresh = () => {
    getCart();
  };
  useEffect(() => {
    setTot();
    sort();
  }, [cart]);

  useEffect(() => {
    const getStoresForEachProduct = async () => {
      for (const store of groupedProducts) {
        await getStore(store?.shopID); // Assuming getStore is an asynchronous function
      }
    };

    groupedProducts && getStoresForEachProduct();
  }, [groupedProducts]);

  const getStore = async (id) => {
    const response = await fetch(serverHost + `stores/${id}`, {
      method: "GET",
    });

    if (!response.ok) {
      setPopup({
        type: "error",
        message: "Errore nel server, per cortesia riprovare",
      });
    } else {
      const data = await response.json();
      setStores([...stores, data.store]);
    }
  };
  const getCart = async () => {
    setIsLoading(true);
    if (token != null) {
      const response = await fetch(serverHost + "carts/", {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      setCart(data);
      var number = 0;
      data.products.map((product) => (number += parseInt(product.quantity)));
      dispatch(
        setCartNumber({
          cartNumber: number,
        })
      );
    } else {
      setCart(JSON.parse(localStorage.getItem("cart")) || null);
      const localCart = JSON.parse(localStorage.getItem("cart"));
      var number = 0;
      localCart &&
        localCart?.products?.map(
          (product) => (number += parseInt(product.quantity))
        );
      dispatch(
        setCartNumber({
          cartNumber: number,
        })
      );
    }

    setTot();
    setIsLoading(false);
  };

  const checkout = async () => {
    const response = await fetch(serverHost + "checkout/", {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    window.location = data.url;
  };

  const setTot = () => {
    var tot = 0;
    cart?.products.forEach((prod) => {
      tot = tot + prod.price * prod.quantity;
    });
    tot = Number(tot.toFixed(2));
    console.log("calculating cart tot", tot);

    tot && setTotalPrice(tot);
  };

  const sort = () => {
    const groupedProductsTemp = [];

    cart?.products.forEach((product) => {
      console.log(product);
      const shopID = product?.completeProduct?.shopID;
      let foundShop = groupedProductsTemp.find(
        (group) => group?.shopID === shopID
      );

      if (!foundShop) {
        foundShop = {
          shopID,
          products: [],
          maxShippingCost: 0,
        };
        groupedProductsTemp.push(foundShop);
      }

      foundShop.products.push(product);
      const shippingCost = product.completeProduct.shippingCost || 0;
      if (shippingCost > foundShop.maxShippingCost) {
        foundShop.maxShippingCost = shippingCost;
      }
    });
    setGroupedProducts(groupedProductsTemp);
    setTotShippingCost(
      groupedProductsTemp.reduce((acc, group) => acc + group.maxShippingCost, 0)
    );
    console.log(
      "max",
      groupedProductsTemp.reduce((acc, group) => acc + group.maxShippingCost, 0)
    );
  };
  const removeProduct = async (prod) => {
    if (token != null) {
      const savedStoreResponse = await fetch(
        serverHost + "carts/removeProduct",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(prod),
        }
      );
      if (!savedStoreResponse.ok) {
        setPopup({ type: "error", message: "operazione non riuscita" });
      } else {
        setPopup({ type: "success", message: "Prodotto rimosso dal carrello" });
        getCart();
      }
    } else {
      var localCart = JSON.parse(localStorage.getItem("cart"));

      var tempArray = localCart.products;
      const index = tempArray.findIndex((el) => {
        return String(el.productID) === prod.productID;
      });
      if (index != -1) {
        tempArray.splice(index, 1);
      }
      localCart.products = tempArray;
      localStorage.setItem("cart", JSON.stringify(localCart));

      setPopup({ type: "success", message: "Prodotto rimosso dal carrello" });
      getCart();
    }

    dispatch(
      setCartNumber({
        cartNumber: parseInt(cartNumber) - parseInt(prod.quantity),
      })
    );
  };

  if (!cart) return;

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className="secondaryTitle">
          <IconShoppingCart></IconShoppingCart>
          Carrello {"("} {cartNumber}{" "}
          {cartNumber == 1 ? "prodotto)" : "prodotti)"}
        </div>
        {isLoading && <Loader fullScreen={false} isText={true} />}

        {cart?.products.length !== 0 ? (
groupedProducts?.map((group, index) => (
  <div key={group?.shopID} className={styles.storeProd}>
    <div className={styles.storeProdStore}>
      <Badge
        key={stores[index]?._id} // This line might be causing the issue
        image={stores.find(store => store._id === group.shopID)?.picture[0]}
        name={stores.find(store => store._id === group.shopID)?.name}
        path={"/negozio/" + group.shopID} // Use group.shopID instead of stores[index]?._id
        vertical={true}
      />
      <div className={styles.shippingBento}>
        spedizione complessiva da questo negozio: {group.maxShippingCost} €
      </div>
    </div>
    <div className={styles.rightProducts}>
      {group?.products.map((prod) => (
        <CartProduct
          refresh={refresh}
          product={prod}
          key={prod.completeProduct?._id} // Assuming completeProduct has _id
          removeProduct={removeProduct}
        />
      ))}
    </div>
  </div>
))
        ) : (
          <div className={styles.noProd}>Nessun prodotto nel carrello</div>
        )}
        {cart?.products.length != 0 && (
          <>
            <div className={styles.checkoutSection}>
              <div>
                <div className={styles.tot}>
                  Spedizione totale:
                  {
                    <PriceTag
                      key={totShippingCost}
                      price={totShippingCost}
                      big={false}
                    />
                  }
                </div>
                <div className={styles.tot}>
                  Totale:{" "}
                  {
                    <PriceTag
                      key={totalPrice}
                      price={totalPrice + totShippingCost}
                      big={true}
                    />
                  }
                </div>
              </div>

              <button
                id={styles.checkout}
                className="mainButtonGreen"
                onClick={checkout}
              >
                Procedi all'acquisto <IconChevronRight></IconChevronRight>
              </button>
            </div>

            <div className={styles.spedizione}>
              Spedizione in 2/3 giorni lavorativi
              {/*  <Tooltip text="La spedizione avverrà tramite Tralevie in due giorni lavorativi. " />*/}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BentoCart;
