import styles from "./ReviewCard.module.css";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Badge from "./Badge";
import { serverHost } from "helpers/usefulData";
import ConfirmationModal from "./ConfirmationModal";
import RatingStars from "./RatingStars";
import { PopupContext } from "App";

const ReviewCard = ({ review, badgeType="user" }) => {
  const navigate = useNavigate();
  //var storeId = useParams();
  const [user, setUser] = useState(null);
  const [show, setShow] = useState(true);
  const userAccount = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const [showModal, setShowModal] = useState(false);
  const [popup, setPopup] = useContext(PopupContext);



  useEffect(() => {
    getUser();
    console.log(review)
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getUser = async () => {
    var link = serverHost+ "users/" + review?.userID;
    const response = await fetch(link, {
      method: "GET",
    });
    const data = await response.json();
    setUser(data.user);
  };
  const deleteReview = async () => {
    var link = serverHost+ "reviews/delete/" + review?._id;
    const response = await fetch(link, {
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
    
    });
    const data = await response.json();
    if (response.status=="200") setShow(false)
    setShowModal(false)
    if (response.ok){
    setPopup({ type: "success", message: "Recensione eliminata" });

    }else {
      setPopup({ type: "error", message: "operazione non riuscita" });
    }
    };
  return (
    <>
         <ConfirmationModal         showModal={showModal} 
        handleClose={()=>setShowModal(false)} 
        handleConfirm={deleteReview} 
        title="Elimina Recensione"
      message={'Sei sicuro di voler eliminare definitivamente questa recensione?'}
      positive={false}/>
    {show &&
      <div className={(!review.product && badgeType=="product") ? styles.containerDisabled : styles.container }>
        {userAccount?._id == review.userID && (
          <button
            className="redLink"
            onClick={() => {
              setShowModal(true)

            }}
          >
            Elimina
          </button>
        )}
        <div className={styles.firstRow}>
          <div>
                      {badgeType=="user" && <Badge image={user?.picturePath} path={""} name={user?.firstName + " " + user?.lastName}/>}
          {badgeType=="product" && review?.product && <Badge image={review?.product.picture[0]} path={`/prodotto/${review.product._id}`} name={ review?.product.name.length > 20 ? review?.product.name.substring(0, 17) + "..." : review?.product.name}/>}
          {badgeType=="product" && !review?.product && <Badge image={"/assets/product.png"} path={""} name={"prodotto rimosso"}/>}
          <div className={styles.date}>{review?.createdAt.slice(0, 10)}</div>
          </div>


          <div className={styles.ratingsContainer}>
           {review.productRating!=0 &&  <div className={styles.rating}>
              <div className={styles.ratingLabel}>Prodotto:</div>
              <div className={styles.ratingValue}><RatingStars rating={review.productRating ? review.productRating : 0} inline={true}/>
</div>
            </div>}
            {review.serviceRating &&
            <div className={styles.rating}>
              <div className={styles.ratingLabel}>Servizio:</div>
              <div className={styles.ratingValue}><RatingStars rating={review.serviceRating} inline={true}/></div>
            </div>}
          </div>
        </div>
        <div className={styles.content}>{review.content}</div>

      </div>
}
    </>
  );
};

export default ReviewCard;
