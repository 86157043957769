import Footer from "components/Footer";
import Navbar from "components/Navbar";
import React, { useEffect, useState } from "react";
import styles from "./FAQPage.module.css";

const FAQPage = ({section=true}) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  useEffect(() => {
    document.title = "FAQ - Tralevie";
  }, []);

  const handleSubmit = (e) => {
    // Send email with the user's email and message using an API or other means
    // Add your logic here

    // Reset form fields
    setIsFormSubmitted(true);
  };

  const faqData = [
    {
      question: "Che cos'è Tralevie?",
      answer:
      "Tralevie è una piattaforma di e-commerce dedicata a digitalizzare il commercio locale. Sulla nostra piattaforma, avrai accesso ai migliori negozi della tua città e potrai acquistare i loro prodotti con consegna entro un giorno lavorativo. I negozianti sono pronti ad assisterti in ogni tua esigenza. Non esitare a contattarli e scoprire i prodotti straordinari che hanno da offrire!",    },
    {
      question: "Quali negozi locali posso trovare su Tralevie?",
      answer:
        "Attualmente Tralevie è presente sul territorio del comune di Padova. I negozi a disposizione sono in continuo aumento, controlla la homepage di Tralevie per vedere la lista completa!",
    },
{
      question: "Come trovo i prodotti da acquistare?",
      answer:
      "Ci sono due modi! Il primo metodo consiste nel consultare i prodotti esposti dai negozianti nelle loro pagine. Il secondo è l'interazione diretta con il commerciante. Come quando varchi la soglia di un negozio fisico, su Tralevie hai l'opportunità di comunicare direttamente con il venditore, rivolgere domande, ottenere informazioni dettagliate sui prodotti e, se lo desideri, aggiungerli al tuo carrello con facilità. Siamo qui per rendere la tua esperienza di shopping online il più vicina possibile a quella in negozio, offrendoti la possibilità di connetterti in modo personale con i negozianti locali."    },
    {
      question: "Un negozio non ha alcun prodotto, vuol dire che non posso comprare nulla?",
      answer:
" Tralevie non impone ai negozianti l'obbligo di elencare tutti i loro prodotti. Tuttavia, basta inviare loro un messaggio e sarai in grado di acquistare qualsiasi articolo offerto nel loro negozio!"    },
    
    {
      question: "Posso acquistare prodotti da più negozi locali contemporaneamente?",
      answer:
        "Certamente! Verranno creati più ordini per tenere traccia di ogni prodotto proveniente da ogni negozio!",
    },
    {
      question: "Quali metodi di pagamento accettate per gli acquisti dai negozi locali?",
      answer:
        "Attualmente è possibile pagare con carte o tramite Google pay.",
    },  {
      question: "Come viene gestita la consegna dei prodotti acquistati dai negozi locali?",
      answer:
        "Tralevie si occuperà di recuperare e consegnare i prodotti ordinati in un giorno lavorativo grazie ad una rete di rider!",
    },  {
      question: "Quali misure vengono adottate per garantire la sicurezza dei dati personali?",
      answer:
      "Noi raccogliamo solo i dati strettamente necessari e meno rischiosi per garantire la tua sicurezza e la tua privacy. Ad esempio, i dati di pagamento sono gestiti da uno dei servizi di pagamento più affidabili del settore, Stripe. La sicurezza dei tuoi dati è la nostra massima priorità, e lavoriamo costantemente per garantire che le tue informazioni siano protette da qualsiasi minaccia."    },  {
      question: "Posso restituire o cambiare un prodotto acquistato da un negozio locale tramite il vostro sito?",
      answer:
        "Certo! Contatta personalmente il negozio in questione per sapere la loro personale politica di reso o contatta Tralevie per avere un ulteriore supporto!",
    },  {
      question: "Come posso contattare il servizio clienti per assistenza?",
      answer:
        "Scrivi a info@tralevie.com per avere assistenza il più velocemente possibile!",
    },
    
  ];

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const renderFAQItems = () => {
    return faqData?.map((faq, index) => (
      <div key={index} className={styles.item}           onClick={() => toggleAccordion(index)}
      >
        <div
          className={`${styles.question} ${
            activeIndex === index ? "active" : ""
          }`}
        >
          <div>{faq.question} </div>{" "}
          <div className={`${styles.openClose}  ${
            activeIndex === index && styles.rotate}`}>+</div>
        </div>
        {activeIndex === index && (
          <div className={styles.answer}>{faq.answer}</div>
        )}
      </div>
    ));
  };

  return (
    <>
      {!section && <><Navbar />
      <ul className="breadCrumb">
        <li className="breadCrumbElement">
          <a href="/">Homepage</a>
        </li>{" "}
        {">"}
        <li className="breadCrumbElement">
          <a href="/FAQ">FAQ</a>
        </li>
      </ul></>}
      <div className={!section ? styles.container : styles.sectionContainer }>
        <div>
          <img className={styles.img} src="/assets/doubt.png" alt="" />

          <div className="mainTitle">Domande Frequenti (FAQ)</div>
        </div>

        <div className="faq-container">{renderFAQItems()}</div>
      

      {isFormSubmitted ? (
        <div className="form-success-message">
          A breve risponderemo al tuo messaggio. Grazie!
        </div>
      ) : (
        <>
          <div className="mainTitle">
            Non trovi la risposta che cerchi? Contattaci!
          </div>
          <div className={styles.formContainer}>
            <img className={styles.imgForm} src="/assets/question.png" alt="" />

            <form className={styles.actualForm}
              action="mailto:info@tralevie.com"
              method="post"
              enctype="text/plain"
            >
              <label>Nome</label>
              <input type="text" />
              <label>Email</label>
              <input type="email" />
              <label>Domanda</label>
              <textarea rows="5" name="body" required/>
              <button className="cta" type="submit">Invia</button>
            </form>
          </div>
        </>
      )}
      </div>
      {!section && <> <Footer /> </>}
    </>
  );
};

export default FAQPage;
