import styles from "./VerifiedEmailPage.module.css";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const VerifiedEmailPage = ({ message }) => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Verifica email - Tralevie ";
  }, []);
  return (
    <>
      <Navbar />
      <div className={styles.container}>
      <img src="/assets/happy.png" className={styles.icon} alt="" />
        <div className={styles.positiveMex}>Indirizzo confermato!</div>
        <div className={styles.positiveMexParag}>
          Congratulazioni hai confermato correttamente il tuo indirizzo email. Ora puoi accedere al nostro sito. Benvenuto!
        </div>
        <a className="mainButtonGreen" onClick={() => navigate("/auth/login")}>
          Effettua l'accesso
        </a>
      </div>

      <Footer />
    </>
  );
};

export default VerifiedEmailPage;
