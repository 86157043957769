import Navbar from "components/Navbar";
import Footer from "components/Footer";
import styles from "./cartPage.module.css";
import ProductCarousel from "components/ProductCarousel";

import BentoCart from "components/BentoCart";

const CartPage = () => {  return (
    <>
      <Navbar />
      <ul className="breadCrumb">
        <li className="breadCrumbElement">
          <a href="/">Homepage</a>
        </li>{" "}
        {">"}
        <li className="breadCrumbElement">
          <a href="/carrello">il Mio Carrello</a>
        </li>
      </ul>

<BentoCart/>
      <div>
        <div className="secondaryTitle">Prodotti consigliati</div>
        <ProductCarousel storeID={""} />
      </div>
      <Footer />
    </>
  );
};

export default CartPage;
