import styles from "./Showcase.module.css";
import Post from "./Post";
import { useEffect, useState } from "react";
import GenericCarousel from "./GenericCarousel";
import { serverHost } from "helpers/usefulData";
const Showcase = ({store}) => {
  const [posts, setPosts] = useState(null);
  useEffect(() => {
    getPosts();
  }, []);

  const getPosts = async () => {
    var link= serverHost+ `posts/recent`
    if (store) link=serverHost+ `posts/store/`+store._id
    const response = await fetch(link, {
      method: "GET",
    });
    const data = await response.json();
    setPosts(data);
  };

  return (

    <GenericCarousel type={"post"} collection={posts} title={store==null?"Aggiornamenti Dai Nostri Negozi": "La vetrina di '"+store?.name+"'"}/>

  );
};

export default Showcase;
