import { IconX } from "@tabler/icons-react";
import styles from "./ConfirmationModal.module.css";

const ConfirmationModal = ({ showModal, handleClose, handleConfirm, title, message , positive=true}) => {

  return (
    <div className={`${styles.modal} ${showModal ? styles.show : ''}`}>
      <div className={styles.modalContent}>
        <div className={styles.row}>
        <div className={styles.title}>{title}</div>

        <span className={styles.close} onClick={handleClose}><IconX></IconX></span>
        </div>

        <p className={styles.content}>{message}</p>
        <button className={`${positive? "mainButtonGreen":"mainButtonRed" } ${styles.button}`} onClick={handleConfirm}>Conferma</button>
      </div>
    </div>
  );
};


export default ConfirmationModal;
