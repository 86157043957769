import { useEffect, useState } from "react";
import styles from "./Tag.module.css";
import { useNavigate } from "react-router-dom";
import { IconSearch, IconTag } from "@tabler/icons-react";

const Tag = ({ name, small, icon=""}) => {
  const navigate = useNavigate();
  return (
    <div className={`${styles.container} ${small && styles.smallContainer}`}                   onClick={() => {
      navigate("/cerca/" + name);
    }}>

{icon=="search" ?<IconSearch className={styles.tagIcon}></IconSearch> : <IconTag className={styles.tagIcon}></IconTag>}        {name}
    </div>
  );
};

export default Tag;
