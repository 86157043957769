import { BrowserRouter, Navigate, Routes, Route  } from 'react-router-dom';
import AuthPage from 'scenes/AuthPage';
import HomePage from 'scenes/homePage';
import ProfilePage from 'scenes/profilePage';
import StorePage from 'scenes/storePage';
import CartPage from 'scenes/cartPage';
import MyStorePage from 'scenes/myStorePage';
import ProductPage from 'scenes/productPage';
import ChatPage from 'scenes/chatPage';
import SearchPage from 'scenes/SearchPage';
import Popup from 'components/Popup';
import { createContext, useEffect, useState } from 'react';
import MessagePage from 'scenes/messagePage';
import FAQPage from 'scenes/FAQPage';
import AboutUsPage from 'scenes/aboutUsPage';
import ProtectedRoute from 'state/ProtectedRoute';
import StoreProtectedRoute from 'state/StoreProtectedRoute';
import VerifyEmailPage from 'scenes/VerifyEmailPage';
import DeliveryPage from 'scenes/DeliveryPage';
import ContactPage from 'scenes/ContactPage';
import StoresPage from 'scenes/StoresPage';
import ScrollToTop from 'components/ScrollToTop';
import GuidePage from 'scenes/GuidePage';
import VerifiedEmailPage from 'scenes/VerifiedEmailPage';
import ProductsListPage from 'scenes/productsListPage';
import OrdersListPage from 'scenes/ordersListPage';
import CreateEditProductPageChat from 'scenes/createEditProductPageChat';
import CreateEditStorePageChat from 'scenes/createEditStorePageChat';
import LandingPage from 'scenes/LandingPage';
import NoInternetConnection from 'components/NoInternetConnection';
import OrderFullfilledPage from 'scenes/OrderFullfilledPage';
import SubscriptionPage from 'scenes/SubscriptionPage';
import OrderReturnPage from 'scenes/OrderReturnPage';
import AfterAuthPage from 'scenes/AfterAuthPage';

export const PopupContext= createContext();

function App() {

  const [popup, setPopup]= useState({type: null, message:null})

  useEffect(() =>{
    if (localStorage.getItem('cart')==null){
    localStorage.setItem("cart", JSON.stringify({products: []}))
}
  }, [])

  return (
    <div className="app">
        <NoInternetConnection>
      <PopupContext.Provider value={[popup, setPopup]}>
      <BrowserRouter>
      <ScrollToTop/>
      <Routes>
        <Route path="/auth/:type" element={<AuthPage/>}/>
        <Route path="/tipologiaAccount" element={<AfterAuthPage/>}/>
        <Route path="/creaNegozio" element={<ProtectedRoute><CreateEditStorePageChat/></ProtectedRoute>}/>
        <Route path="/" element={<HomePage/>}/>
        <Route path="/homepage" element={<HomePage/>}/>
        <Route path="/ilMioProfilo" element={<ProtectedRoute><ProfilePage/></ProtectedRoute>}/>
        <Route path="/ilMioProfilo/:section" element={<ProtectedRoute><ProfilePage/></ProtectedRoute>}/>
        <Route path="/ilMioNegozio" element={<StoreProtectedRoute><MyStorePage/></StoreProtectedRoute>}/>
        <Route path="/ilMioNegozio/:section" element={<StoreProtectedRoute><MyStorePage/></StoreProtectedRoute>}/>
        <Route path="/iMieiProdotti" element={<StoreProtectedRoute><ProductsListPage/></StoreProtectedRoute>}/>
        <Route path="/iMieiOrdini" element={<StoreProtectedRoute><OrdersListPage/></StoreProtectedRoute>}/>
        <Route path="/modificaNegozio/:id" element={<StoreProtectedRoute><CreateEditStorePageChat edit={true}/></StoreProtectedRoute>}/>
        <Route path="/negozio/:id" element={<StorePage/>}/>
        <Route path="/carrello" element={<CartPage/>}/>
        <Route path="/aggiungiProdotto" element={<StoreProtectedRoute><CreateEditProductPageChat/></StoreProtectedRoute>}/>
        <Route path="/modificaProdotto/:id" element={<StoreProtectedRoute><CreateEditProductPageChat edit={true}/></StoreProtectedRoute>}/>
        <Route path="/prodotto/:id" element={<ProductPage/>}/>
        <Route path="/chat" element={<ProtectedRoute><ChatPage fullscreen={true}/></ProtectedRoute>}/>
        <Route path="/cerca/:query" element={<SearchPage/>}/>
        <Route path="/404" element={<MessagePage message={"404"}/>} />
        <Route path="/successo" element={<MessagePage message={"successo"}/>} />
        <Route path="/fallimento" element={<MessagePage message={"fallimento"}/>} />
        <Route path="/FAQ" element={<FAQPage/>} />
        <Route path="/GuidaPerNegozi" element={<GuidePage/>} />
        <Route path="/chiSiamo" element={<AboutUsPage/>} />
        <Route path="/verificaEmail" element={<VerifyEmailPage/>} />
        <Route path="/EmailVerificata" element={<VerifiedEmailPage/>} />
        <Route path="/spedizioni" element={<DeliveryPage/>} />
        <Route path="/contatti" element={<ContactPage/>} />
        <Route path="/negozi" element={<StoresPage/>} />
        <Route path="/ordineCompletato" element={<OrderFullfilledPage/>} />
        <Route path="/reso/:id" element={<OrderReturnPage/>} />
        <Route path="/iscrizione" element={<SubscriptionPage/>} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
      </BrowserRouter>
      {popup.type!=null && <Popup message={popup.message} type={popup.type}/>}
      </PopupContext.Provider>
      </NoInternetConnection>
    </div>
  );
}

export default App;
