import { useState } from "react";
import styles from "./Tooltip.module.css";


const Tooltip = ({text}) => {
  const [show, setShow] = useState(false);


  return (
    <div  className={styles.container}>
  

  <div className={styles.icon} onClick={()=>setShow(!show)}>?</div>
  {show &&
  <div className={styles.content}>{text}</div>}
    

    </div>
  );
};

export default Tooltip;
