import styles from "./Loader.module.css";


const Loader = ({fullScreen=false, isText=false}) => {
  return (
    <div  className={!fullScreen ? styles.container : styles.fullScreenContainer}>
    {/*<div className={styles.loader}><span></span></div>*/}
    {}
    <div className={` ${styles.ldsEllipsis} ${isText ? styles.text: ""}`}><div></div><div></div><div></div><div></div></div> 
    </div>
  );
};

export default Loader;
