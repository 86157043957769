import { useContext, useEffect, useState } from "react";
import { ErrorMessage, Field, Formik } from "formik";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLogin, setCartNumber } from "state";
import Dropzone from "react-dropzone";
import styles from "./AuthPage.module.css";
import { PopupContext } from "App";
import Loader from "components/Loader";
import { serverHost } from "helpers/usefulData";
import imageCompression from "browser-image-compression";
const registerSchema = yup.object().shape({
  firstName: yup.string().required("Richiesto"),
  lastName: yup.string().required("Richiesto"),
  email: yup.string().email("Email invalida").required("Richiesta"),
  password: yup.string().required("Richiesto"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Le password devono combaciare"),
  picture: yup.string(),
  phone: yup.string(),
});
const editSchema = yup.object().shape({
  firstName: yup.string().required("Richiesto"),
  lastName: yup.string().required("Richiesto"),
  email: yup.string().email("Email invalida").required("Richiesto"),
  picture: yup.string(),
  phone: yup.string(),
});

const loginSchema = yup.object().shape({
  email: yup.string().email("Email invalida").required("Richiesto"),
  password: yup.string().required("Richiesto"),
});

var initialValuesLogin = {
  email: "",
  password: "",
};

const Form = () => {
  const [images, setImages] = useState([]);
  const [imagePreview, setImagePreview] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);
  const [oldImages, setOldImages] = useState(
    user?.picturePath ? user?.picturePath : ""
  );
  let { type } = useParams();

  var initialValuesRegister = {
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    password: "",
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogin = type === "login";
  const isRegister = type === "register";
  const isEdit = type === "edit";

  const [popup, setPopup] = useContext(PopupContext);

  useEffect(() => {
    // create the preview
    if (images == [] || images[0] == null) return;
    console.log(images[0]);
    const objectUrl = URL.createObjectURL(images[0]);
    setImagePreview(objectUrl);
    // setPreview(objectUrl)
    console.log(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [images]);

  const addToCart = async (prod, token) => {
    const savedStoreResponse = await fetch(serverHost + "carts/addProduct", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(prod),
    });
    const savedStore = await savedStoreResponse.json();
  };

  async function processImages(formData) {
    for await (const image of images) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: false,
      };
      const compressedFile = await imageCompression(image, options);
      formData.append("picturePath", compressedFile);
    }
    return formData;
  }

  const edit = async (values, onSubmitProps) => {
    // this allows us to send form info with image
    setIsLoading(true);
    var formData = new FormData();
    for (let value in values) {
      console.log(values[value]);
      formData.append(value, values[value]);
    }
    console.log(formData);
    if (images) formData = await processImages(formData);
    formData.append("oldImages", oldImages);
    const savedUserResponse = await fetch(serverHost + "auth/editUser", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    setIsLoading(false);
    if (savedUserResponse.ok) {
      const editUser = await savedUserResponse.json();
      onSubmitProps.resetForm();
      console.log("editUser", editUser);

      if (editUser) {
        dispatch(
          setLogin({
            user: editUser.user,
            token: editUser.token,
          })
        );
        navigate("/");
      }
    } else {
      setPopup({
        type: "error",
        message: "Accesso non eseguito. I dati inseriti non sono corretti",
      });
    }
  };

  const register = async (values, onSubmitProps) => {
    // this allows us to send form info with image
    setIsLoading(true);
    var formData = new FormData();
    for (let value in values) {
      console.log(values[value]);
      formData.append(value, values[value]);
    }
    console.log(formData);
    formData = await processImages(formData);
    const savedUserResponse = await fetch(serverHost + "auth/registerUser", {
      method: "POST",
      body: formData,
    });
    setIsLoading(false);
    const savedUser = await savedUserResponse.json();
    onSubmitProps.resetForm();

    if (savedUser) {
      setPopup({ type: "success", message: "Account creato con successo" });
        dispatch(
          setLogin({
            user: savedUser.user,
            token: savedUser.token,
          })
        );
        const localCart = JSON.parse(localStorage.getItem("cart"));
        if (localCart?.products) {
          for (const prod of localCart.products) {
            console.log("prod", prod);
            await addToCart(prod, savedUser.token);
          }
          localStorage.setItem("cart", JSON.stringify({ products: [] }));
        }

        navigate("/tipologiaAccount");
      
    } else {
      setPopup({
        type: "error",
        message: "Account NON creato, per favore riprova",
      });
    }
    navigate("/tipologiaAccount");

  };

  const login = async (values, onSubmitProps) => {
    setIsLoading(true);
    const loggedInResponse = await fetch(serverHost + "auth/login", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    });
    setIsLoading(false);
    if (loggedInResponse.ok) {
      const loggedIn = await loggedInResponse.json();
      onSubmitProps.resetForm();
      console.log("loggedIn", loggedIn);

      if (loggedIn) {
        dispatch(
          setLogin({
            user: loggedIn.user,
            token: loggedIn.token,
          })
        );
        const localCart = JSON.parse(localStorage.getItem("cart"));
        if (localCart?.products) {
          for (const prod of localCart.products) {
            console.log("prod", prod);
            await addToCart(prod, loggedIn.token);
          }
          localStorage.setItem("cart", JSON.stringify({ products: [] }));
        }

        navigate("/");
      }
    } else {

      const errorResponse = await loggedInResponse.json();
      const errorMessages = {
        500: "Errore del server. Si prega di riprovare più tardi o contattare il supporto.",
        422: "Errore di convalida. Si prega di controllare i dati inseriti.",
        404: "Risorsa non trovata. Controlla l'URL o torna alla homepage.",
        409: "Conflitto nella richiesta. Si prega di controllare i dati e riprovare.",
        400: "Richiesta non valida. Assicurati di fornire tutti i dati necessari.",
      };
      const errorMessage = errorMessages[loggedInResponse.status] || "Errore. Si prega di riprovare più tardi.";
      setPopup({ type: "error", message: errorMessage });
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    console.log("formSubmit");
    if (isLogin) {
      await login(values, onSubmitProps);
    } else if (isRegister) {
      await register(values, onSubmitProps);
    } else if (isEdit) {
      await edit(values, onSubmitProps);
    }
  };

  return (
    <>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={isLogin ? initialValuesLogin : initialValuesRegister}
        validationSchema={
          isLogin
            ? loginSchema
            : isRegister
            ? registerSchema
            : isEdit
            ? editSchema
            : ""
        }
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit} className={styles.form}>
            <div>
              <div className="mainTitle">
                {isLogin
                  ? "Accedi"
                  : isRegister
                  ? "Registrati"
                  : isEdit
                  ? "Modifica Account"
                  : ""}
              </div>

              <div className={styles.avatar}>
                <div className={styles.imageContainer}>
                  <img
                    src="/assets/userBase.png"
                    alt="avatar"
                    className={styles.avatarIcon}
                  />
                  <img
                    src="/assets/userOcchi.png"
                    alt="avatar"
                    className={styles.avatarIcon}
                    style={{
                      transform: `translateX(${
                        values.email.length > 0
                          ? -20 + values.email.length + "px"
                          : "0px"
                      }) translateY(${
                        values.email.length > 0 ? "+20px" : "0px"
                      })`,
                    }}
                  />
                </div>
                <div className={styles.textContainer}>
                  <div>
                    {isLogin
                      ? "Ciao! Che bello rivederti."
                      : isRegister
                      ? "Benvenuto! Che bello vederti qui!"
                      : isEdit
                      ? "Modifichiamo insieme il tuo account!"
                      : ""}
                  </div>
                </div>
              </div>

              <div className={styles.inputContainer}>
                <>
                <Field
                  className={
                    touched.email && errors.email
                      ? `${styles.input} ${styles.error}`
                      : `${styles.input}`
                  }
                  type="email"
                  disabled={isEdit}
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  placeholder="Email"
                  name="email"
                  error={Boolean(touched.email) && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className={styles.errorMessage}
                /></>
                {(isRegister || isEdit) && (
                  <>
                    <>
                      <Field
                           className={
                            touched.firstName && errors.firstName
                              ? `${styles.input} ${styles.error}`
                              : `${styles.input}`
                          }
                        type="text"
                        label="Nome"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.firstName}
                        name="firstName"
                        placeholder="Nome"
                        error={
                          Boolean(touched.firstName) &&
                          Boolean(errors.firstName)
                        }
                        helperText={touched.firstName && errors.firstName}
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className={styles.errorMessage}
                      />
                    </>
                    <>
                      <Field
                     className={
                      touched.lastName && errors.lastName
                        ? `${styles.input} ${styles.error}`
                        : `${styles.input}`
                    }
                        type="text"
                        label="Cognome"
                        placeholder="Cognome"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.lastName}
                        name="lastName"
                        error={
                          Boolean(touched.lastName) && Boolean(errors.lastName)
                        }
                        helperText={touched.lastName && errors.lastName}
                      />{" "}
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className={styles.errorMessage}
                      />
                    </>
                  </>
                )}

                {(isRegister || isLogin) && (
                  <>
                    <Field
                      className={
                        touched.password && errors.password
                          ? `${styles.input} ${styles.error}`
                          : `${styles.input}`
                      }
                      label="Password"
                      type="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      name="password"
                      placeholder="Password"
                      error={
                        Boolean(touched.password) && Boolean(errors.password)
                      }
                      helperText={touched.password && errors.password}
                    />{" "}
                    <ErrorMessage
                      name="password"
                      component="div"
                      className={styles.errorMessage}
                    />
                  </>
                )}
                {isRegister && (
                  <>
                    <Field
                      className={
                        touched.confirmPassword && errors.confirmPassword
                          ? `${styles.input} ${styles.error}`
                          : `${styles.input}`
                      }
                      label="Conferma Password"
                      type="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.confirmPassword}
                      name="confirmPassword"
                      placeholder="Conferma Password"
                      error={
                        Boolean(touched.confirmPassword) &&
                        Boolean(errors.confirmPassword)
                      }
                      helperText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                    />
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className={styles.errorMessage}
                    />
                  </>
                )}

                {(isRegister || isEdit) && (
                  <>
                    <Dropzone
                      acceptedFiles=".jpg,.jpeg,.png"
                      multiple={false}
                      onDrop={(acceptedFiles) => {
                        setImages([]);
                        acceptedFiles.forEach((file) => {
                          setOldImages([]);
                          setImages((prevState) => [...prevState, file]);
                        });
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                          {images?.length + oldImages?.length < 1 ? (
                            <>
                              <input {...getInputProps()} type="file" />
                              <div className={`${styles.newImageContainer}`}>
                                <div className={styles.dropFile}>
                                  <div>
                                    +<br></br>foto
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div>
                              {oldImages?.length > 0 && (
                                <div className={`${styles.newImageContainer}`}>
                                  <img
                                    src={oldImages}
                                    className={`${styles.dropFile} ${styles.newImageContainer}`}
                                  />
                                  <button
                                    type="button"
                                    onClick={() => setOldImages([])}
                                    className={styles.removeOldImage}
                                  >
                                    -
                                  </button>
                                </div>
                              )}

                              {images?.length > 0 && (
                                <div className={`${styles.newImageContainer}`}>
                                  <img
                                    src={imagePreview}
                                    className={`${styles.dropFile} ${styles.newImageContainer}`}
                                    alt=""
                                  />
                                  <button
                                    type="button"
                                    onClick={() => setImages([])}
                                    className={styles.removeOldImage}
                                  >
                                    -
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </Dropzone>
                  </>
                )}
              </div>

              <div>
                <button className={`mainButtonGreen ${styles.actionButton}`} type="submit">
                  {isLoading ? (
                    <Loader fullScreen={false} isText={true} />
                  ) : (
                    <>
                      {isLogin
                        ? "Accedi"
                        : isRegister
                        ? "Registrati"
                        : isEdit
                        ? "Modifica"
                        : ""}
                    </>
                  )}
                </button>
                {(isLogin || isRegister) && (
                  <div
                    className={styles.changeType}
                    onClick={() => {
                      isLogin && navigate("/auth/register");
                      isRegister && navigate("/auth/login");
                      resetForm();
                    }}
                  >
                    {isLogin
                      ? "Non hai un account? Registrati."
                      : "Hai già un account? Accedi."}
                  </div>
                )}
                {isRegister && (
                  <div className={styles.agreement}>
                    Cliccando su "Registrati" acconsenti ai{" "}
                    <a href="/terminiECondizioni">termini e condizioni</a> di
                    Tralevie
                  </div>
                )}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default Form;
