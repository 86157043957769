import styles from "./CarouselWrapper.module.css";
import { useEffect, useRef, useState } from "react";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";

const CarouselWrapper = ({ children, title = "" }) => {
  const elementRef = useRef(null);
  const BGRef = useRef(null);
  const totElementRef = useRef(null);

  const [arrowDisable, setArrowDisable] = useState(true);
  const [arrowDisableRight, setArrowDisableRight] = useState(false);

  const [viewButtons, setViewButtons] = useState(false);
  const [showArrows, setShowArrows] = useState();
  const [bgColor, setBgColor] = useState(null);
  const [scrollPercentage, setScrollPercentage] = useState(10);

  useEffect(() => {
    if (elementRef.current.scrollWidth > totElementRef.current.clientWidth)
      setViewButtons(true);
  }, []);

  useEffect(() => {
    if (elementRef.current.scrollWidth > totElementRef.current.clientWidth)
      setViewButtons(true);
  }, [children]);

  useEffect(() => {
    const computedStyle = window.getComputedStyle(elementRef.current);
    let parent = elementRef.current.parentElement;
    let inheritedBackgroundColor = null;

    while (parent) {
      const computedStyle = window.getComputedStyle(parent);
      const backgroundColor = computedStyle.backgroundColor;

      if (backgroundColor && backgroundColor !== "rgba(0, 0, 0, 0)") {
        inheritedBackgroundColor = backgroundColor;
        break;
      }

      parent = parent.parentElement;
    }

    setBgColor(inheritedBackgroundColor);
  }, []);
  const handleScroll = (event) => {
    const { scrollLeft, scrollWidth, clientWidth } = event.target;
    var percentage = (scrollLeft / (scrollWidth - clientWidth)) * 100;
    if (percentage == 0) percentage = 10;
    setScrollPercentage(percentage);
  };
  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
      if (
        element.scrollLeft + element.clientWidth + 10 >=
        element.scrollWidth
      ) {
        setArrowDisableRight(true);
      } else {
        setArrowDisableRight(false);
      }
    }, speed);
  };
  return (
    <div className={styles.totContainer} ref={totElementRef}>
      {title && <div className="secondaryTitle">{title}</div>}

      <div className={styles.container} ref={BGRef}>
        {viewButtons && window.innerHeight > 800 && (
          <button
            className={styles.buttonArrow}
            onClick={() => {
              handleHorizantalScroll(
                elementRef.current,
                2,
                window.innerWidth * 0.5,
                -10
              );
            }}
            disabled={arrowDisable}
          >
            <IconChevronLeft className={styles.arrow}></IconChevronLeft>
          </button>
        )}
        <div
          className={`${styles.carousel} ${!viewButtons && styles.padding}`}
          ref={elementRef}
          onScroll={handleScroll}
        >
          {children != undefined && children != null && children && children?.length !=0? (
            children?.map((child, index) => <div key={index}>{child}</div>)
          ) : (
            <>
              {children?.length ==0 ? (
                <div className={styles.voidList}>nessun elemento</div>
              ) : (
                Array.from({ length: 10 }).map((_, index) => (
                  <div key={index} className="loadingSkeleton"></div>
                ))
              )}
            </>
          )}
        </div>
        {viewButtons && !arrowDisableRight && (
          <div
            className={styles.fadeRight}
            style={{
              background: `linear-gradient(to right, rgba(255, 255, 255, 0), ${bgColor} 50%)`,
            }}
          ></div>
        )}

        {viewButtons && window.innerHeight > 800 && (
          <button
            className={styles.buttonArrow}
            onClick={() => {
              handleHorizantalScroll(
                elementRef.current,
                2,
                window.innerWidth * 0.5,
                10
              );
            }}
            disabled={arrowDisableRight}
          >
            <IconChevronRight className={styles.arrow}></IconChevronRight>
          </button>
        )}
      </div>
      {viewButtons && (
        <div
          className={styles.bar}
          style={{
            backgroundColor: `${bgColor})`,
          }}
        >
          <div
            className={styles.fill}
            style={{ width: `${scrollPercentage}%` }}
          ></div>
        </div>
      )}
    </div>
  );
};

export default CarouselWrapper;
