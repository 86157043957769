import styles from "./Navbar.module.css";
import { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setCartNumber, setLogout } from "state";
import { useNavigate } from "react-router-dom";
import SearchBar from "./SearchBar";
import ChatPage from "scenes/chatPage";
import { serverHost } from "helpers/usefulData";
import CookieBanner from "./CookieBanner";
import {
  IconBuildingStore,
  IconChevronDownRight,
  IconChevronRight,
  IconCoins,
  IconMenu2,
  IconMessage,
  IconReceipt,
  IconShoppingCart,
  IconStar,
  IconUser,
  IconUserCircle,
  IconX,
} from "@tabler/icons-react";
import { IconPackage } from "@tabler/icons-react";
const Navbar = () => {
  const isNonMobileScreens = useMediaQuery("(min-width: 800px)");
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const cartNumber = useSelector((state) => state.cartNumber);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const [showBanner, setShowBanner] = useState(
    localStorage.getItem('showBanner') !== 'false'
  );

  // Update local storage when showBanner state changes
  useEffect(() => {
    localStorage.setItem('showBanner', showBanner);
  }, [showBanner]);
  
  useEffect(() => {
    getCart();
  }, []);

  const getCart = async () => {
    if (token != null) {
      const response = await fetch(serverHost + `carts/`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      var number = 0;
      data.products.map((product) => (number += parseInt(product.quantity)));
      dispatch(
        setCartNumber({
          cartNumber: number,
        })
      );
    } else {
      const localCart = JSON.parse(localStorage.getItem("cart"));
      var number = 0;
      localCart &&
        localCart?.products?.map(
          (product) => (number += parseInt(product.quantity))
        );
      dispatch(
        setCartNumber({
          cartNumber: number,
        })
      );
    }
  };
  const handleClick = () => {
    navigate("/homepage");
  };

  return (
    <>
      <CookieBanner />
      {showBanner && (
        <div className={styles.banner}>
          <div className={styles.text}>
            Attenzione: il servizio è attualmente in fase di sviluppo, per qualsiasi necessità, problema tecnico o informazione,
            contattare direttamente  <a className={styles.contactLink} onClick={() =>navigate("/contatti")}>il team di Tralevie</a>
          </div>
          <IconX  className={styles.closeBanner} onClick={() => setShowBanner(false)}></IconX>
        </div>
      )}
      <div className={styles.container}>
        <div className={styles.topnav}>
          <div className={styles.logoContainer}>
          <img
            src="/assets/logo.png"
            alt="Tralevie logo"
            id={styles.logo}
            onClick={handleClick}
          />
          <span className={styles.beta}>Test</span>
          </div>

          {isNonMobileScreens && (
            <div className={styles.searchBarContainer}>
              <SearchBar />
            </div>
          )}

          {(isNonMobileScreens || (!isNonMobileScreens && mobileMenuOpen)) && (
            <>
              {user === null ? (
                <div
                  className={
                    mobileMenuOpen
                      ? styles.topnavIconsMobile
                      : styles.topnavIcons
                  }
                >
                  <button
                    onClick={() => navigate("/carrello")}
                    className={styles.link}
                  >
                    <IconShoppingCart
                      className={styles.svgIcons}
                    ></IconShoppingCart>
                     {!isNonMobileScreens ? "Carrello": ""}
                    <div className={styles.cartNumber}>{cartNumber}</div>
                  </button>
                  <button
                    className={styles.link}
                    onClick={() => navigate("/guidaPerNegozi")}
                  >
                    <IconBuildingStore
                      className={styles.svgIcons}
                    ></IconBuildingStore>
                    Vendi Con Noi
                  </button>

                  <button
                    className={`mainButtonGreen ${styles.loginButton}`}
                    onClick={() => navigate("/auth/login")}
                  >
                    Accedi o Registrati
                  </button>
                </div>
              ) : (
                <div
                  className={
                    mobileMenuOpen
                      ? styles.topnavIconsMobile
                      : styles.topnavIcons
                  }
                >
                  <button
                    onClick={() =>{navigate("/carrello"); setMobileMenuOpen(false)}}
                    className={styles.link}
                  >
                    <IconShoppingCart
                      className={styles.svgIcons}
                    ></IconShoppingCart>
                    {!isNonMobileScreens ? "Carrello": ""}
                    <div className={styles.cartNumber}>{cartNumber}</div>
                  </button>

                  {user?.isOwner && (<>
                  <div                      
                  id={!isNonMobileScreens ?styles.storeDropdownMobile : styles.storeDropdown}>

                    <button
                      className={styles.link}
                      onClick={() => {
                        navigate("/ilMioNegozio");
                    
                   setMobileMenuOpen(false)
                      }}


                    >
                      <IconBuildingStore
                        className={styles.svgIcons}
                      ></IconBuildingStore>
                      Negozio {isNonMobileScreens && <IconChevronRight></IconChevronRight>}
                    </button>
            <div className={styles.dropdownContent}>
            <div className={styles.dropdownLink} onClick={()=>{navigate("/ilMioNegozio/dashboard"); setMobileMenuOpen(false)}}><IconBuildingStore></IconBuildingStore> Negozio</div>
        <div className={styles.dropdownLink} onClick={()=>{ navigate("/ilMioNegozio/prodotti"); setMobileMenuOpen(false)}}><IconPackage></IconPackage> Prodotti</div>
        <div className={styles.dropdownLink} onClick={()=>{ navigate("/ilMioNegozio/ordini"); setMobileMenuOpen(false)}}><IconReceipt></IconReceipt> Ordini</div>
        <div className={styles.dropdownLink} onClick={()=>{ navigate("/ilMioNegozio/abbonamento"); setMobileMenuOpen(false)}}><IconCoins></IconCoins> Abbonamento</div>
      
            </div>
            </div>

                  </>)}
                  <div                      
                  id={!isNonMobileScreens ?styles.storeDropdownMobile : styles.storeDropdown}>

                  <button
                    className={styles.link}
                    onClick={() => {navigate("/ilMioProfilo"); setMobileMenuOpen(false)}}
                  >
                    <img src={
                  user?.picturePath ? user?.picturePath.replace("upload/", "upload/q_10/") : "/assets/user.png"
                  } className={styles.userImage} alt="Tralevie user icon" />
                    {user?.firstName} {isNonMobileScreens && <IconChevronRight></IconChevronRight>}
                  </button>
                  <div className={styles.dropdownContent}>
        <div className={styles.dropdownLink} onClick={()=>{navigate("/ilMioProfilo/dashboard"); setMobileMenuOpen(false)}}><IconUser></IconUser> Profilo</div>
      <div className={styles.dropdownLink} onClick={()=>{ navigate("/ilMioProfilo/carrello"); setMobileMenuOpen(false)}}><IconShoppingCart></IconShoppingCart> Carrello</div>
      <div className={styles.dropdownLink} onClick={()=>{ navigate("/ilMioProfilo/recensioni"); setMobileMenuOpen(false)}}><IconStar></IconStar> Recensioni</div>
      <div className={styles.dropdownLink} onClick={()=>{ navigate("/ilMioProfilo/ordini"); setMobileMenuOpen(false)}}><IconReceipt></IconReceipt> Ordini</div>
      
            </div>
                  </div>
                </div>

              )}
            </>
          )}
          {!isNonMobileScreens && (
            <button
              className={styles.hamburger}
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              {/*!mobileMenuOpen ? <IconMenu2></IconMenu2> : <IconX></IconX>*/}
              {!mobileMenuOpen ? "☰" : "x"}
            </button>
          )}
        </div>
        {!isNonMobileScreens && (
          <div className={styles.searchBarContainerMob}>
            <SearchBar />
          </div>
        )}

        {/* <WaveSeparator />*/}
      </div>
      {user && <ChatPage />}
    </>
  );
};

export default Navbar;
