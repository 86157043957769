import styles from "./productsListPage.module.css";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {  useSelector } from "react-redux";
import { PopupContext } from "App";
import StoreProduct from "components/StoreProduct";
import Fuse from 'fuse.js';
import { serverHost } from "helpers/usefulData";
const ProductsListPage = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const [store, setStore] = useState(null);
  const [products, setProducts] = useState(null);
  const [newestFirst, setNewestFirst] = useState(true);
  const [popup, setPopup] = useContext(PopupContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const options = {
    keys: ['name'],
    isCaseSensitive: false,
    shouldSort: true,
    threshold: 0.3,
  };
  
  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);

    if (!value) {
      setSearchResults(products);
      return;
    }
    const fuse = new Fuse(products, options);
    const results = fuse.search(value);
    console.log(results)

    setSearchResults(results.map((result) => result.item));
  };
  function sort() {
    if (!products || products?.length==1) return
    console.log("I'm here", newestFirst)
    // Sort the array in ascending order based on the "createdAt" field
    var tempProd= products
    tempProd.sort((a, b) => {
      // Convert createdAt values to Date objects for comparison
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
  
      if (newestFirst) {
        return dateA - dateB; // Sort in increasing order
      } else {
        return dateB - dateA; // Sort in decreasing order
      }    
    });
    console.log(tempProd)
    setNewestFirst(!newestFirst)
  setProducts(tempProd)
  
  }
  const user = useSelector((state) => state.user);
  useEffect(() => {
    document.title = "I Miei Prodotti - Tralevie";
  }, []);
  useEffect(() => {
    getStore();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    getProducts();
  }, [store]);
  const getStore = async () => {
    const response = await fetch(serverHost+ `stores/myStore`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    setStore(data);
  };
  const deleteProduct = async (id) => {
    const response = await fetch(
      serverHost+ `products/deleteProduct/` + id,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    if (response.ok) {
      console.log("dleeted success");
      setPopup({
        type: "success",
        message: "Prodotto eliminato con successo",
      });
      const newList = products.filter((item) => item._id !== id);
      setProducts(newList);
    }
  };

  const getProducts = async () => {
    var link = serverHost+ "products/store/" + store?._id;
    const response = await fetch(link, {
      method: "GET",
    });
    const data = await response.json();
    setProducts(data);
    setSearchResults(data)

  };
  return (
    <>
      <Navbar />
      <ul className="breadCrumb">
        <li className="breadCrumbElement">
          <a href="/">Homepage</a>
        </li>{" "}
        {">"}
        <li className="breadCrumbElement">
          <a href={"/IMieiProdotti/"}>I Miei prodotti</a>
        </li>
      </ul>
      <div className="mainTitle">I tuoi prodotti</div>
      {/*<div onClick={()=>sort()}>
        ordina per data crescente
  </div>*/}

      <div className={styles.carousel}>
        <div className={styles.row}>
                <input
        type="text"
        placeholder="cerca tra i prodotti..."
        value={searchTerm}
        onChange={handleSearch}
        className={styles.searchFilter}
      />
        <button
          className="mainButtonGreen"
          onClick={() => navigate("/aggiungiProdotto")}
        >
          + aggiungi un prodotto
        </button>
        </div>

        {searchResults?.length > 0 && (
          <>
            {searchResults?.map((el) => (
              <StoreProduct
                key={el._id}
                product={el}
                deleteFunction={deleteProduct}
              />
            ))}
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default ProductsListPage;
