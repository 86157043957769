import styles from "./searchPage.module.css";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import GenericDisplay from "components/GenericDisplay";
import Loader from "components/Loader";
import { PopupContext } from "App";
import GenericCarousel from "components/GenericCarousel";
import { serverHost } from "helpers/usefulData";
import { IconCheck, IconMessage2 } from "@tabler/icons-react";
const SearchPage = () => {
  const navigate = useNavigate();
  const [popup, setPopup] = useContext(PopupContext);

  var params = useParams();
  const list =  [
    "Alimentari",
    "Abbigliamento",
    "Elettronica",
    "Casa",
    "Libri",
    "Gioielli",
    "Attrezzature Sportive",
    "Giocattoli",
    "Calzature",
    "Musica",
    "Farmaci",
    "Arte",
    "Hobby",
    "Intrattenimento",
    "Panificati",
    "Motori",
    "Cura Personale",
  ];

  const [products, setProducts] = useState([]);
  const [stores, setStores] = useState([]);
  const [storesGPT, setStoresGPT] = useState([]);
  const [category, setCategory] = useState("");
  const [activeCategory, setActiveCategory] = useState("");

  const [type, setType] = useState("products");

  useEffect(() => {
    document.title = params.query + " - Tralevie";

    getProducts();
    getStores();
    getStoresGPT();
  }, [params]);
  useEffect(() => {
    document.title = params.query + " - Tralevie";

    getProducts();
    getStores();
    getStoresGPT();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getProducts = async () => {
    const response = await fetch(
      serverHost+ "search/products/" + params.query,
      {
        method: "GET",
      }
    );
    if (!response.ok) {
      setPopup({
        type: "error",
        message: "Errore nel server, per cortesia riprovare",
      });
    } else {
      const data = await response.json();

      setProducts(data);
      if (data.length == 0) setType("stores");
    }
  };

  const getStores = async () => {
    const response = await fetch(
      serverHost+ "search/stores/" + params.query,
      {
        method: "GET",
      }
    );
    if (!response.ok) {
      setPopup({
        type: "error",
        message: "Errore nel server, per cortesia riprovare",
      });
    } else {
      const data = await response.json();

      setStores(data);
    }
  };

  const getStoresGPT = async () => {
    try{
    const response = await fetch(
      serverHost+ "search/storesGPT/" + params.query,
      {
        method: "GET",
      }
    );
    if (!response.ok) {
      setPopup({
        type: "error",
        message: "Errore nel server, per cortesia riprovare",
      });
    } else {
      const data = await response.json();
      setStoresGPT(data.results);
      setCategory(data.category);
      console.log(data);
    }

  } catch (err) {
    console.log(err);
  }
  };

  const getStoresTag = async (tag) => {
    const response = await fetch(
      serverHost+ "search/stores/tag/" + tag,
      {
        method: "GET",
      }
    );
    if (!response.ok) {
      setPopup({
        type: "error",
        message: "Errore nel server, per cortesia riprovare",
      });
    } else {
      console.log("hereee")
      const data = await response.json();
      console.log(data);
      setStoresGPT(data);
      setCategory(tag);
    }
  };

  return (
    <>
      <Navbar />
      <ul className="breadCrumb">
        <li className="breadCrumbElement">
          <a href="/">Homepage</a>
        </li>{" "}
        {">"}
        <li className="breadCrumbElement">
          <a href={"/cerca/" + params.query}>Cerca: "{params.query}"</a>
        </li>
      </ul>
      <div className={styles.contactReminder}>
         <IconMessage2 className={styles.contactReminderIcon}></IconMessage2>
         <div className={styles.contactReminderText}>Contatta i venditori per scoprire tutti i prodotti o chiedere assistenza! </div>

      </div>
      <div className="mainTitle">risultati per: "{params.query}"</div>
      <div className={styles.titleTag}>
        <div className="secondaryTitle">negozi nella categoria : </div>
        <select
          className={styles.selection}
          value={category}
          onChange={(event) => {
            getStoresTag(event.target.value.toLocaleLowerCase());
          }}
        >
          {list?.map((tag, i) => (
            <option value={tag.toLocaleLowerCase()} key={i}>
              {tag.toLocaleLowerCase()}
            </option>
          ))}
        </select>
      </div>
      {storesGPT == [] ? (
        <Loader />
      ) : (
        <>
         <GenericCarousel type={"store"} collection={storesGPT} title={""} />
        </>
      )}

      <div className={styles.filter}>
        <button
          className={type == "stores" && styles.notSelectedType}
          onClick={() => setType("products")}
        >
                    {type == "products" && <IconCheck></IconCheck>}

          Prodotti
        </button>{" "}
        
        <button
          className={type == "products" && styles.notSelectedType}
          onClick={() => setType("stores")}
        >
          {type == "stores" && <IconCheck></IconCheck>}
          Negozi
        </button>
      </div>
      {type == "stores" ? (
        <>
          {!stores ? (
            <Loader />
          ) : (
            <GenericDisplay
              type={"store"}
              collection={stores}
              title={"negozi (" + stores?.length + ")"}
            />
          )}
        </>
      ) : (
        <>
          {!products ? (
            <Loader />
          ) : (
            <GenericDisplay
              type={"product"}
              collection={products}
              title={"prodotti (" + products?.length + ")"}
            />
          )}
        </>
      )}

      {products?.length == 0 &&
        stores?.length == 0 &&
        storesGPT?.length == 0 && (
          <div className={styles.noResult}>
            <img className={styles.image} src="/assets/no elements.png" alt="" />
            <div className={styles.noResultText}>
              Al momento non ci sono risultati. Prova a contattare direttamente
              i negozi per sentire che cosa offrono!
            </div>
          </div>
        )}
      <Footer />
    </>
  );
};

export default SearchPage;
