import styles from "./Sidebar.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { PopupContext } from "App";
import { serverHost } from "helpers/usefulData";
import { IconCalendarStar, IconCoins, IconMessage, IconPackage, IconShoppingCart, IconStar, IconUser } from '@tabler/icons-react';
import { IconBuildingStore } from '@tabler/icons-react';
import { IconReceipt } from '@tabler/icons-react';
import { IconHelp } from '@tabler/icons-react';
import { IconWallet } from "@tabler/icons-react";

const Sidebar = ({type="store", mobile=false}) => {
  let { section } = useParams();

  const navigate = useNavigate();

  return (
    <>
        <div className={window.innerWidth < 1000? styles.mobileSidebar: styles.sidebar}>
          {type=="store" ?
      <ul>
        <li className={`${styles.link} ${(section=="dashboard" || section==null) && styles.linkSelected}`} onClick={()=>{navigate("/ilMioNegozio/dashboard")}}><IconBuildingStore></IconBuildingStore> Negozio</li>
        <li className={`${styles.link} ${section=="prodotti" && styles.linkSelected}`} onClick={()=>{ navigate("/ilMioNegozio/prodotti")}}><IconPackage></IconPackage> Prodotti</li>
        <li className={`${styles.link} ${section=="ordini" && styles.linkSelected}`} onClick={()=>{ navigate("/ilMioNegozio/ordini")}}><IconReceipt></IconReceipt> Ordini</li>
        <li className={`${styles.link} ${section=="chat" && styles.linkSelected}`} onClick={()=>{navigate("/ilMioNegozio/chat")}}><IconMessage></IconMessage> Messaggi</li>
        <li className={`${styles.link} ${section=="abbonamento" && styles.linkSelected}`} onClick={()=>{ navigate("/ilMioNegozio/abbonamento")}}><IconCoins></IconCoins> Abbonamento</li>
        <li className={`${styles.link} ${section=="aiuto" && styles.linkSelected}`} onClick={()=>{navigate("/ilMioNegozio/aiuto")}}><IconHelp></IconHelp> Aiuto</li>
      </ul>
      :
      <ul>
      <li className={`${styles.link} ${(section=="dashboard" || section==null) && styles.linkSelected}`} onClick={()=>{navigate("/ilMioProfilo/dashboard")}}><IconUser></IconUser> Profilo</li>
      <li className={`${styles.link} ${section=="carrello" && styles.linkSelected}`} onClick={()=>{ navigate("/ilMioProfilo/carrello")}}><IconShoppingCart></IconShoppingCart> Carrello</li>
      <li className={`${styles.link} ${section=="recensioni" && styles.linkSelected}`} onClick={()=>{ navigate("/ilMioProfilo/recensioni")}}><IconStar></IconStar> Recensioni</li>
      <li className={`${styles.link} ${section=="ordini" && styles.linkSelected}`} onClick={()=>{ navigate("/ilMioProfilo/ordini")}}><IconReceipt></IconReceipt> Ordini</li>
      <li className={`${styles.link} ${section=="chat" && styles.linkSelected}`} onClick={()=>{navigate("/ilMioProfilo/chat")}}><IconMessage></IconMessage> Messaggi</li>
      <li className={`${styles.link} ${section=="aiuto" && styles.linkSelected}`} onClick={()=>{navigate("/ilMioProfilo/aiuto")}}><IconHelp></IconHelp> Aiuto</li>
    </ul>
      }
    </div>
    </>
  );
};

export default Sidebar;