import styles from "./ProductCard.module.css";
import { useNavigate } from "react-router-dom";
import PriceTag from "./PriceTag";
import { useState } from "react";
import { IconStarFilled } from "@tabler/icons-react";

const ProductCard = (props) => {
  const [isHover, setIsHover] = useState(false);
  const product = props.product;
  const navigate = useNavigate();
  if (!product) return null;
  return (
    <>
      <div
        className={styles.card}
        onClick={() => {
          navigate("/prodotto/" + product._id);
        }}
      >
        {product.inventory == 0 && (
          <div className={styles.banner}>Non disponibile</div>
        )}
        {product?.picture != null &&
        typeof product?.picture != undefined &&
        product?.picture.length != 0 ? (
          <img
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            src={
              !isHover || !product?.picture[1]
                ? product?.picture[0].replace("upload/", "upload/q_50/")
                : product?.picture[1].replace("upload/", "upload/q_50/")
            }
            alt=""
            className={styles.icon}
          />
        ) : (
          <img src="/assets/product.png" alt="" className={styles.noIcon} />
        )}
        <div className={styles.textDataContainer}>
          {/* <RatingStars rating={product.rating} />
          {product.shippingCost==0 && <div className={styles.badge}>Spedizione Gratuita</div>}*/}
          <div className={styles.priceSection}>
            <PriceTag price={product.price} />
          </div>
          <div
            className={`${styles.star} ${
              product.rating == 0 && styles.disabled
            } `}
          >
            <IconStarFilled></IconStarFilled> {product.rating}
          </div>
          <div className={styles.name}>{product.name}</div>
        </div>
      </div>
    </>
  );
};

export default ProductCard;
