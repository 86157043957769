import styles from "./productPage.module.css";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PopupContext } from "App";
import ReviewsSection from "components/ReviewsSection";
import RatingStars from "components/RatingStars";
import ImageDisplay from "components/ImageDisplay";
import Badge from "components/Badge";
import Loader from "components/Loader";
import { setCartNumber } from "state";
import SendMessage from "components/SendMessage";
import PriceTag from "components/PriceTag";
import GenericCarousel from "components/GenericCarousel";
import { serverHost } from "helpers/usefulData";
import { IconShare } from "@tabler/icons-react";
const ProductPage = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [productId, setProductId] = useState(useParams());
  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);
  const [option, setOption] = useState("");
  const [popup, setPopup] = useContext(PopupContext);
  const [product, setProduct] = useState(null);
  const [storeProducts, setStoreProducts] = useState(null);
  const [store, setStore] = useState(null);
  const [storeOwner, setStoreOwner] = useState(null);
  const [soldOut, setSoldOut] = useState(false);
  const dispatch = useDispatch();
  const cartNumber = useSelector((state) => state.cartNumber);

  const [quantity, setQuantity] = useState(1);
  useEffect(() => {
    getProduct();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    setProductId(id);
    getProduct();
  }, [id]);

  useEffect(() => {
    document.title = product?.name + " - Tralevie";
  }, [product]);

  useEffect(() => {
    getStoreProducts();
    getStoreOwner();
  }, [store]);

  useEffect(() => {
    getStoreOwner();
  }, [storeProducts]);

  const updatehistory = (prod) => {
    const history = JSON.parse(localStorage.getItem("productHistory")) || [];
    
    if (prod) {
      const existingIndex = history.findIndex((item) => item && item._id === prod._id);
      
      if (existingIndex !== -1) {
        // If the product is already in history, replace it with the new product
        history.splice(existingIndex, 1, prod);
      } else {
        if (history.length >= 10) {
          history.shift(); // Remove the oldest product if history length exceeds 10
        }
        history.push(prod); // Add the new product to history
      }
      
      localStorage.setItem("productHistory", JSON.stringify(history));
    }
  };
  
  const getProduct = async () => {
    const response = await fetch(serverHost + `products/${productId?.id}`, {
      method: "GET",
    });
    const data = await response.json();
    setProduct(data.product);
    console.log(data.product);
    updatehistory(data.product);
    setStore(data.store);
    setSoldOut(data.product?.inventory ==0);
    setOption(data.product?.options[0]);
  };

  const getStoreOwner = async () => {

    const response = await fetch(serverHost + `users/${store?.ownerID}`, {
      method: "GET",
    });

    if (!response.ok) {
    } else {
      const data = await response.json();
      setStoreOwner(data.user);
    }
  };
  const getStoreProducts = async () => {
    var link = serverHost + "products/store/" + store?._id;
    const response = await fetch(link, {
      method: "GET",
    });

    if (!response.ok) {
      setPopup({
        type: "error",
        message: "Errore nel server, per cortesia riprovare",
      });
    } else {
      var data = await response.json();
      var res = data.filter((prod) => prod._id !== product?._id);
      setStoreProducts(res);
    }
  };

  const addToCart = async () => {
    const values = {
      productID: product?._id,
      quantity: quantity,
      option: option,
      price: product.price,
      productName: product.name,
    };

    if (user) {
      const savedStoreResponse = await fetch(serverHost + "carts/addProduct", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
      });
      const savedStore = await savedStoreResponse.json();
    } else {
      var localCart = JSON.parse(localStorage.getItem("cart"));

      var tempArray = localCart.products;
      const index = tempArray.findIndex((el) => {
        return el.productID == values.productID && el.option == values.option;
      });
      if (index != -1) {
        tempArray[index].quantity += parseInt(values.quantity);
      } else {
        tempArray.push(values);
      }
      localCart.products = tempArray;
      localStorage.setItem("cart", JSON.stringify(localCart));
    }

    dispatch(
      setCartNumber({
        cartNumber: cartNumber + parseInt(quantity),
      })
    );
    setPopup({ type: "success", message: "prodotto aggiunto al carrello!" });
  };
  const shareProduct = () => {
    if (navigator.share) {
      navigator.clipboard.writeText(
        "Guarda questo prodotto su Tralevie! " +
          product?.name +
          "Tralevie.it/prodotto/" +
          product?._id
      );
      navigator
        .share({
          title: product?.name,
          url: "Tralevie.it/prodotto/" + product?._id,
        })
        .then(() => console.log("Product shared successfully"))
        .catch((error) => console.error("Error sharing product:", error));
    } else {
      // You can implement a fallback behavior here, like showing a copy link option.
    }
  };

 return (
    <>
      <Navbar />
      <ul className="breadCrumb">
        <li className="breadCrumbElement">
          <a href="/">Homepage</a>
        </li>{" "}
        {">"}
        <li className="breadCrumbElement">
          <a href={"/prodotto/" + product?._id}>"{product?.name.length > 10 ? product?.name.slice(0, 20) + '...' : product?.name}"</a>
        </li>
      </ul>
      {!product ? (
        <Loader fullScreen={true}/>
      ) : (
        <>
          <div className={styles.container}>
            <div className={styles.productRow}>
              <ImageDisplay collection={product?.picture} />

              <div className={styles.dataContainer}>
                <div className={styles.firstRow}>
                  <RatingStars rating={product.rating ? product.rating : 0} />

                  <div className={styles.rightButtons}>
                    <button onClick={shareProduct} className="greenLink">
                      <IconShare className={styles.shareIcon}></IconShare>
                    </button>
                    {store.ownerID == user?._id && (
                      <button
                        className={`${styles.edit} mainButtonGreen`}
                        onClick={() =>
                          navigate(`/modificaProdotto/${product?._id}`)
                        }
                      >
                        Modifica
                      </button>
                    )}
                  </div>
                </div>
                <div className={styles.name}>{product.name}</div>
                <div className={styles.chatDescriptionContainer}>
                  <Badge
                    image={store?.picture[0]}
                    name={store?.name}
                    path={"/negozio/" + store?._id}
                  />

                  <div className={styles.descriptionContainer}>
                    {product.description
                      ? product.description
                      : "Contatta il venditore per ulteriori informazioni"}
                  </div>
                </div>

                <div className={styles.actionContainer}>
                  {product?.options.length > 0 && product?.options[0] != "" && (
                    <div className={styles.action}>
                      <div className={styles.lable}>Opzioni:</div>
                      <div className={styles.optionContainer}>
                        {product?.options.map((opt) => (
                          <button
                            key={opt}
                            className={styles.optionButton}
                            style={
                              option == opt
                                ? {
                                    color: "white",
                                    backgroundColor: "var(--main)",
                                    scale: "1.1",
                                  }
                                : {}
                            }
                            onClick={() => setOption(opt)}
                          >
                            {opt}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                  {!soldOut && (
                    <>
                    <div>

                        <div className={styles.lable}>Quantità {product.inventory>0 ? `(${product.inventory} disponibili)`: ""}:</div>
                        <div className={styles.row}>

                        <button
                          className={styles.quantityButton}
                          onClick={() => setQuantity(Math.max(quantity - 1, 1))}
                          disabled={quantity == 1}
                        >
                          -
                        </button>

                        <input
                          type="number"
                          min="1"
                          max={product?.inventory<0? 9999: product?.inventory}
                          value={quantity}
                          className={styles.quantity}
                          onChange={(e) =>{
                            product?.inventory!=0 && e.target.value <= product?.inventory && setQuantity(e.target.value)}}
                        />
                        <button
                          className={styles.quantityButton}
                          disabled={ product?.inventory>0 && quantity == product?.inventory}
                          onClick={() =>
                            setQuantity(
                              quantity + 1
                            )
                          }
                        >
                          +
                        </button>
                      </div>
                      </div>
                    </>
                  )}

                </div>

                <div className={styles.rowa}>
                <div className={styles.priceSection}>
                    <div className={styles.actualPriceSection}>
                    <PriceTag
                      className={styles.price}
                      price={product.price}
                      big={true}
                    />
                    </div>
           
                    <div className={styles.spedizione}>
                      spedizione:{" "}
                      <PriceTag price={product.shippingCost} big={false} />
                    </div>
                  </div>
                  <button
                    className={`mainButtonGreen ${styles.addToCart}`}
                    onClick={addToCart}
                    disabled={soldOut}
                  >
                    {soldOut ? "Non disponibile" : "Aggiungi al carrello"}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <SendMessage store={store} storeOwner={storeOwner} />
          {storeProducts?.length > 0 && (
            <GenericCarousel
              collection={storeProducts}
              type={"product"}
              title={"prodotti dallo stesso negozio"}
              storeID={store?._id}
            />
          )}
          <ReviewsSection productID={product?._id} storeID={store?._id}/>
        </>
      )}
      <Footer />
    </>
  );
};

export default ProductPage;
