import styles from "./OrdersStats.module.css";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  IconCash,
  IconChartBar,
  IconChevronDown,
  IconChevronRight,
  IconId,
  IconPackageExport,
  IconPigMoney,
  IconReceipt,
  IconReceiptEuro,
  IconReportMoney,
  IconUserHeart,
} from "@tabler/icons-react";
import { serverHost } from "helpers/usefulData";
import ConfirmationModal from "./ConfirmationModal";
import { PopupContext } from "App";
import { IconBuildingBank } from "@tabler/icons-react";

const OrdersStats = ({ orders = [], store }) => {
  const token = useSelector((state) => state.token);
  const [total, setTotal] = useState(0);
  const [totRevenue, setTotRevenue] = useState(0);
  const [totCustomers, setTotCustomers] = useState(0);
  const [totItems, setTotItems] = useState(0);
  const [unfullfilledRevenue, setUnfullfilledRevenue] = useState(0);
  const [seeMore, setSeeMore] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [popup, setPopup] = useContext(PopupContext);

  const navigate = useNavigate();

  useEffect(() => {
    setTotal(orders?.length);

    let totalAmount = 0;
    for (let i = 0; i < orders.length; i++) {
      totalAmount += orders[i].totalProductPrice || 0;
    }
    setTotRevenue(totalAmount);

    const uniqueCustomerIDs = [];
    orders.forEach((order) => {
      const customerID = order.clientID;
      if (!uniqueCustomerIDs.includes(customerID)) {
        uniqueCustomerIDs.push(customerID);
      }
    });
    const totalUniqueCustomers = uniqueCustomerIDs.length;
    setTotCustomers(totalUniqueCustomers);

    let totalItemsSold = 0;
    orders.forEach((order) => {
      order.products.forEach((product) => {
        totalItemsSold += product.quantity;
      });
    });

    setTotItems(totalItemsSold);

    let totalConfirmedAmount = 0;

    orders.forEach((order) => {
      if (order.status === "confirmed") {
        totalConfirmedAmount += order.totalPrice;
      }
    });

    setUnfullfilledRevenue(totalConfirmedAmount * 0.93);
  }, [orders]);

  const requestCollection = async () => {
    var link = serverHost + `orders/requestCollection`;
    const response = await fetch(link, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    console.log(data);
    if (!response.ok) {
      setPopup({ type: "error", message: "operazione non riuscita" });
    } else {
      setPopup({
        type: "success",
        message: "Richiesta inoltrata con successo",
      });
    }
    setShowModal(false);
  };

  return (
    <>
      <ConfirmationModal
        showModal={showModal}
        handleClose={() => setShowModal(false)}
        handleConfirm={requestCollection}
        title="Conferma Richiesta di riscossione"
        message={
          "Hia controllato i dati? Un operatore esaudirà la tua richiesta il prima possibile"
        }
      />
      <div className={styles.container}>
        <div className={styles.firstRow}>
          <div className="secondaryTitle">
            <IconChartBar></IconChartBar> Statistiche
          </div>
        </div>
        <div className={styles.dataContainer}>
          <div className={styles.dataContainerSection1}>
            <div className={styles.data}>
              <IconReceipt></IconReceipt>
              <div>
                <div className={styles.dataTitle}>Ordini totali </div>
                <div className={styles.num}>{total}</div>
              </div>
            </div>
            <div className={styles.data}>
              <IconCash></IconCash>
              <div>
                <div className={styles.dataTitle}>Incassi totali</div>
                <div className={styles.num}>{totRevenue} €</div>
              </div>
            </div>
          </div>
          <div className={styles.dataContainerSection2}>
            <div className={styles.data}>
              <IconReceiptEuro></IconReceiptEuro>
              <div>
                <div className={styles.dataTitle}>Incasso medio</div>
                <div className={styles.num}>
                {!isNaN((totRevenue / total).toFixed(2)) ? (totRevenue / total).toFixed(2) + " €" : "-"}
                </div>
              </div>
            </div>
            <div className={styles.data}>
              <IconUserHeart></IconUserHeart>
              <div>
                <div className={styles.dataTitle}>Clienti</div>
                <div className={styles.num}>{totCustomers}</div>
              </div>
            </div>
            <div className={styles.data}>
              <IconPackageExport></IconPackageExport>
              <div>
                <div className={styles.dataTitle}>Prodotti venduti</div>
                <div className={styles.num}>{totItems}</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.creditSection}>
          <div className={styles.data}>
            <IconPigMoney></IconPigMoney>
            <div></div>
            <div className={styles.dataTitle}>Credito</div>
            <div className={styles.num}>{unfullfilledRevenue} €</div>
          </div>

          {seeMore ? (
            <div
              className={`greenLink ${styles.link}`}
              onClick={() => setSeeMore(false)}
            >
              Annulla <IconChevronDown></IconChevronDown>
            </div>
          ) : (
            <div
              className={`greenLink ${styles.link}`}
              onClick={() => setSeeMore(true)}
            >
              Richiedi riscossione credito<IconChevronRight></IconChevronRight>
            </div>
          )}
          {seeMore && (
            <div className={styles.request}>
              <div className={styles.littleTitle}>
                Sposta ora il tuo credito.
              </div>

              <div className={styles.explanation}>
                Come funziona? Un operatore prenderà al più presto la tua
                richiesta e procederà ad emettere un bonifico al codice IBAN da
                Lei specificato
              </div>
              <div className={styles.actionRow}>
                <div className={styles.storeDataContainer}>
                  <div className={styles.iban}>
                    <IconBuildingBank></IconBuildingBank>Codice IBAN:{" "}
                    <span>{store?.IBAN || "ARDTFYGUHIJOPIO"}</span>
                  </div>
                  <div className={styles.iban}>
                    <IconReportMoney></IconReportMoney>Importo:{" "}
                    <span>{unfullfilledRevenue || "0"}€</span>
                  </div>
                </div>

                <button
                  className={`yellowLink ${styles.link}`}
                  onClick={() => navigate("/modificaNegozio/" + store._id)}
                >
                  Modifica dati
                </button>

                <button
                  className="mainButtonGreen"
                  onClick={() => setShowModal(true)}
                >
                  Invia Richiesta <IconChevronRight></IconChevronRight>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OrdersStats;
