import styles from "./OrderCard.module.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Badge from "./Badge";
import ProductBadge from "./ProductBadge";
import FormBar from "./FormBar";
import { serverHost } from "helpers/usefulData";
import {
  IconAddressBook,
  IconCalendarPlus,
  IconClockEdit,
  IconCubeSend,
  IconId,
  IconMapPin,
  IconMathSymbols,
  IconPackage,
} from "@tabler/icons-react";
import ConfirmationModal from "./ConfirmationModal";
const OrderCard = ({ order, type }) => {
  const navigate = useNavigate();
  //var storeId = useParams();
  const [user, setUser] = useState(null);
  const [store, setStore] = useState(null);
  const [products, setProducts] = useState([]);
  const [orderState, setOrderState] = useState(order);
  const [status, setStatus] = useState("");
  const [date, setDate] = useState(new Date(order.updatedAt));
  const [prodCost, setProdCost] = useState(0);
  const statuses = ["creato", "confermato", "spedito", "completato"];
  const userAccount = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const [showModal, setShowModal] = useState(false);


  useEffect(() => {
    //getOrder();
    getClient();
    getProducts();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getProducts = async () => {
    try {
      const updatedProducts = [];
      let totalProdCost = 0;
  
      for await (const product of orderState.products) {
        const link = serverHost + "products/" + product.productID;
        const response = await fetch(link, {
          method: "GET",
        });
  
        if (!response.ok) {
          throw new Error("Failed to fetch product data");
        }
  
        const data = await response.json();
        product._id = data.product._id;
        product.name = data.product.name;
        product.picture = data.product.picture;
        totalProdCost += product.price;
        updatedProducts.push(product);
        setStore(data.store);
      }
  
      setProdCost(totalProdCost);
      setProducts(updatedProducts);
    } catch (error) {
      console.log(error)
      console.error("Error fetching product data:", error);
      // Handle error here, such as displaying an error message to the user
    }
  };

  const changeStatusOrder = async () => {
   if (status=="") return
    var link =
      serverHost + `orders/changeStatusOrder/` + order._id + "/" + status;
    const response = await fetch(link, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    orderState.status = status;
    setShowModal(false)
  };

  const getClient = async () => {
    const response = await fetch(serverHost + `users/${order.clientID}`, {
      method: "GET",
    });
    const data = await response.json();
    setUser(data.user);
  };

  return (
    <>
      <ConfirmationModal
        showModal={showModal}
        handleClose={() => setShowModal(false)}
        handleConfirm={changeStatusOrder}
        title="Conferma Aggiornamento stato dell'ordine"
        message={
          "Sei Sicuro di voler aggiornare lo stato dell'ordine a '"+status+"'?"
        }
        positive={true}
      />
      <div className={styles.container}>
        <FormBar
          phases={["Da confermare", "Da Spedire", "In consegna", "Completato"]}
          step={statuses.indexOf(order.status)}
        />

        <div className={styles.row}>
          {type == "client" ? (
            <div className={styles.player}>
              <Badge
                name={store?.name}
                path={store?._id}
                image={store?.picture[0].replace("upload/", "upload/q_50/")}
              />{" "}
            </div>
          ) : (
            <div className={styles.player}>
              <Badge
                name={user?.firstName + " " + user?.lastName}
                image={user?.picturePath.replace("upload/", "upload/q_50/")}
              />
            </div>
          )}
          {type == "store" && (
            <div className={styles.actionList}>
              {order.status == "creato" && (
                <button
                  className="mainButtonGreen"
                  onClick={() => {
                    setStatus("confermato");
                    setShowModal(true)
                  }}
                >
                  conferma
                </button>
              )}
              {order.status == "creato" && (
                <button
                  className="redLink"
                  onClick={() => {
                    setStatus("annullato");
                    setShowModal(true)
                  }}
                >
                  cancella
                </button>
              )}

              {order.status == "confermato" && (
                <button
                  className="mainButtonGreen"
                  onClick={() => {
                    setStatus("spedito");
                    setShowModal(true)

                  }}
                >
                  spedito
                </button>
              )}

              {order.status == "spedito" && (
                <div>
                  In attesa che il cliente confermi la ricezione dell'ordine
                </div>
              )}
            </div>
          )}
          {type == "client" && (
            <div className={styles.actionList}>
              {order.status == "creato" && (
                <button
                  className="redLink"
                  onClick={() => {
                    setStatus("annullato");
                    setShowModal(true)

                  }}
                >
                  Annulla
                </button>
              )}
              {order.status == "spedito" && (
                <button
                  className="mainButtonGreen"
                  onClick={() => {
                    setStatus("completato");
                    setShowModal(true)

                  }}
                >
                  conferma ricezione
                </button>
              )}
              {order.status == "completato" && (
                <button
                  className="mainButtonRed"
                  onClick={() => {
                    navigate(`/reso/${order._id.toString()}`);
                    console.log(`/reso/${order._id.toString()}`);
                  }}
                >
                  Richiedi il Reso
                </button>
              )}
            </div>
          )}{" "}
        </div>
        <div className={styles.mainRow}>
          <div className={styles.dataContainer}>
            <div className={styles.dataRow}>
              <div className={styles.lable}>
                {" "}
                <IconId></IconId> ID:
              </div>{" "}
              {order._id}
            </div>
            <div className={styles.dataRow}>
              <div className={styles.lable}>
                <IconCalendarPlus></IconCalendarPlus> Creazione:
              </div>{" "}
              {new Date(order.createdAt).toLocaleString()}
            </div>
            <div className={styles.dataRow}>
              <div className={styles.lable}>
                <IconClockEdit></IconClockEdit>
                Ultimo aggiornamento:
              </div>{" "}
              {date.toLocaleString()}
            </div>
            <div className={styles.dataRow}>
              <div className={styles.lable}>
                <IconMapPin></IconMapPin> Indirizzo:
              </div>{" "}
              {order.address}
            </div>
            <div className={styles.dataRow}>
              <div className={styles.lable}>
                <IconAddressBook></IconAddressBook> Contatti:
              </div>
              { user?.email}
            </div>
          </div>
          <div className={styles.productContainer}>
            <div className={styles.productList}>
              {order.products?.map((prod) => (
                <ProductBadge key={prod._id} product={prod} />
              ))}
            </div>
          </div>
        </div>
        <div className={styles.bill}>
          <div className={styles.dataRow}>
            <span className={styles.lable}>
              <IconCubeSend></IconCubeSend> Spedizione:
            </span>{" "}
            {order.shippingPrice.toFixed(2)}€
          </div>
          <div className={styles.dataRow}>
            <span className={styles.lable}>
              <IconPackage></IconPackage> Prodotti:{" "}
            </span>{" "}
            {order.totalProductPrice.toFixed(2)}€
          </div>
          <div className={styles.dataRow}>
            <span className={styles.lable}>
              <IconMathSymbols></IconMathSymbols> Totale:
            </span>{" "}
            {order.totalPrice.toFixed(2)}€
          </div>
        </div>
        {type=="store" && <div>Scarica etichetta per spedizione</div>}
      </div>
    </>
  );
};

export default OrderCard;
