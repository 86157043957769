import styles from "./StoreDashboard.module.css";
import RatingStars from "components/RatingStars";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  IconBuildingBank,
  IconCategory,
  IconFileDescription,
  IconMail,
  IconMapPin,
  IconPhone,
  IconShare,
} from "@tabler/icons-react";
import StoreShowcase from "./StoreShowcase";
import CarouselWrapper from "./CarouselWrapper";
import Tag from "./Tag";
import FullScreenView from "./FullScreenView";
import ConfirmationModal from "./ConfirmationModal";
import { PopupContext } from "App";
import { serverHost } from "helpers/usefulData";
import { setIsOwner, setLogin } from "state";

const StoreDashboard = ({ store, user, type = "store" }) => {
  const navigate = useNavigate();
  const myUser = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();

  const [fullScreen, setFullScreen] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [popup, setPopup] = useContext(PopupContext);

  const shareStore = () => {
    if (navigator.share) {
      navigator.clipboard.writeText(
        "Guarda questo negozio su Tralevie! " +
          store?.name +
          "tralevie.it/negozio/" +
          store?._id
      );
      navigator
        .share({
          title: store?.name,
          url: "tralevie.it/negozio/" + store?._id,
        })
        .then(() => console.log("Product shared successfully"))
        .catch((error) => console.error("Error sharing product:", error));
    } else {
      console.log("Web Share API not supported");
      // You can implement a fallback behavior here, like showing a copy link option.
    }
  };
  const deleteStore = async () => {
      try{    // this allows us to send form info with image
 
      const response = await fetch(
         serverHost+ "stores/delete/" + store._id,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,

          },          
        }
      );
 
      if (response.ok) {

        setPopup({
          type: "success",
            message: "Negozio eliminato con successo",
        });
        console.log("deleted")
        dispatch(
          setIsOwner({isOwner: false})
        );
        navigate("/ilMioProfilo");

      } else {
        setPopup({
          type: "error",
          message: "Negozio NON eliminato, per favore riprova"
        });

  
      }}catch (e) {
        console.error(e);
      }
    navigate("/ilMioProfilo");

    setShowModal(false)
    navigate("/ilMioProfilo");
  }

  return (
    <>
      {fullScreen && (
        <FullScreenView
          activeImage={activeImageIndex ? activeImageIndex : 0}
          collection={store?.picture}
          closeFunction={setFullScreen}
        />
      )}
       <ConfirmationModal        
        showModal={showModal} 
        handleClose={()=>setShowModal(false)} 
        handleConfirm={deleteStore} 
        title="Elimina Definitivamente il tuo negozio"
      message={"Sei sicuro di voler rimuovere definitivamente questo negozio? L'operazione non sarà reversibile."}
      positive={false}/>

      <div className={styles.storeRow}>
        <RatingStars rating={store.rating ? store.rating : 0} />

        <div className={styles.titleRow}>
          <div className="secondaryTitle">
            <IconCategory></IconCategory> {store.name}
          </div>
          {type == "store" ? (<div className={styles.buttons}>
            <button
              className={`${styles.edit} mainButtonGreen`}
              onClick={() => navigate("/modificaNegozio/" + store?._id)}
            >
              Modifica
            </button>
                        <button
                        className={`${styles.delete} redLink`}
                        onClick={() => setShowModal(true)}
                      >
                        Elimina
                      </button></div>
          ) : (
            <button onClick={shareStore} className="greenLink">
              <IconShare className={styles.shareIcon}></IconShare>
            </button>
          )}
        </div>

        <div className={styles.imagesContainer}>
          {store?.picture.map((image, index) => (
            <img
              src={image}
              key={index}
              className={styles.image}
              onClick={() => {
                setActiveImageIndex(index);
                setFullScreen(true);
              }}
            />
          ))}
        </div>
        <div>
          <CarouselWrapper title="">
            {store?.tags.map((term, index) => (
              <Tag key={index} name={term} />
            ))}
          </CarouselWrapper>
        </div>

        <div className={styles.dataContainer}>
          <div className={styles.textArea}>
            {store?.description && (
              <div className={styles.descriptionContainer}>
                <div className={styles.label}>
                  <IconFileDescription></IconFileDescription> Descrizione
                </div>

                <div className={styles.description}>{store?.description} </div>
              </div>
            )}
            <div className={styles.info}>
              {store?.address && (
                <div>
                  <div className={styles.label}>
                    {" "}
                    <IconMapPin></IconMapPin> Indirizzo
                  </div>
                  <div className={styles.address}>
                    {store?.address}, {store?.province}
                  </div>
                </div>
              )}
              {store?.phone && (
                <>
                  <div className={styles.label}>
                    {" "}
                    <IconPhone></IconPhone> Telefono
                  </div>
                  <div className={styles.address}>{store?.phone}</div>
                </>
              )}
              {store?.email && (
                <>
                  <div className={styles.label}>
                    {" "}
                    <IconMail></IconMail> Email
                  </div>
                  <div className={styles.address}>{store?.email}</div>
                </>
              )}
              {store?.IBAN && (
                <>
                  <div className={styles.label}>
                    {" "}
                    <IconBuildingBank></IconBuildingBank> IBAN
                  </div>
                  <div className={styles.address}>{store?.IBAN}</div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <StoreShowcase store={store} />
    </>
  );
};

export default StoreDashboard;
