import styles from "./SubscriptionPage.module.css";
import { useNavigate } from "react-router-dom";
import {  useState } from "react";
import { serverHost } from "helpers/usefulData";
import { useSelector } from "react-redux";
import { IconBabyCarriage, IconCoins, IconPigMoney, IconPower, IconStar } from "@tabler/icons-react";
import ConfirmationModal from "components/ConfirmationModal";
import { IconRotateClockwise2 } from "@tabler/icons-react";

const SubscriptionPage = ({store}) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const token = useSelector((state) => state.token);
  const priceID= "price_1OhqvuKja0rAPefmCsh30hcL"
  const [showModal, setShowModal] = useState(false);

  
  const checkout = async () => {
    const response = await fetch(serverHost + `checkout/subscription/${priceID}`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    console.log(data);
    window.location = data.url;
  };
    
  const cancelSubscription = async () => {
    try{
    const response = await fetch(serverHost + `checkout/subscription/cancell`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` }
    });
    const data = await response.json();
    console.log(data);
  }catch(err){
    console.log(err)
  }
  };

  return (
    <>      <ConfirmationModal         showModal={showModal} 
    handleClose={()=> setShowModal(false)} 
    handleConfirm={cancelSubscription} 
    title="Conferma Annullamento"
  message={"Sei sicuro di voler annullare il tuo abbonamento? Non perderai i benefici dell'abbonamento fino al "+ new Date(store?.subscriptionExpiration).toLocaleString()}/>

      <div className={styles.container}>
      <div className={styles.titleRow}>
        <div className="secondaryTitle">
        <IconCoins></IconCoins> Il tuo abbonamento
      </div>
      {store?.isActive || false &&
      <button
                className={`${styles.editButton} mainButtonRed`}
                 onClick={() =>
                  setShowModal(true)
                }
              >
                Annulla abbonamento
              </button>}
        </div>
        <div className={styles.dataContainer}>
          <div className={styles.explanation}>
            Dato che sei uno dei primi che hanno creduto nel progetto, il Team di Tralevie ha deciso di fornirti l'abbonamento
            gratuitamente. Grazie, continua con l'ottimo lavoro!
          </div>
        <div className={styles.data}>
            <IconPower></IconPower>
          <div>
          <div className={styles.dataTitle}>Stato</div>
            <div className={styles.num}>{store?.isActive? "Attivo": "In pausa" }</div>
          </div>

          </div>
        <div className={styles.data}>
            <IconBabyCarriage></IconBabyCarriage>
          <div>
          <div className={styles.dataTitle}>Data di inizio </div>
            <div className={styles.num}>{new Date(store?.createdAt ).toLocaleDateString("en-GB")}</div>
          </div>
          </div>
          <div className={styles.data}>
            <IconRotateClockwise2></IconRotateClockwise2>
          <div>
          <div className={styles.dataTitle}>Prossimo rinnovo </div>
            <div className={styles.num}>{false ? new Date(store?.renewal ).toLocaleString("it-IT"): "-"}</div>
          </div>

          </div>
          <div className={styles.data}>
            <IconPigMoney></IconPigMoney>
          <div>
          <div className={styles.dataTitle}>Importo</div>
            <div className={styles.num}>0€/mese</div>
          </div>

          </div>
          </div>


{!store?.isActive &&<>
        <div className={styles.positiveMex}>Scegli un piano di iscrizione per attivare il negozio!</div>
        <div className={styles.options}>
        <div className={styles.card}>
          <div className={styles.price}>
            29,99 <span  className={styles.smallPrice}>€/Mese</span>
          </div>
          <div className={styles.description}>
            Iscrizione mensile a Tralevie
          </div>
          <button className="mainButtonGreen" onClick={checkout}>Iscriviti!</button>
        </div>
        <div className={styles.card}>
          <div className={styles.price}>
            329,99          <IconStar className={styles.star}></IconStar>
 <span  className={styles.smallPrice}>€/Anno</span>
          </div>
          <div className={styles.discount}>359.88</div>
          <div className={styles.description}>
            Iscrizione Annuale a Tralevie
          </div>
          <button className="mainButtonGreen" onClick={checkout}>Iscriviti!</button>
        </div>
        </div></>}

      </div>

    </>
  );
};

export default SubscriptionPage;
