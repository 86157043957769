import styles from "./AfterAuthPage.module.css";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { serverHost } from "helpers/usefulData";
import { useDispatch, useSelector } from "react-redux";
import { PopupContext } from "App";
import { setIsOwner } from "state";
import RedeemStore from "components/RedeemStore";

const AfterAuthPage = () => {
  useEffect(() => {
    document.title = "Tralevie - Seleziona Account";  
  }, []);
  const navigate = useNavigate();
  return (
    <>
      <Navbar />
      <div className={styles.textContainer}>
                  <img src="/assets/happy.png" className={styles.icon} alt="" />
        <div className={styles.positiveMex}>Evviva! Account Creato Con Successo!</div>
        <div className={styles.positiveMexParag}>
        Benvenuto su Tralevie! Grazie per il sostegno al nostro progetto e al Commercio Italiano di Qualità.
        Esplora le incredibili offerte dei nostri negozi oppure, se possiedi un negozio, creane uno subito!
        </div>
        </div>
      <div className={styles.container}>
        <button className="mainButtonGreen" onClick={()=>navigate('/')}>
          Scopri I Migliori Negozi E Prodotti
        </button>
        <button className="mainButtonGreen" onClick={()=>navigate('/creaNegozio')}>
          Aggiungi Il Tuo Negozio
        </button>
      </div>
      <RedeemStore/>
      <Footer />
    </>
  );
};

export default AfterAuthPage;
