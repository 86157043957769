import Footer from "components/Footer";
import Navbar from "components/Navbar";
import { useEffect, useState } from "react";
import styles from "./ContactPage.module.css";

const ContactPage = () => {
  useEffect(() => {
    document.title = "Contatti - Tralevie";  
  }, [])

  return (
    <>
      <Navbar />
      <ul className="breadCrumb">
        <li className="breadCrumbElement">
          <a href="/">Homepage</a>
        </li>{" "}
        {">"}
        <li className="breadCrumbElement">
          <a href="/Contatti">Contatti</a>
        </li>
      </ul>

      <div className={styles.container}>
          <div className="mainTitle">
            Inviaci una email e in breve ricereverai risposta!
          </div>
          <div className={styles.formContainer}>
            <img className={styles.imgForm} src="/assets/question.png" alt="" />

            <form className={styles.actualForm}
              action="mailto:info@tralevie.com"
              method="post"
              enctype="text/plain"
            >
              <label>Soggetto</label>
              <input type="text" name="subject"/>
              <label>Contenuto</label>

              <textarea rows="5" name="body" required/>
              <button className="cta" type="submit">Invia</button>
            </form>
          </div>
          <div className={styles.or}>oppure scrivi a info@tralevie.com</div>
</div>
      <Footer />
    </>
  );
};

export default ContactPage;
