import Footer from "components/Footer";
import Navbar from "components/Navbar";
import React, { useEffect, useState } from "react";
import styles from "./aboutUsPage.module.css";

const AboutUsPage = () => {
  useEffect(() => {
    document.title = "Chi siamo - Tralevie";
  }, []);

  return (
    <>
      <Navbar />
      <ul className="breadCrumb">
        <li className="breadCrumbElement">
          <a href="/">Homepage</a>
        </li>{" "}
        {">"}
        <li className="breadCrumbElement">
          <a href="/chiSiamo">Chi Siamo</a>
        </li>
      </ul>

      <div className={styles.container}>
        <div className={styles.row}>
          <img className={styles.image} src="/assets/logo.png" alt="" />
          <div className={styles.textSection}>
            <div className="mainTitle">Che cos'è Tralevie?</div>
            <div className={styles.text}>
              Tralevie è più di una semplice piattaforma di shopping online: è
              un luogo dove gli amanti dei prodotti di qualità possono davvero
              vivere un'esperienza unica. Desideriamo essere considerati non
              solo un'alternativa etica e di qualità per lo shopping online, ma
              anche un ponte tra i clienti e i negozianti, permettendo loro di
              interagire in modo autentico e coinvolgente. Su Tralevie, non solo
              puoi esplorare i migliori negozi italiani, ma puoi anche
              connetterti con i venditori, scambiare messaggi, esplorare
              contenuti condivisi e immergerti completamente nel mondo
              affascinante dei prodotti eccezionali che il nostro territorio ha
              da offrire. Scopri il piacere dello shopping online con un tocco
              personale su Tralevie!
            </div>
          </div>
        </div>
        <div className={styles.rowReversed}>
          <img
            className={styles.image}
            src="/assets/homepage.png"
            alt=""
          />

          <div className={styles.textSection}>
            <div className="mainTitle">Perchè scegliere Tralevie?</div>
            <div className={styles.text}>
              La domanda difficile sarebbe: "perché NON scegliere Tralevie?"! Qui
              troverai prodotti ricercati e di alta qualità che non troveresti
              altrove, gestiti da persone competenti e disponibili, pronte ad
              assisterti con i tuoi acquisti. La nostra piattaforma è progettata
              per essere intuitiva e facile da usare, garantendo un'esperienza
              di shopping online senza precedenti. Cosa potresti desiderare di
              più? Tralevie è la scelta evidente per chi cerca l'eccellenza e il
              servizio impeccabile.
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <img
            className={styles.image}
            src="/assets/Tralevie team.png"
            alt="Team did Tralevie: Vittorio Schiavon, Fabio d'Alessi, Marco Parmegian"
          />

          <div className={styles.textSection}>
            <div className="mainTitle">Il (fantastico)Team !</div>
            <div className={styles.text}>
              Ecco il cuore pulsante di Tralevie: Vittorio Schiavon, Fabio
              d'Alessi e Marco Parmegian! Dal 2022, abbiamo abbracciato un
              obiettivo ambizioso: revitalizzare il commercio di qualità e
              introdurre innovazioni in un settore che ci appassiona
              profondamente. La nostra missione va oltre il semplice profitto:
              vogliamo creare tecnologie che migliorino la vita delle persone,
              promuovendo il senso di comunità e la curiosità verso il prossimo.
              Mettiamo al centro di tutto il valore della Persona, e speriamo
              che questo si rifletta in ogni aspetto di ciò che facciamo.
            </div>
          </div>
        </div>
        {/* 
        <div className="mainTitle">Il team</div>
        <div className={styles.teamSection}>
          <div className={styles.dude}>
            <div className={styles.badge}>
              <img src="/assets/Vittorio Schiavon.JPG" alt="" />
              <div className={styles.nameSection}>
                <div className={styles.name}>Vittorio Schiavon</div>
                <div className={styles.role}>co-fondatore</div>
              </div>
            </div>
            <div className={styles.dudeTextSection}>
              <div className={styles.description}>
                Chiamatemi "Vitto"! Sono laureato con lode in Informatica presso
                l'Università di Padova. La mia missione è utilizzare la
                tecnologia per rendere la vita delle persone più bella e felice.
                Tra le mie passioni c'è anche la cucina e l'arte!
              </div>
            </div>
          </div>
          <div className={styles.dude}>
            <div className={styles.badge}>
              <img src="/assets/fabio.jpg" alt="" />
              <div className={styles.nameSection}>
                <div className={styles.name}>Fabio d'Alessi</div>
                <div className={styles.role}>co-fondatore</div>
              </div>{" "}
            </div>

            <div className={styles.dudeTextSection}>
              <div className={styles.description}>Aggiungere descrizione </div>
            </div>
          </div>
        </div>*/}
      </div>
      <Footer />
    </>
  );
};

export default AboutUsPage;
